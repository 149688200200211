<template>
    <div class="block open">
        
        <div class="summary">
            <img class="icontype" :src="require(`@/assets/icons/${item.key}.svg`)" />

            <!-- <EditName class="name" 
                @update="consolidateName"
                :source="item.ukey" /> -->
            <p style="margin:0; display:flex; align-items:center;">{{item.ukey}}</p>

            <span  v-if="next.show_adminonly" class="swap">
                <select v-model="currentType" @change="swapType">
                    <option disabled>Change Type</option>
                    <option v-for="block in userBlocks" :key="block.key" :value="block.key">{{block.name}}</option>
                </select>
            </span>

            <div class="description">{{item.description}}</div>

            <div class="ctrlcontainer" :style="{width: ctrl.w || '100%'}"  
                v-for="ctrl in firstLevelOptions" :key="ctrl.key">
                <component 
                    :is="ctrl.type + '-ctrl'" 
                    :ctrl="ctrl"
                    @change="onChange"
                    @inject="onInject" />                
            </div>

            <div v-if="secondLevelOptions.length > 0">
                <p class="moreopt toggler" @click="more=!more">more options</p>
            </div>
            <div class="summary" v-if="more">
                <div class="ctrlcontainer" :style="{width: ctrl.w || '100%'}" 
                    v-for="(ctrl,index) in secondLevelOptions" :key="ctrl.key + index">
                    <component 
                        :is="ctrl.type + '-ctrl'" 
                        :ctrl="ctrl"
                        @change="onChange" />                
                </div>
            </div>

        </div>

    </div>
</template>




<script>
import { mapGetters, mapMutations } from 'vuex'
import CheckboxCtrl from './ui/CheckboxCtrl'
import SelectCtrl from './ui/SelectCtrl'
import EditName from './ctrls/EditableName.vue'
import oblocks from '@/libs/def/blocks'

export default {
    props:{
        item: Object,
        index: Number,
        isNested: Boolean,
    },
    data(){
        return{
            more:false,
            active: 'inherit',
            show: true,
            currentType:null
        }
    },
    mounted(){
        this.item.isRoot = true

        if(this.item.value){
            this.active = 'override'
        }

        if(!this.item.value){
            this.active = 'inherit'
        }

        if(this.item.value === '__disabled'){
            this.active = 'disable'
        }

        this.currentType = this.item.key
    },
    components:{
        CheckboxCtrl, SelectCtrl, EditName
    },
    computed:{
        ...mapGetters(['selectingBlockIndex', 'selectedBlockIndexFromFabric', 'company']),
        hasOptions(){
            return this.item.options && this.item.options.length > 0
        },
        firstLevelOptions(){
            return this.item.options.filter(d => !d.more)
        },
        secondLevelOptions(){
            return this.item.options.filter(d => d.more)
        },
        togglerClass(){
            return {hidden: !this.hasOptions}
        },
        togglerClassTitle(){
            return {simple: !this.hasOptions}
        },
        selecting(){
            return this.isPresentaBlock && this.selectingBlockIndex === this.index
        },
        next(){
            return this.company && this.company.next ? this.company.next : {}
        },
        userBlocks(){
            return this.next.show_adminonly ? oblocks : oblocks.filter(d => d.basic && !d.hidden)
        }
    },
    watch:{
        selectedBlockIndexFromFabric(v){
            this.show = this.isPresentaBlock ? (v >= 0 && v === this.index) : this.show
            this.$emit('open', {open:this.show, index:this.index})
        }
    },
    methods:{
        ...mapMutations(['setSelectingBlockIndex', 'setSelectedBlockIndexFromFabric']),
        onChange(ctrl){
            
            if(ctrl.isRoot) {
                //this.show = this.item.value
            }else{
                this.item.value = true
            }

            this.$emit('change', {
                item: this.item,
                index: this.index,
                ctrl
            })

            this.active = 'override'
        },
        onInject(ctrl){
            const first = this.item.options[0]
            const temp = ctrl.options.find(c => c.key === ctrl.value)
            const arr = [first, ...temp.options]
            this.item.options = arr
            this.onChange(ctrl)
        },

        toggleSummary(){
            this.show = !this.show
        },

        onActiveChange(){
            
            if(this.active === 'inherit'){
                this.item.value = null
                this.show = false
            }
            if(this.active === 'disable'){
                this.item.value = '__disabled'
                this.show = false
            }

            this.$emit('change', {
                item: this.item,
                index: this.index,
                ctrl: this.item
            })
        },

        consolidateName(src) {
            this.item.ukey = src
            this.$emit('ukey', {index:this.index, ukey:src})
        },

        swapType(){
            this.item.key = this.currentType
            this.$emit('swap', {index:this.index, type:this.currentType})
            this.setSelectedBlockIndexFromFabric(-1)
        }
    }
}
</script>



<style scoped>
.block{
    width: 100%;
}
.block:hover{
    background-color: #eee;
}
.block.selecting{
    outline:1px solid #24a1f9;
}
.block.open{
    box-shadow: 0 4px 5px #ccc;
    margin-bottom: 1rem;
    background-color: #eee;
}


.head{
    display: flex;
    align-items: center;
    background-color: none;
    font-size: .8rem;
}
.head img{
    width: 20px;
    height: 20px;
}
.head p{
    padding: 0;
    margin: 0;
    flex: 1;
    padding: .5rem 0rem;
    user-select: none;
}

.inline{
    padding: 0;
}
.inline >>> p{
    margin: 0;
}
.inline >>> label{
    display: flex;
}

select.inline{
    background: none;
    border: none;
}

.name{
    text-align: center;
}
.description{
    font-size: .75rem;
}

.moreopt{
    font-size: .75rem;
    text-decoration: underline;
    margin-bottom: 0;
    justify-content: center;
}

.summary{
    padding: .5rem;
    background-color: white;
}
.summary{
    display: flex;
    flex-wrap: wrap;
}
.ctrlcontainer{
    display: flex;
}

.toggler{
    cursor: pointer;
    display: flex;
}
 
.draggable{
    cursor: move;
}
.edit{
    cursor: pointer;
}


.icontype{
    width: 30px;
}

.swap{
    display: flex;
    flex:1;
    align-items: center;
    justify-content: flex-end;
    font-size: .7rem;
}
</style>
