<template>
    <div class="samples">
        <div class="inner">
            <p>Need some initial help?</p>

            <div class="grid">
                <div class="preview" v-for="sample in samples" :key="sample.url">
                    <a target="_blank" :href="'https://www.presenta.cc/blog/' + sample.url">
                       <img :src="sample.img" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>




<script>
import VuePresentaPreview from '@/components/VuePresentaPreview'

export default {
    components:{
        VuePresentaPreview
    },
    data(){
        return{
            samples:[
                { url: 'a-basic-introduction-to-presenta', img:"https://cloud.preso.cc/v1/url/zGywhb2oJn:ah8cqUtoW?&term=pen&title=A basic introduction to PRESENTA&subtitle=Tutorial"},
                { url: 'why-you-need-presenta', img:"https://cloud.preso.cc/v1/url/zGywhb2oJn:ah8cqUtoW?&term=tool&title=Why%20you'd%20need%20PRESENTA%3F&subtitle=General"},
                { url: 'notify-invited-users-of-your-saas-with-a-personal-and-novel-banner', img:"https://cloud.preso.cc/v1/url/zGywhb2oJn:ah8cqUtoW?&term=office&title=Notify invited users of your SaaS with a personal and novel banner&subtitle=Tutorial"}
            ]
        }
    },
    async mounted(){
        
    }
}
</script>




<style scoped>
.samples{
    margin-top: 1.5rem;
}
.inner{
    border: 1px dashed #ccc;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
a{
    font-size: .7rem;
}
.preview{
    padding: 1rem;
    transition: transform .25s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.preview:hover{
    transform:scale(1.1);
}
.grid{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.grid .preview{
    width: 33.3333333%;
}
.carousel{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    overflow-x: auto;
}
</style>