<template>
    <div class="panel">
        {{msg}}
    </div>
</template>



<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
    data(){
        return{
            loading:false,
            error:false,
            msg: 'Import Project in progress...'
        }
    },
    computed:{
        ...mapGetters(['companyId', 'primaryCompanyId'])
    },
    async mounted(){
        this.loading = true
        
        const id = this.$route.params.id
        
        try {
            let res1 = await axios(`${process.env.VUE_APP_IMAGE_CDN_BASE}${process.env.VUE_APP_IMAGE_CDN}/public/${id}.json`, { withCredentials: false })
            if(res1.data){

                const doc = res1.data
                doc.name = res1.data.name
                doc.curl = this.companyId || this.primaryCompanyId

                let res2 = await this.createProject(doc)
                if(res2.data) {
                    this.$router.push('/edit/' + res2.data.url)
                }
            }
        } catch(err) {
            console.log(err)
            this.loading = false
            this.error = true
            this.msg = err
        }
    },
    methods:{
        ...mapActions(['createProject'])
    }
}
</script>



<style scoped>
.panel{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>