import Vue from 'vue'

export default {
  state: {
    selection: [],
    internalClipboard: null,
    selectingBlockIndex: -1,
    selectedBlockIndexFromFabric: -1,
    selectedBlockIndexFromSidebar: -1,
    selectedBlockIndex: -1
  },
  mutations: {
    setSelection (state, s) {
      const actualValue = s.$selected
      this.commit('clearSelection')
      if (!actualValue) this.commit('addToSelection', s)
    },

    addToSelection (state, s) {
      const actualValue = s.$selected
      s.$selected = !actualValue
      if (!actualValue) state.selection.push(s)
    },
    removeFromSelection (state, s) {
      s.$selected = false
      const index = state.selection.indexOf(s)
      if (index >= 0) state.selection.splice(index, 1)
    },
    clearSelection (state) {
      state.selection.forEach(s => {
        s.$selected = false
      })
      state.selection = []
    },

    setSelectingBlockIndex (state, v) {
      state.selectingBlockIndex = v
    },
    setSelectedBlockIndexFromFabric (state, v) {
      state.selectedBlockIndexFromFabric = v
      state.selectedBlockIndex = v
    },
    setSelectedBlockIndexFromSidebar (state, v) {
      state.selectedBlockIndexFromSidebar = v
      state.selectedBlockIndex = v
    },

    setInternalClipboard (state, v) {
      state.internalClipboard = v
    }

  },
  getters: {
    selection (state) {
      return state.selection
    },
    selectionLength (state) {
      return state.selection.length
    },
    isSingleSelection (state) {
      return state.selection.length === 1
    },
    isMultiSelection (state) {
      return state.selection.length > 1
    },
    isEmptySelection (state) {
      return state.selection.length === 0
    },

    internalClipboard (state) {
      return state.internalClipboard
    },

    selectingBlockIndex (state) {
      return state.selectingBlockIndex
    },
    selectedBlockIndexFromFabric (state) {
      return state.selectedBlockIndexFromFabric
    },
    selectedBlockIndexFromSidebar (state) {
      return state.selectedBlockIndexFromSidebar
    },
    selectedBlockIndex (state) {
      return state.selectedBlockIndex
    }
  }
}
