import { render, staticRenderFns } from "./Exporter.vue?vue&type=template&id=490f51b4&scoped=true&"
import script from "./Exporter.vue?vue&type=script&lang=js&"
export * from "./Exporter.vue?vue&type=script&lang=js&"
import style0 from "./Exporter.vue?vue&type=style&index=0&id=490f51b4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "490f51b4",
  null
  
)

export default component.exports