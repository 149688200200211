<template>
    <div class="listContainer">
        <transition-group class="list" name="list" tag="ul">
            <li v-for="project in results" :key="project.ref" class="list-item-trans">
                <PresentationItem :project="getRef(project)" />
            </li>
        </transition-group>
    </div>
</template>


<script>
import PresentationItem from './PresentationItem'

export default {
    components:{
        PresentationItem
    },
    props:{
        results:Array,
        projects:Array
    },
    methods:{
        getRef (item) {
            var r
            this.projects.forEach(d => {
                if (d.url == item.ref) r = d
            })
            return r
        }
    }
}
</script>


<style scoped>
.listContainer{
    width: 100%;
}

 ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

 li{
  padding: .5rem;
  background-color: #fff;
  margin: 0;
  margin-bottom: 1.25rem;
  border:1px solid #C9C9C9;
}

 ul.list li:hover{
  background-color: var(--ghighlightcolor);
}



 .list-enter-active,  .list-leave-active {
  transition: opacity .2s ease-in-out; /*, transform .35s ease-in-out*/
}
 .list-enter,  .list-leave-to{
  opacity: 0;
  /* transform: scale(0); */
}
 .list-move {
  /* transition: transform .35s ease-in-out; */
}

/*  .list-leave-active {
  position: absolute;
} */

 ul.head li{
  background-color: unset;
  border: none;
}
</style>