<template>
    <p>
        <button v-tooltip="'Duplicate and Edit'" class="small alternate" @click.prevent="onDuplicateProject()">
            <img class="icon" src="@/assets/icons/duplicate.svg" />
        </button>
    </p>
</template>



<script>
import { mapActions } from 'vuex'
export default {
    props:{
        project:Object
    },
    methods:{
        ...mapActions(['duplicateProject']),
        async onDuplicateProject () {
            try {
                let res = await this.duplicateProject(this.project)
                if(res.data) {
                    this.$router.push('/edit/' + res.data.url)
                }
            } catch(err) {
                console.log(err)
            }
        }
    }
}
</script>