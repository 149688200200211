<template>
    <div class="wrapper">
        <div class="left">
          <router-link to="/projects">
              <img src="@/assets/logowhite.svg" />
          </router-link>
        </div>
        <div class="right">
          <!-- <SendFeedback v-if="user" /> -->
          <router-link class="user" to="/profile" v-if="user">
              <img :src="gravatar" />
          </router-link>
        </div>
    </div>
</template>



<script>
import {mapGetters} from 'vuex'
import SendFeedback from './SendFeedback'

export default {
    components:{
      SendFeedback
    },
    computed: {
        ...mapGetters(['user', 'gravatar'])
    }
}
</script>



<style scoped>
.wrapper{
  background-color: #333;
  display: flex;
  height: 40px;
  min-height: 40px;
}

.wrapper > div{
  padding: .25rem 0.9rem;
  flex:1;
  display: flex;
  align-items: center;
}

.right{
  justify-content: flex-end;
  padding: .25rem;
    padding-right: .5rem;
}

.left img{
  height: 25px;
  display: block;
}

.left{
    padding-left: .5rem;
}

.user{
  display: block;
  width: 24px;
  height:24px;
  border-radius: 50%;
  overflow: hidden;
  transition: transform .2s ease-in-out;
}
.user img{
  width: 100%;
  height:100%;
}

a{
    text-decoration: none;
}
</style>