
export default {
  state: {
    slideRenderedWidth: 0,
    actualScaling: 1,
    fontsAvailable: false,
    sidebarOpen: true,
    isMobileMode: false,
    isTouchMode: false,
    isSlideDragging: false,
    isDrawering: false,
    isFileDropping: false,
    templateAPI: null,
    projectAPI: null,
    automateObject: null
  },
  getters: {
    slideRenderedWidth (state) {
      return state.slideRenderedWidth
    },
    actualScaling (state) {
      return state.slideRenderedWidth / 960
    },
    fontsAvailable (state) {
      return state.fontsAvailable
    },
    sidebarOpen (state) {
      return state.sidebarOpen
    },
    isMobileMode (state) {
      return state.isMobileMode
    },
    isTouchMode (state) {
      return state.isTouchMode
    },
    isSlideDragging (state) {
      return state.isSlideDragging
    },
    isDrawering (state) {
      return state.isDrawering
    },
    isFileDropping (state) {
      return state.isFileDropping
    },
    templateAPI (state) {
      return state.templateAPI
    },
    projectAPI (state) {
      return state.projectAPI
    },
    automateObject(state){
      return state.automateObject
    }
  },
  mutations: {
    setSlideRederedWidth (state, v) {
      state.slideRenderedWidth = v
    },
    toggleSidebarOpen (state) {
      state.sidebarOpen = !state.sidebarOpen
    },
    setFontsAvailable (state) {
      state.fontsAvailable = true
    },
    setMobileMode (state) {
      state.isMobileMode = true
      state.sidebarOpen = false
    },
    setTouchMode (state) {
      state.isTouchMode = true
    },
    setIsSlideDragging (state, v) {
      state.isSlideDragging = v
    },
    setIsDrawering (state) {
      state.isDrawering = true
    },
    unsetIsDrawering (state) {
      state.isDrawering = false
    },
    setIsFileDropping (state) {
      state.isFileDropping = true
    },
    unsetIsFileDropping (state) {
      state.isFileDropping = false
    },
    setTemplateAPI (state, v) {
      state.templateAPI = v
    },
    setProjectAPI (state, v) {
      state.projectAPI = v
    },

    setAutomateObject (state, v) {
      state.automateObject= v
    }
  }
}
