<template>
    <div class="block" :class="{open:show, selecting:selecting}">

        <div  class="head">
            
            <img class="handle draggable" v-if="isPresentaBlock" src="@/assets/icons/drag.svg" />
            
            <div class="toggler" @click="toggleSummary">
                <img v-if="show" src="@/assets/icons/downdrop.svg" />
                <img v-if="!show" src="@/assets/icons/rightdrop.svg" />
            </div>

            <p class="toggler" @mouseenter="setSelectingBlockIndex(index)" @mouseleave="setSelectingBlockIndex(-1)" @click="toggleSummary">{{item.name}}</p>
            
            <!-- <CheckboxCtrl :noLabel="true" v-if="!isNested && !isPresentaBlock" class="inline" :ctrl="item" @change="onChange"/> -->

            <select v-if="isNested" class="inline" v-model="active" @change="onActiveChange">
                <option value="inherit">Inherit</option>
                <option value="override">Override</option>
                <option value="disable">Disable</option>
            </select>

            <img class="edit" v-if="isPresentaBlock" @click="onDelete" src="@/assets/icons/delete.svg" />
            <img class="edit" v-if="isPresentaBlock" @click="onDuplicate" src="@/assets/icons/duplicate.svg" />
        </div>
        
        <div v-show="show" class="summary">

            <div class="description">{{item.description}}</div>

            <div class="ctrlcontainer" :style="{width: ctrl.w || '100%'}" 
                v-for="ctrl in firstLevelOptions" :key="ctrl.key">
                <component 
                    :is="ctrl.type + '-ctrl'" 
                    :ctrl="ctrl"
                    @change="onChange"
                    @inject="onInject" />                
            </div>

            <div v-if="secondLevelOptions.length > 0">
                <p class="moreopt toggler" @click="more=!more">more options</p>
            </div>
            <div v-if="more">
                <div class="ctrlcontainer" :style="{width: ctrl.w || '100%'}"
                    v-for="(ctrl,index) in secondLevelOptions" :key="ctrl.key + index">
                    <component 
                        :is="ctrl.type + '-ctrl'" 
                        :ctrl="ctrl"
                        @change="onChange" />                
                </div>
            </div>

        </div>

    </div>
</template>




<script>

import { mapGetters, mapMutations } from 'vuex'
import CheckboxCtrl from './ui/CheckboxCtrl'
import SelectCtrl from './ui/SelectCtrl'

export default {
    props:{
        item: Object,
        index: Number,
        isPresentaBlock: Boolean,
        isNested: Boolean,
    },
    data(){
        return{
            more:false,
            active: 'inherit',
            show: false
        }
    },
    mounted(){
        this.item.isRoot = true

        if(this.item.value){
            this.active = 'override'
        }

        if(!this.item.value){
            this.active = 'inherit'
        }

        if(this.item.value === '__disabled'){
            this.active = 'disable'
        }

    },
    components:{
        CheckboxCtrl, SelectCtrl
    },
    computed:{
        ...mapGetters(['selectingBlockIndex', 'selectedBlockIndexFromFabric']),
        hasOptions(){
            return this.item.options && this.item.options.length > 0
        },
        firstLevelOptions(){
            return this.item.options.filter(d => !d.more)
        },
        secondLevelOptions(){
            return this.item.options.filter(d => d.more)
        },
        togglerClass(){
            return {hidden: !this.hasOptions}
        },
        togglerClassTitle(){
            return {simple: !this.hasOptions}
        },
        selecting(){
            return this.isPresentaBlock && this.selectingBlockIndex === this.index
        }
    },
    watch:{
        selectedBlockIndexFromFabric(v){
            this.show = this.isPresentaBlock ? (v >= 0 && v === this.index) : this.show
            this.$emit('open', {open:this.show, index:this.index})
        }
    },
    methods:{
        ...mapMutations(['setSelectingBlockIndex', 'setSelectedBlockIndexFromSidebar']),
        onChange(ctrl){
            
            if(ctrl.isRoot) {
                //this.show = this.item.value
            }else{
                this.item.value = true
            }

            this.$emit('change', {
                item: this.item,
                index: this.index,
                ctrl
            })

            this.active = 'override'
        },
        onInject(ctrl){
            const first = this.item.options[0]
            const temp = ctrl.options.find(c => c.key === ctrl.value)
            const arr = [first, ...temp.options]
            this.item.options = arr
            this.onChange(ctrl)
        },
        toggleSummary(){
            this.show = !this.show

            if(this.isPresentaBlock){
                const v = this.show ? this.index : -1
                this.setSelectedBlockIndexFromSidebar(v)
            }

            this.$emit('open', {open:this.show, index:this.index})
        },
        onDelete(){
            this.$emit('delete', this.index)
        },
        onDuplicate(){
            this.$emit('duplicate', this.index)
        },

        onActiveChange(){
            
            if(this.active === 'inherit'){
                this.item.value = null
                this.show = false
            }
            if(this.active === 'disable'){
                this.item.value = '__disabled'
                this.show = false
            }

            this.$emit('change', {
                item: this.item,
                index: this.index,
                ctrl: this.item
            })
        }
    }
}
</script>



<style scoped>
.block{
    width: 100%;
}
.block:hover{
    background-color: #eee;
}
.block.selecting{
    outline:1px solid #24a1f9;
}
.block.open{
    box-shadow: 0 4px 5px #ccc;
    margin-bottom: 1rem;
    background-color: #eee;
}


.head{
    display: flex;
    align-items: center;
    background-color: none;
    font-size: .8rem;
}
.head img{
    width: 20px;
    height: 20px;
}
.head p{
    padding: 0;
    margin: 0;
    flex: 1;
    padding: .5rem 0rem;
    user-select: none;
}

.inline{
    padding: 0;
}
.inline >>> p{
    margin: 0;
}
.inline >>> label{
    display: flex;
}

select.inline{
    background: none;
    border: none;
}

.description{
    font-size: .75rem;
    width: 100%;
}

.summary{
    display: flex;
    flex-wrap: wrap;
}
.ctrlcontainer{
    display: flex;
}

.moreopt{
    font-size: .75rem;
    text-decoration: underline;
    margin-bottom: 0;
    justify-content: center;
}

.summary{
    padding: .5rem;
    background-color: white;
}

.toggler{
    cursor: pointer;
    display: flex;
}
 
.draggable{
    cursor: move;
}
.edit{
    cursor: pointer;
}
</style>
