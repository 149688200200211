const validTrans = ['fadeIn', 'zoomOut', 'zoomIn', 'slideUp', 'slideDown']
const validAnims = ['bouncing']

export default {
  key: 'enters',
  name: 'Element Transition',
  context: 'modules',
  description: 'Element Transitions are visible on shares projects.',
  value: false,
  options: [
    { key: 'transition', label: 'Enter transition', description: '', type: 'select', value: '', options: validTrans.map(o => ({ key: o, label: o, value: o })) },
    { key: 'stagger', w: '30%', label: 'Staggered', description: 'Automatically calculate a staggered delay according to the number of blocks.', type: 'checkbox', value: true },
    { key: 'delay', w: '70%', label: 'Delay', description: '', type: 'range', value: 750, min: 0, max: 3000, step: 250 }

    // { key: 'sep1', type: 'separator' },
    // { key: 'transitionOut', label: 'Exit transition', description: '', type: 'select', value: '', options: validTrans.map(o => ({ key: o, label: o, value: o })) },

    // { key: 'sep2', type: 'separator' },
    // { key: 'transitionIdle', label: 'In-scene animation', description: '', type: 'select', value: '', options: validAnims.map(o => ({ key: o, label: o, value: o })) }

  ]
}
