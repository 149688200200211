<template>
  <div class="viewScroll" @dragover="onDragOver($event)">

    <WorkspaceHeader 
      @resultChanged="onResultChanged"
      @newProject="newProjectSelected" />

    <div class="page">
        <div class="prewrapper">

            <p v-if="isMobileMode"><mark>Mobile version is not 100% functional yet.</mark></p>
            
            <div class="wrapper">

                <ProjectFromTemplates 
                  v-if="showTemplatesModal" 
                  @selected="onTemplateSelected"
                  @close="showTemplatesModal=false"
                  @done="saveAndEditProject" />

                <ProjectFromFigma 
                  v-if="showFigmaModal" 
                  @close="showFigmaModal=false"
                  @done="saveAndEditProject" />

                <ProjectFromMD 
                  v-if="showMarkdownModal"
                  @close="showMarkdownModal=false"
                  @done="saveAndEditProject" />

                <ProjectFromCSV 
                  v-if="showCsvModal"
                  @close="showCsvModal=false"
                  @done="saveAndEditProject" />
                
                <PresentationList v-if="hasProjects" 
                  :projects="projects" 
                  :results="results"  />
            </div>
            
            <NoProjects :msg="msg" 
              :showBtn="!loading && !isError"
              @newProject="newProjectSelected"
              class="wrapper" v-if="!hasProjects && !isError" />
              
            <!-- <SampleBox class="wrapper" /> -->
           
        </div>
    </div>

  </div>
</template>

<script>
import WorkspaceHeader from '@/components/presentations/WorkspaceHeader'
import PresentationList from '@/components/presentations/PresentationList'
import NoProjects from '@/components/presentations/NoProjects'
import SampleBox from '@/components/presentations/SampleBox'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import nameGen from 'project-name-generator'
import defaults, {fixScene} from '@/libs/defaults'
import format from '@/libs/format'
import {clone} from '@/libs/stringify'
import ProjectFromTemplates from '@/components/presentations/ProjectFromTemplates'
import ProjectFromFigma from '@/components/presentations/ProjectFromFigma'
import ProjectFromCSV from '@/components/presentations/ProjectFromCSV'
import ProjectFromMD from '@/components/presentations/ProjectFromMD'


export default {
  components:{
    WorkspaceHeader, PresentationList, NoProjects, SampleBox, 
    ProjectFromTemplates, ProjectFromFigma, ProjectFromCSV, ProjectFromMD
  },
  created(){
    this.load()
  },
  watch:{
    "$route": 'load'
  },
  data(){
    return{
      results:[],
      msg: null,
      loading: false,
      isError:false,

      showTemplatesModal:false,
      showFigmaModal:false,
      showMarkdownModal:false,
      showCsvModal:false
    }
  },
  computed:{
    ...mapGetters(['company', 'companyId', 'isMobileMode']),
    hasProjects(){
      return this.company && this.company.projects ? this.company.projects.length > 0 : false
    },
    projects(){
      return this.company ? this.company.projects : []
    },
    next(){
      return this.company && this.company.next ? this.company.next : {}
    },
  },
  methods:{
    ...mapMutations(['unsetCompany', 'setCompany', 'setCompanyId']),
    ...mapActions(['createProject', 'getCompany', 'getTemplate']),
    async load(){
      this.unsetCompany()
      this.loading = true
      this.isError = false
      this.msg = 'Loading projects...'

      try {
        let res = await this.getCompany(this.$route.params.id)
        if(res.data.error){
          this.msg = res.data.error
          this.isError = true
          return
        }
        let company = res.data
        this.setCompany(company)
        this.setCompanyId(company.url)
        this.msg = `You don't have any project yet.`
      } catch(err) {
        this.msg = err
        this.isError = true
      }

      this.loading = false
    },
    onResultChanged(v){
      this.results = v
    },

    onDragOver(e){
      this.dragover = true
      e.preventDefault()
    },

    onCreateNewProject(){
        let p = clone(defaults.defaultProject)
        p.name = nameGen({ number: true }).spaced
        p.curl = this.companyId
        this.saveAndEditProject(p)
    },

    async saveAndEditProject(p){
      try {
          format.fixEmoji(p)
          console.log('saveAndEditProject', p)
          let res = await this.createProject( p )
          if(res.data) {
              this.$router.push('/edit/' + res.data.url)
          }
      } catch(err) {
          console.log(err)
      }
    },

    newProjectSelected($event){
      switch($event){
        case 'empty':
          this.onCreateNewProject()
          break;

        case 'templates':
          this.showTemplatesModal = true
          break;

        case 'figma':
          this.showFigmaModal = true
          break;

        case 'markdown':
          this.showMarkdownModal = true
          break;
        
        case 'csv':
          this.showCsvModal = true
          break;
      }
    },

    async onTemplateSelected(t){
      const id = t.id
      const parts = id.split(':')
      const pCurl = parts[0]
      const pPurl = parts[1]
      const src = await this.getTemplate({curl: this.companyId, pCurl, pPurl})
      const template = clone(src.data)

      let p = clone(defaults.defaultProject)
      p.name = nameGen({ number: true }).spaced
      p.curl = this.companyId

      const keysToKeep = ['controllers', 'modules', 'aspect', 'format', 'scenes', 'plugins']
      keysToKeep.forEach(k => {
        p.document[k] = template[k]
      })

      p.document.scenes.forEach((s,i) =>  {
        s.pTemplateID = null
        s.pFrameAPIChanged = null
        fixScene(s,i)
      })

      this.saveAndEditProject(p)
    }
    
  }
}
</script>



<style scoped>
.page{
  background-color: #F5F5F5;
}
</style>