<template>
    <div ref="wrap" class="presentaSceneWrapper"></div>
</template>



<script>
import * as Presenta from '@presenta/lib'
import { clone } from '@/libs/stringify'

export default {
    props:{
        document:Object,
        index:Number,
        present:Boolean
    },
    data(){
        return{
            instancedScene:null
        }
    },
    mounted(){
        // required because parent size calc fails in popover
        setTimeout(this.build)
    },
    beforeDestroy(){
        if(this.instancedScene) this.instancedScene.destroy()
        this.instancedScene = null
    },
    watch:{
        present(){
            this.build()
        }
    },
    methods:{
        build(){
            if(this.instancedScene){
                this.instancedScene.destroy()
                this.$refs.wrap.innerHTML = ''
            }

            let p = clone(this.document)
            
            p.mode = this.present ? 'present' : 'preview'
            p.controllers.current = this.index + 1
            p.controllers.arrows = null
            p.controllers.swiper = null
            p.controllers.focus = null

            p.modules.fabricbridge = true
            
            try{
                new Presenta(this.$refs.wrap, p).then(scene => {
                    this.instancedScene = scene
                    this.$emit('pscene', scene)
                })
            }catch(e){
                console.log("error", e)
            }
        }
    }
}
</script>


<style>
.presentaSceneWrapper >>> div:focus {
  outline: none;
}
.presentaSceneWrapper{
  user-select: none;
  width: 100%;
  pointer-events: none;
}
</style>
