const aspects = [
  'xMidYMid meet',
  'xMinYMid meet',
  'xMaxYMid meet',

  'xMidYMin slice',
  'xMidYMid slice',
  'xMidYMax slice',

  'xMidYMax meet',

  'xMinYMid slice',
  'xMaxYMid slice',

  'none'
]

export default {
  key: 'svg',
  name: 'Svg',
  description: '',
  value: true,
  options: [
    // { key: 'url', label: 'Svg URL', placeholder: 'http://...', type: 'text', value: '' },
    { key: 'code', placeholder: '<svg...', type: 'scripter', env:'html', value: '' },
    { key: 'css', placeholder: '#a{...}', type: 'scripter', env:'css', value: '' }
    // { key: 'query', type: 'text', value: null },
    //{ key: 'aspect', nav: true, type: 'select', options: aspects.map(o => ({ key: o, label: o, value: o })) }

  ]
}
