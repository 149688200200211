<template>
    <div class="block" :class="{open:show}">

        <CtrlHeader @changed="show=!show" :label="'Fonts Kit'" />
        
        <div v-show="show" class="summary">

            <div class="description">Choose a pre-defined fonts kit that works nicely with the Rich Text element.</div>

            <div class="ctrlcontainer">
                <SelectCtrl :ctrl="kit" @change="onChangeKit" />                
            </div>

            <!-- <div class="more" @click="more=!more">more settings</div> -->

            <div v-if="more">

                <div class="ctrlcontainer">
                    <AreaCtrl :ctrl="fontText" @change="onChangeOpt" />    
                </div>

                <div class="ctrlcontainer">
                    <AreaCtrl :ctrl="fontHead" @change="onChangeOpt" />    
                </div>

                <div class="ctrlcontainer">
                    <AreaCtrl :ctrl="fontAlt" @change="onChangeOpt" />    
                </div>

            </div>


        </div>

    </div>
</template>




<script>
import { mapGetters } from 'vuex'
import CtrlHeader from './CtrlHeader.vue'
import SelectCtrl from '../ui/SelectCtrl'
import TextCtrl from '../ui/TextCtrl'
import AreaCtrl from '../ui/AreaCtrl'

export default {
    props:{
        root:Object
    },
    data(){
        return{
            show: false,
            more: false,
            
            kit: { label: 'Preset', value: '', options: [
                { key: 'montserrat/robotomono', value: 'montserrat/robotomono', label: 'Montserrat / RobotoMono', 
                    fontText:'https://fonts.gstatic.com/s/robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQ--5Ip2sSQ.ttf',
                    fontHead:'https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF7g7J_950vCo.ttf',
                    fontAlt:''
                },

                { key: 'playfair/worksans', value: 'playfair/worksans', label: 'Playfair / Work Sans', 
                    fontText:'https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebukDQZNLo_U2r.ttf',
                    fontHead:'https://fonts.gstatic.com/s/worksans/v11/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNigDp6_cOyA.ttf',
                    fontAlt:''
                },

                { key: 'carrois/roboto', value: 'carrois/roboto', label: 'Carrois / Roboto', 
                    fontText:'https://fonts.gstatic.com/s/carroisgothic/v11/Z9XPDmFATg-N1PLtLOOxvIHl9ZmD3i7ajcJ-.ttf',
                    fontHead:'https://fonts.gstatic.com/s/robotoslab/v13/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaG5iddG-1A.ttf',
                    fontAlt:''
                },
            ] },

            fields: ['fontText', 'fontHead', 'fontAlt'],
            
            fontText: { label: 'Text URL', value: '' },
            fontHead: { label: 'Head URL', value: '' },
            fontAlt: {  label: 'Alt URL',  value: '' }
        }
    },
    computed:{
        space(){
            return this.root.modules.fonts
        }
    },
    watch:{
        space(){
            this.update()
        }
    },
    mounted(){
        this.update()
    },
    components:{
        SelectCtrl, TextCtrl, CtrlHeader, AreaCtrl
    },
    
    methods:{
        update(){
            const ft = this.space
            if(ft){
                this.kit.value = ft.kit
                this.fields.forEach(f => {
                    this[f].value = ft[f]
                })
            }else{
                this.root.modules.fonts = {}
            }
        },
        onChangeKit(ctrl){
            this.setFields()
        },
        onChangeOpt(ctrl){
            this.kit.value = null
            this.consolidate()
        },
        setFields(){
            const k = this.kit.value
            if(k){
                const val = this.kit.options.find(d => d.key === k)
                if(val){
                    this.fields.forEach(f => {
                        this[f].value = val[f]
                    })
                }
            }else{
                this.fields.forEach(f => {
                    this[f].value = ''
                })
            }
            this.consolidate()
        },
        consolidate(){
            const ft = this.root.modules.fonts
            ft.kit = this.kit.value
            this.fields.forEach(f => {
                ft[f] = this[f].value
            })
            this.$emit('change')
        }
    }
}
</script>



<style scoped>
.block{
    width: 100%;
}
.block:hover{
    background-color: #eee;
}
.block.selecting{
    outline:1px solid #24a1f9;
}
.block.open{
    box-shadow: 0 4px 5px #ccc;
    margin-bottom: 1rem;
    background-color: #eee;
}


.inline{
    padding: 0;
}
.inline >>> p{
    margin: 0;
}
.inline >>> label{
    display: flex;
}

select.inline{
    background: none;
    border: none;
}

.description{
    font-size: .75rem;
    width: 100%;
}

.summary{
    display: flex;
    flex-wrap: wrap;
}
.ctrlcontainer{
    width: 100%;
    display: flex;
}


.summary{
    padding: .5rem;
    background-color: white;
}

.more{
    font-size: .7rem;
    text-align: center;
    width: 100%;
    padding: .3rem;
    padding-top: .75rem;
    cursor: pointer;
}

</style>
