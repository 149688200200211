<template>
    <div>
        <div class="inner">
            <ElementCtrl 
                @change="onBlockChanged" 
                v-if="selectedBlockCtrl"
                :index="selectedBlockIndex" 
                @ukey="onNewUkey"
                @swap="onSwap"
                :key="getKeyId(selectedBlockCtrl,selectedBlockIndex)" 
                :item="selectedBlockCtrl" />

            <Ctrl v-if="next.show_adminonly" @change="onModChanged" :isNested="true" :key="getKeyId(ctrl,index)" :item="ctrl" v-for="(ctrl,index) in elementOptions" />
        </div>
    </div>
</template>


<script>

import Ctrl from './Ctrl'
import ElementCtrl from './ElementCtrl'

import element_options from '@/libs/def/element_options'
import oblocks from '@/libs/def/blocks'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { fromUItoSettings, fromSettingsToUI } from './utils'
import { clone, stringify } from '@/libs/stringify'



export default {
    components:{
        Ctrl, ElementCtrl
    },
    data(){
        return{
            sBlock:null,
            blocks:null,
            elementOptions: clone(element_options)
        }
    },
    watch:{
        // currentSlide(v){
        //     console.log('currentSlide', v)
        //     if(v) this.createBlocksUI()
        //     this.sceneBlocks.forEach(b => b.$selected = false)
        // },
        
        selectedBlock(v){
            this.elementOptions = clone(element_options)
            if(v) {
                this.createBlocksUI()
                fromSettingsToUI(this.elementOptions, v)
            }
        }
    },
    
    computed:{
        ...mapGetters(['company', 'selection', 'companyId', 'document', 'selectedBlockIndex']),
        
        next(){
            return this.company && this.company.next ? this.company.next : {}
        },

        iblocks:{
            set(v){
                this.currentSlide.blocks = v
            },
            get(){
                return this.currentSlide ? this.currentSlide.blocks : []
            }
        },
        currentSlide(){
            return this.selection ? this.selection[0] : null
        },
        
        sceneBlocks(){
            return this.currentSlide ? this.currentSlide.blocks : []
        },
        selectedBlock(){
            return this.sceneBlocks && this.selectedBlockIndex >= 0 
                ? this.sceneBlocks[this.selectedBlockIndex] 
                : null
        },
        selectedBlockCtrl(){
            return this.blocks && this.selectedBlockIndex >= 0 
                ? this.blocks[this.selectedBlockIndex] 
                : null
        }
    },
    methods:{
        ...mapMutations(['notifyProjectUpdate', 'notifySlideUpdate', 'setSelectedBlockIndexFromSidebar']),
        
        onBlockChanged(ob){
            console.log('block edit', ob)
            const block = this.currentSlide.blocks[ob.index]

            const tmp = {}
            fromUItoSettings(ob, tmp)

            const bob = tmp[ob.item.key]
            
            for(var k in bob){
                block[k] = bob[k]
            }

            // edge case: remove the edited prop that is now false but was true before edit (boolean)
            if(bob && !bob[ob.ctrl.key] && block[ob.ctrl.key]) delete block[ob.ctrl.key]

            block.hidden = !bob
            
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },
        onModChanged(ob){
            fromUItoSettings(ob, this.selectedBlock)
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },

        onNewUkey({index, ukey}){
            this.currentSlide.blocks[index].ukey = ukey
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },

        createBlocksUI(){
            const arr = []
            if(this.currentSlide){
                this.currentSlide.blocks.forEach(b => {
                    const bl = oblocks.find(blk => blk.key === b.type)
                    if(bl){
                        const bcopy = clone(bl)
                        bcopy.options.forEach(o => {
                            if(b.hasOwnProperty(o.key)) {
                                if(o.type === 'list'){
                                    o.values = b[o.key].map((v,i) => {
                                        const t = clone(o.template)
                                        t.value = v
                                        t.key += i
                                        return t
                                    })
                                }else{
                                    o.value = b[o.key]
                                }
                                
                            }
                        })

                        bcopy.ukey = b.ukey
                        bcopy.value = !b.hidden                        

                        arr.push(bcopy)
                    }
                })
            }
            this.blocks = arr
        },


        getKeyId(ctrl,index){
            const host = this.selectedBlock
            const url = host ? host.$url : ''
            return ctrl.key + index + url
        },

        onSwap({index, type}){
            this.currentSlide.blocks[index].type = type
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        }

    }
}
</script>




<style scoped>
.inner{
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    padding:.5rem 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .4rem;
}

select{
    font-size: 1rem;
    font-family: monospace;
    padding:.35rem;
    width: 100%;
    border:none;
    border:1px solid #ddd;
}

.inner p{
    font-size: .8rem;
    margin: 0;
    margin-bottom: .5rem;
    text-align: center;
    width: 100%;
}
.inner p.middle{
    margin-top: 2rem;
}


.blocksCont{
    display: flex;
    flex-wrap: wrap;
}
.blockThumb{
    width: 25%;
    height: 60px;
    padding: .5rem;
    display: flex;
    cursor: pointer;
}
.blockThumbInner:hover{
    background-color: #eee;
}   
.blockThumbInner{
    width: 100%;
    height: 100%;
    padding: .3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border:1px solid #ccc;
    font-size: .7rem;
    text-align: center;
}
</style>