<template>
    <div class="modalWrapper" @click="$emit('close')">
        <div class="modalInner" @click.stop="" :style="{width: w}">
            <div v-if="!hideClose" class="close" @click="$emit('close')"><img src="@/assets/icons/close.svg" /></div>
            <slot></slot>
        </div>
    </div>
</template>




<script>
export default {
    props:{
        w:String,
        hideClose:false
    }
}
</script>


<style scoped>
.modalWrapper{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,.5);
    padding: 3rem;
    z-index: 9999;

    display: flex;
    justify-content: center;
}

.modalInner{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;

    padding: 1rem;
    position: relative;
}

.close{
    text-align: right;
    cursor: pointer;
    position: absolute;
    top:5px;
    right:5px;
}
.close img{
    width: 20px;
}
</style>