<template>
  <div class="view">
      <div class="modal">

        <div class="wrapper">
          <div class="inner">
            <h3>Set your password:</h3>

            <div class="field">
                <p>Password (at least 6 chars):</p>
                <input type="password" v-model="pass1" placeholder="" @keyup.enter="submit" />
            </div>

            <div class="field">
                <p>Repeat password:</p>
                <input type="password" v-model="pass2" placeholder="" @keyup.enter="submit" />
            </div>

            <div class="field" v-if="message">
              <p class="alert">{{message}}</p>
            </div>

            <div class="field">
              <button @click.prevent="submit" :disabled="noPass()">Set</button>
            </div>

            <div class="field" v-if="updated">
                <p><router-link to="/login">Good! Now proceed with a Log-in</router-link></p>
            </div>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data(){
    return {
        pass1: '',
        pass2: '',
        message: '',
        updated: false
    }
  },
  computed:{
      token(){
          return this.$route.query.token
      }
  },
  methods:{
    ...mapActions(['resetPassword']),
    async submit(){
        var res = await this.resetPassword({ password: this.pass1, token: this.token })
        console.log(res)
        if(!res.data.error){
            this.updated = true
        }else{
            this.message = res.data.message
        }
    },
    noPass: function () {
      var res = !(this.pass1 === this.pass2 && this.pass1.length > 5 && this.token && this.token.length > 0)
      return res
    }
  }
}
</script>

<style scoped>
.warning{
  color:red;  
}
</style>
