<template>
    <div class="empty">
        <p>{{text}}</p>
        <slot></slot>
    </div>
</template>


<script>

export default {
  props:{
    msg:String
  },
  computed:{
    text(){
      return this.msg
    }
  }
}
</script>


<style scoped>
.empty{
  flex:1;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>