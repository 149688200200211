<template>
    <div class="inner">
        <!-- <p class="first">Current Blocks:</p> -->

        <div style="width:100%; margin-bottom:1rem;" v-if="!pTemplateID">
            <button v-tooltip="'Open the API/URL Panel'" style="width:100%;" class="btn positive" @click="openTestPanel">
                Publish and Test API-URL
            </button>   
        </div>

        <div style="display:flex;width:100%;margin-bottom:1rem;" v-if="pTemplateID">
            <button style="flex:2;" v-tooltip="'Open the API/URL Panel'" class="btn" :class="getEditApiBtnColor" @click="openTestPanel">
                {{ getEditApiBtnLabel }}
            </button> 
            <!-- <button v-if="next.show_scheduler" style="flex:1;margin-left:.5rem;" 
                v-tooltip="'Open the AUTO Panel'" 
                @click="openAutomatePanel"
                class="btn" :class="getSchedulerClass" >
                Automated: {{ getSchedulerStatus }}</button>   -->
        </div>


        <Query v-if="currentSlide" :root="currentSlide" @change="onChanged" />
        <Prompt :root="document" @change="onChanged" />
        <PreScript :root="document" @change="onChanged" />

        <p class="middle">Current Blocks:</p>

        <ElementSummary 
            @delete="deleteBlock" 
            @duplicate="duplicateBlock" 
            @lock="onLockToggle"
            @select="setSelectedBlockIndexFromSidebar"
            @ukey="onNewUkey"
            :index="index" 
            :key="getKeyId(ctrl,index)" 
            :item="ctrl" 
            v-for="(ctrl,index) in blocks" />


        <div style="width:100%; margin-top:1rem;" v-if="figmaUrl">
            <button v-tooltip="'Reload the linked Figma file <br />and update this Template'" 
                style="width:100%;" class="btn light" 
                @click="doUpdateFigma=true">
                Update Figma File
            </button>   
        </div>
        <p style="width:100%; margin-top:.5rem;"><a v-if="figmaUrl" :href="figmaUrl" target="_blank">Open Figma File</a></p>


        <!-- <div class="section"> -->

            <!-- <p class="middle">Frame options:</p> -->

            

            <!-- <Format v-if="currentSlide" :root="currentSlide" @change="onChanged" /> -->
            <!-- <FrameID v-if="currentSlide && isProjectAPI" :root="currentSlide" @change="onChanged" /> -->
            <!-- <Fonts v-if="currentSlide" :root="currentSlide" @change="onChanged" />
            <Colors v-if="currentSlide" :root="currentSlide" @change="onChanged" /> -->

            <!-- <div v-if="publicUrl">
                <Ctrl @change="onCtrlChanged" :key="getKeyId(ctrl,index)" :item="ctrl" v-for="(ctrl,index) in frameOptions" />
            </div>
            <Ctrl @change="onCtrlChanged" :key="getKeyId(ctrl,index)" :item="ctrl" v-for="(ctrl,index) in frameOptions2" /> -->
        <!-- </div> -->

        <FigmaRefresher v-if="doUpdateFigma" @updated="figmaUpdated" />
    </div>
</template>


<script>
import ElementSummary from './ElementSummary'
import Ctrl from './Ctrl'
import element_options from '@/libs/def/element_options'
import frame_options from '@/libs/def/frame_options'
import oblocks from '@/libs/def/blocks'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Draggable from 'vuedraggable'
import { fromUItoSettings, fromSettingsToUI } from './utils'
import { clone, stringify } from '@/libs/stringify'
import { getDefaultBlock } from '@/libs/defaults'
import uuid from '@/libs/uuid'
import Format from './ctrls/Format'
import Fonts from './ctrls/Fonts'
import Colors from './ctrls/Colors'
import FrameID from './ctrls/FrameID'
import Query from './ctrls/Query'
import PreScript from './ctrls/PreScript'
import Prompt from './ctrls/Prompt'
import FigmaRefresher from '../FigmaRefresher.vue'

oblocks.forEach(b => {
    b.locked = false
})


export default {
    components:{
        ElementSummary, Ctrl, Draggable, Format, Fonts, Colors, FrameID, Query, PreScript,
        FigmaRefresher, Prompt
    },
    data(){
        return{
            sBlock:null,
            blocks:null,
            elementOptions:null,
            frameOptions:clone(frame_options.filter(d => d.key !== 'script')),
            frameOptions2:clone(frame_options.filter(d => d.key === 'script')),
            doUpdateFigma:false
        }
    },
    mounted(){
        console.log('aaa', this.document, this.document.figmaUrl)
    },
    watch:{
        currentSlide(v){
            if(v){
                this.genQuery()

                this.elementOptions = clone(element_options.filter(d => {
                    return !d.visible || (d.visible && d.visible.indexOf('scene')>=0)
                }))
                this.createBlocksUI()
                fromSettingsToUI(this.elementOptions, v)
                fromSettingsToUI(this.frameOptions, v)
                fromSettingsToUI(this.frameOptions2, v)
            }
            this.sBlock = null
            this.sceneBlocks.forEach(b => b.$selected = false)
            this.sceneForPreset = null


        },
        sBlock(v){
            let b = this.currentSlide
            let filt = 'scene'
            if(v){
                b = v
                filt = 'block'
            }
            this.elementOptions = clone(element_options.filter(d => {
                return !d.visible || (d.visible && d.visible.indexOf(filt)>=0)
            }))
            fromSettingsToUI(this.elementOptions, b)
        }
    },
    
    computed:{
        ...mapGetters(['selection', 'companyId', 'document', 'company', 'publicUrl']),
        dragOptions() {
            return {
                animation: 100,
                disabled: false,
                ghostClass: 'ghost',
                dragClass:'dragging',
                direction: 'vertical'
            }
        },

        figmaUrl(){
            return this.document.figmaUrl
        },


        getEditApiBtnLabel(){
            let s = 'Publish and Test API-URL'
            if(this.pTemplateID) s = 'View and Test API-URL'
            if(this.frameAPIChanged) s = 'Update and Test API-URL'
            return s
        },
        getEditApiBtnColor(){
            let s = {positive:true}
            if(this.pTemplateID) s = {alternate:true}
            if(this.frameAPIChanged) s = {attract:true}
            return s
        },


        getSchedulerClass(){
            let s = {light:true}
            if(this.schedulerActive) s = {positive:true}
            return s
        },
        getSchedulerStatus(){
            return (this.schedulerActive) ? 'ON' : 'OFF'
        },

        schedulerActive(){
            return this.scheduler ? this.scheduler.active : null
        },
        scheduler(){
            return this.document ? this.document.scheduler : null
        },

        iblocks:{
            set(v){
                this.currentSlide.blocks = v
            },
            get(){
                return this.currentSlide ? this.currentSlide.blocks : []
            }
        },
        currentSlide(){
            return this.selection ? this.selection[0] : null
        },
        oblocks(){
            return oblocks
        },
        blocksbasic(){
            return clone(this.oblocks.filter(d => d.basic && !d.hidden))
        },
        blocksrich(){
            return clone(this.oblocks.filter(d => !d.basic && !d.hidden))
        },
        sceneBlocks(){
            return this.currentSlide ? this.currentSlide.blocks : []
        },
        next(){
            return this.company && this.company.next ? this.company.next : {}
        },
        isProjectAPI(){
            return this.document.pProjectID
        },

        pTemplateID(){
            return this.currentSlide ? this.currentSlide.pTemplateID : null
        },
        frameAPIChanged(){
            return this.currentSlide ? this.pTemplateID && this.currentSlide.pFrameAPIChanged : null
        },

    },
    methods:{
        ...mapMutations(['notifyProjectUpdate', 'notifySlideUpdate', 
        'setSelectedBlockIndexFromSidebar', 'setTemplateAPI', 'setAutomateObject']),
        

        openTestPanel(){
            this.setTemplateAPI(this.currentSlide)
        },

        openAutomatePanel(){
            this.setAutomateObject(this.currentSlide)
        },

        getTags(str) {
            let mergeTags = []
            const tags = str.match(/{{\s*[\w\.]+\s*}}/g)
            if(tags) mergeTags = tags.map((x) => { return x.match(/[\w\.]+/)[0]; })
            return mergeTags
        },

        genQuery(){
            let allTags = []
            let src = ''
            this.currentSlide.blocks.forEach(b => {
                if(b.type === 'svg') src = b.code
                if(b.type === 'image') src = b.url
                if(b.type === 'markup') src = b.html + b.css
                if(b.type === 'text') src = b.content
                if(b.type === 'qrcode') src = b.content

                let tags = this.getTags(src)

                //if(b.type === 'image' && tags.length === 0) tags = [b.ukey]

                allTags = allTags.concat(tags)

            })
            allTags = [...new Set(allTags)]
            const allQuery = allTags.reduce((a, v) => ({ ...a, [v]: ''}), {}) 

            const prevQuery = this.currentSlide.query
            if(prevQuery){
                for(const k in prevQuery){
                    allQuery[k] = prevQuery[k]
                }
            }

            this.currentSlide.query = allQuery
        },

        onBlockChanged(ob){
            if(!this.currentSlide) return
            const block = this.currentSlide.blocks[ob.index]

            const tmp = {}
            fromUItoSettings(ob, tmp)

            const bob = tmp[ob.item.key]
            
            for(var k in bob){
                block[k] = bob[k]
            }

            // edge case: remove the edited prop that is now false but was true before edit (boolean)
            if(bob && !bob[ob.ctrl.key] && block[ob.ctrl.key]) delete block[ob.ctrl.key]

            block.hidden = !bob
            
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },
        onModChanged(ob){
            const dest = this.sBlock || this.currentSlide
            fromUItoSettings(ob, dest)
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },

        onChanged(){
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },

        addBlock(b){
            const arr = this.currentSlide.blocks
            const ukey = uuid.block()

            const bl = getDefaultBlock()

            const defProps = b.props || {coords:{top: 20,left: 20,width: 60,height: 60}}

            bl.type = b.key
            bl.aliasType = b.aliasType
            bl.ukey = ukey
            bl.$url = ukey
            bl.modules = {...defProps}
            
            arr.push(bl)

            // fill the default
            b.options.forEach(f => {
                if(f.value) bl[f.key] = f.value
            })

            b.ukey = ukey // ?

            this.setSelectedBlockIndexFromSidebar(arr.length-1)
            this.createBlocksUI()
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },
        deleteBlock(index){
            const arr = this.currentSlide.blocks
            arr.splice(index, 1)

            this.createBlocksUI()
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },
        duplicateBlock(index){
            const arr = this.currentSlide.blocks
            const bl = clone(arr[index])

            const ukey = uuid.block()
            bl.$url = ukey
            bl.ukey = ukey
            
            arr.push(bl)

            this.createBlocksUI()
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },

        figmaUpdated(blocks){
            if(!blocks) return

            const actualBlocks = this.currentSlide.blocks

            const reservedKeys = ['ukey', 'url'] // image url not supported yet

            const newKeysArr = blocks.map(d => d.ukey)
            const actKeysArr = actualBlocks.map(d => d.ukey)

            actualBlocks.forEach(b => {
                const findUpdated = blocks.find(d => d.ukey === b.ukey)
                if(findUpdated){
                    console.log('update', b.ukey)
                    b.modules = findUpdated.modules

                    for(const k in b){
                        if(reservedKeys.indexOf(k) === -1 && k[0] !== '$'){
                            b[k] = findUpdated[k]
                        }
                    }
                }
            })

            // new blocks
            const newBlocks = []
            newKeysArr.forEach((k,i) => {
                if(actKeysArr.indexOf(k) === -1){
                    newBlocks.push(i)
                }
            })

            // deleted blocks
            const delBlocks = []
            actKeysArr.forEach((k,i) => {
                if(newKeysArr.indexOf(k) === -1){
                    delBlocks.push(i)
                }
            })

            console.log('diff', newBlocks, delBlocks)

            delBlocks.reverse().forEach(index => {
                actualBlocks.splice(index, 1)
            })

            newBlocks.reverse().forEach(index => {
                actualBlocks.splice(index, 0, blocks[index])
            })

            
            this.createBlocksUI()
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
            this.doUpdateFigma = false
        },

        onLockToggle({index, lock}){
            const arr = this.currentSlide.blocks
            const bl = arr[index]
            bl.$locked = lock
        },

        onNewUkey({index, ukey}){
            this.currentSlide.blocks[index].ukey = ukey
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },

        onOpenToggle(ob){
            const {open, index} = ob
            this.currentSlide.blocks.forEach(b => (b.$selected = false))
            if(open) this.selectBlock(index)
        },

        selectBlock(index){
            this.currentSlide.blocks.forEach(b => (b.$selected = false))
            if(index >= 0) {
                this.currentSlide.blocks[index].$selected = true
                this.sBlock = this.sceneBlocks[index]
            }
        },

        createBlocksUI(){
            const arr = []
            if(this.currentSlide){
                this.currentSlide.blocks.forEach(b => {
                    let bl = this.oblocks.find(blk => blk.key === b.type)
                    if(!bl) bl = this.oblocks.find(blk => blk.key === 'notfound')
                    const bcopy = clone(bl)
                    bcopy.options.forEach(o => {
                        if(b.hasOwnProperty(o.key)) {
                            if(o.type === 'list'){
                                o.values = b[o.key].map((v,i) => {
                                    const t = clone(o.template)
                                    t.value = v
                                    t.key += i
                                    return t
                                })
                            }else{
                                o.value = b[o.key]
                            }
                            
                        }
                    })

                    bcopy.ukey = b.ukey
                    bcopy.value = !b.hidden                        

                    arr.push(bcopy)
                })
            }
            this.blocks = arr
        },

        sorted(){
            this.setSelectedBlockIndexFromSidebar(-1)
            this.createBlocksUI()
            this.notifyProjectUpdate()
            this.notifySlideUpdate(this.currentSlide)
        },

        getKeyId(ctrl,index){
            const host = this.currentSlide //this.sBlock || this.currentSlide
            const url = host ? host.$url : ''
            return ctrl.name + index + url
        },


        onCtrlChanged(ob){
            console.log('onCtrlChanged', ob)
            fromUItoSettings(ob, this.currentSlide)
            this.notifyProjectUpdate()
        }
    }
}
</script>




<style scoped>
.inner{
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    padding:.5rem 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .4rem;
}

select{
    font-size: 1rem;
    font-family: monospace;
    padding:.35rem;
    width: 100%;
    border:none;
    border:1px solid #ddd;
}

.section{
    width: 100%;
}

.inner p{
    font-size: .8rem;
    margin: 0;
    margin-bottom: .5rem;
    text-align: center;
    width: 100%;
}
.inner p.first{
    margin-top: .5rem;
}
.inner p.middle{
    margin-top: .5rem;
    padding-top: 1rem;
    border-top: 1px solid #ddd;
}


.blocksCont{
    display: flex;
    flex-wrap: wrap;
}
.blockThumb{
    width: 25%;
    height: 60px;
    padding: .5rem;
    display: flex;
    cursor: pointer;
}
.blockThumbInner:hover{
    background-color: #eee;
}   
.blockThumbInner{
    width: 100%;
    height: 100%;
    padding: .3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border:1px solid #ccc;
    font-size: .7rem;
    text-align: center;
}
</style>