<template>
    
    <div class="container"
        v-drawerme:selector="'.presentaSceneWrapper'" 
        @begindrawer="onStartDrawer"
        @drawering="onDrawering"
        @enddrawer="onEndDrawer"
        :class="{gDrawering:isDrawering, gMultiSelected:isMultiSelection}">

        <!-- <div v-if="isSingleSelection && !isMobileMode" class="tipkeys">Use SHIFT or CTRL key to select multiple scenes</div> -->
        
        <Draggable 
            @start="setIsSlideDragging(true)" @end="endDraggedSlide"
            v-model="slidesList" 
            :handle="'.dragHandle'"
            class="slides" 
            multi-drag
            v-bind="dragOptions"
            :class="getClasses">

            <transition-group class="transwrap" name="list">
                <SlideWrapper v-for="(slide, index) in slidesList" 
                    :style="getZoomFactor"
                    :key="slide.$url"
                    :slide="slide"
                    :index="index"
                    :islast="isLast(index)" />
            </transition-group>

        </Draggable>


    </div>

</template>


<script>
import {mapGetters, mapMutations} from 'vuex'
import Draggable from 'vuedraggable'

import SlideWrapper from './slide/SlideWrapper'
import drawerme from './Drawer.dir.js'

export default {
    components:{
        SlideWrapper,
        Draggable
    },
    directives:{
        drawerme
    },
    mounted(){
        // window.onresize = this.resize
        // this.resize()
    },
    watch:{
        zoom(){
            //this.resize()
        }
    },
    data(){
        return{
            isDrawering:false
        }
    },
    computed:{
        ...mapGetters(['project', 'prefs', 'slides', 
        'isEmptySelection', 'isSingleSelection', 'isMultiSelection',
        'isMobileMode', 'isSlideDragging']),

        slidesList: {
            get() {
                return this.slides
            },
            set(value) {
                this.$store.commit('updateSlides', value)
            }
        },
        dragOptions() {
            return {
                animation: 250,
                group: 'slides',
                disabled: false,
                ghostClass: 'ghost',
                dragClass:'dragging',
                selectedClass:'dSelected',
                multiDragKey:'CTRL',
                supportPointer:true
            }
        },
        zoom(){
            let z = this.prefs ? this.prefs.zoom : 3
            return this.isMobileMode ? 1 : z
        },
        getZoomFactor(){
            const v = 100 / this.zoom
            return {width: v + '%'}
        },
        getClasses(){
            return {gIsDragging: this.isSlideDragging, gHasSelection: !this.isEmptySelection}
        }
    },
    methods:{
        ...mapMutations([
            'addToSelection', 
            'clearSelection', 
            'setSlideRederedWidth',
            'setIsSlideDragging',
            'setIsDrawering',
            'unsetIsDrawering',
            'notifyProjectUpdate']),
        resize () {
            document.documentElement.style.setProperty('--fz', this.zoom)
            var bbwrap = this.$el.getBoundingClientRect()
            var pad = 64 + (+this.zoom - 1) * 32
            document.documentElement.style.setProperty('--vp', bbwrap.width - pad)

            let slideActualWidth = 960 / (960/(bbwrap.width - pad)) / this.zoom
            this.setSlideRederedWidth(slideActualWidth)

            this.notifyProjectUpdate()
        },
        isLast(index){
            return index === this.slides.length - 1
        },

        onStartDrawer () {
            this.clearSelection()
            this.setIsDrawering()
        },

        onDrawering (ob) {
            this.isDrawering = true
            this.clearSelection()
            ;[].slice.call(this.$el.querySelectorAll('.outwrapped')).forEach(s => s.classList.remove('tmpselection'))
            ob.detail.arr.forEach(id => {
                let s = this.$el.querySelector(`.outwrapped:nth-child(${id+1})`)
                s.classList.add('tmpselection')
                this.addToSelection(this.slides[id])
            })
        },

        onEndDrawer (ob) {
            this.isDrawering = false
            this.unsetIsDrawering()

//;[].slice.call(this.$el.querySelectorAll('.tmpselection')).forEach(s => MultiDrag.singleton.utils.select(s))
            //;[].slice.call(this.$el.querySelectorAll('.outwrapped')).forEach(s => s.classList.remove('tmpselection'))
        },


        endDraggedSlide(){
            this.notifyProjectUpdate()
            this.setIsSlideDragging(false)
        }
    }
}
</script>



<style scoped>
.container{
    width: 100%;
    /* height: 100%; */ /* check this */
    /* display: flex; */
    position: relative;

    flex:1;
}

.slides{
    padding: 1rem;
    padding-bottom: 1rem;
    padding-top: 2rem;
}


.focusMode.container{
    height: 100%;
}
.focusMode .slides{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
}


.gIsDragging{
    background-color:var(--ghighlightcolor);
}

.transwrap{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}


.tipkeys{
    position: absolute;
    width:100%;
    text-align: center;
    font-size: .7rem;
    top: 10px;
    color:#999;
    user-select: none;
}


.list-enter-active, 
.list-leave-active {
  transition: opacity .4s ease-in-out, transform .4s cubic-bezier(.9, .1, .1, .9);
}
.list-enter, 
.list-leave-to{
  opacity: 0;
  transform: translateY(-40px);
}

/*
.list-move {
  transition: transform .4s cubic-bezier(.9, .1, .1, .9);
}
*/


</style>


<style>
.drawer{
  background-color:blue;
  position:absolute;
  background-color: rgba(0,255,255,.2);
  border:3px dashed blue;
  user-select: none;
  display: none;
}
.gDrawering{
  user-select: none;
}
</style>