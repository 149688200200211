<template>
    <div class="linerow" :class="{deleting: project.deleting}">
        <div class="img">
            <router-link :to="'/edit/' + project.url">
            <img loading="lazy" :src="getThumb(project)" />
            </router-link>
        </div>
        <div class="colrow">
            <p class="cmain"><router-link :to="'/edit/' + project.url">{{ project.name }}</router-link></p>
            <p class="cmin">Last edit {{ updatedAt(project) }} - Created {{ createdAt(project) }}</p>
            <!-- <p class="cmin">{{ slideNum(project) }} 
              <span v-if="project.publicUrl" class="public"><a :href="getPublicUrl()" target="_blank">public link</a></span>
            </p> -->
            <div v-if="project.scheduler" class="scheduled">Automated</div>
        </div>
        <div class="overrow hideIfMobile">
            <p><button  v-tooltip="'Edit'" class="attract withicon small" @click.prevent="openProject(project)"><img class="icon" src="@/assets/icons/edit.svg" /></button></p>
            <DuplicateBtn :project="project" />
            <DeleteBtn :project="project" />
        </div>
    </div>
</template>



<script>
import moment from 'moment'
import DuplicateBtn from './presentationitem/DuplicateBtn'
import DeleteBtn from './presentationitem/DeleteBtn'
import { mapGetters } from 'vuex'

export default {
    props:{
        project:Object
    },
    components:{
      DeleteBtn, DuplicateBtn
    },
    computed:{
      ...mapGetters(['companyId'])
    },
    methods:{
        getThumb () {
          let p = this.project
          return `${process.env.VUE_APP_CACHE_RENDERER}/THUMBS${this.companyId}:${p.url}`
        },
        updatedAt () {
            return moment(this.project.updated_at).fromNow()
        },
        createdAt () {
            return moment(this.project.created_at).fromNow()
        },
        slideNum () {
            const v = this.project.slides
            return v === 1 ? v + ' frame' : v + ' frames'
        },
        openProject () {
            this.$router.push('/edit/' + this.project.url)
        },
        getPublicUrl(){
          return process.env.VUE_APP_PUBLIC_DOC_BASE_URL + '/?id=' + this.project.publicUrl
        },
    }
}
</script>



<style scoped>
li .cmain{
  font-weight: bolder;
  margin-top: .5rem;
}

li .cmin{
  font-size: .8rem;
  color: #999;
}

li p{
  margin: 0;
}

li a{
  text-decoration: none;
}

.linerow{
  display: flex;
  position: relative;
  flex-direction: column;
}

.colrow{
  display: flex;
  flex-direction: column;
  
}

.overrow{
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  opacity: 0;
  transition: opacity .25s ease-in;
}
.overrow a{
  pointer-events: auto;
}
.overrow p{
  padding: .3rem;
}

.scheduled{
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--btnGreenColorHover);
  font-size: .8rem;
  color: white;
  padding: .1rem .3rem;
  text-transform: uppercase;
}

@media(min-width: 800px){
  .linerow{
    flex-direction: row;
  }
  ul.list li:hover .overrow{
    opacity: 1;
  }
  .colrow{
    padding: 0 .5rem;
  }
  li .cmain{
    margin-top: 0;
  }
}


.sorted{
  font-weight: bold;
  background-color: #000;
  color: white;
}



.linerow .img{
  width: 100%;

  overflow: hidden;
  height: 112px;
}
.linerow img{
  width: 100%;
}
.linerow a img{
  display: block;
}

@media(min-width: 800px){
  .linerow .img{
    width: 200px;
  }
}

.public a{
  font-weight: bold;
  color: var(--btnGreenColor);
  text-decoration: underline;
}
</style>