
import Vue from 'vue'

export default {

  mutations: {
    notifySlideUpdate (state, slide) {
      console.log('slide updated')
      // slide.idx = Math.random()
      slide.pFrameAPIChanged = true
    },
    addImageToSlide (state, ob) {
      const s = ob.slide
      const img = ob.image
      const key = ob.key

      s.blocks.push({
        type: 'image',
        url: img,
        key: key
      })
    },

    // called on each text update and on each asset change
    bestPrefsForText (state, slide) {
      if (slide.text) {
        if (slide.asset) Vue.set(slide.prefs.classes, 'imgoverlay', 'fume')
      } else {
        Vue.set(slide.prefs.classes, 'imgoverlay', null)
      }

      if (!slide.configured) {
        if (slide.text) {
          const html = slide.text

          const h1 = html.match(new RegExp('<h1>(.*?)</h1>', 'gim'))
          const h2 = html.match(new RegExp('<h2>(.*?)</h2>', 'gim'))
          const other = html.match(new RegExp('<(ul|ol|p|blockquote)>(.*?)</(ul|ol|p|blockquote)>', 'gim'))

          // if ((h1 || h2) && !other) {
          //   Vue.set(slide.prefs.classes, 'layoutvar', '')
          // }
          // if (other) {
          //   Vue.set(slide.prefs.classes, 'layoutvar', 'layb')
          // }

          // set prefs layout based on text structure
          // h1/h2 -> heading
          // txt/list -> text
          // quote > variation
        }
      }
    }
  }
}
