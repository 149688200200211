<template>
  <div id="app" :class="{gIsMobile: isMobileMode, gIsTouch: isTouchMode}">
    <GlobalHeader v-if="!$route.meta.noglobalbar" />
    <router-view/>
  </div>
</template>


<script>
import 'normalize.css'
import './assets/generic.css'
import './assets/button.css'
import './assets/modal.css'
import './assets/page.css'
import './assets/tooltip.css'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import GlobalHeader from '@/components/header/GlobalHeader'

export default {
  components:{
    GlobalHeader
  },
  
  watch:{
    async companyId(){

      if(!this.companyId) return

      const list = await this.getPlugins(this.companyId)
      
      if(list.data) {
        const autoPlugins = list.data.plugins.filter(d => d.type === 'plug')
        this.setAutoPlugins(autoPlugins)

        const blockPlugins = list.data.plugins.filter(d => d.type === 'block')
        this.setBlockPlugins(blockPlugins)

        const scenePlugins = list.data.plugins.filter(d => d.type === 'scene')
        this.setScenePlugins(scenePlugins)
      }

    }
  },
  computed:{
    ...mapGetters(['isMobileMode', 'isTouchMode', 'companyId', 'pluginsLoaded'])
  },
  methods:{
    ...mapActions(['getPlugins']),
    ...mapMutations(['setAutoPlugins', 'setBlockPlugins', 'setScenePlugins'])
  }
}
</script>
