<template>
    <div class="block" :class="{open:show}">

        <CtrlHeader @changed="show=!show" :label="'GPT-3 Prompt'" />
        
        <div v-show="show" class="summary">

            <div class="description">Write a Prompt to return a JSON object, very early,
                <a href="https://www.presenta.cc/blog/gpt-3-and-presenta" target="_blank">learn more</a>
            </div>

            <div class="ctrlcontainer">
                <ScripterCtrl type="prompt" :ctrl="code" @change="onChange" />
            </div>

        </div>

    </div>
</template>




<script>
import CtrlHeader from './CtrlHeader.vue'
import ScripterCtrl from '../ui/ScripterCtrl'

export default {
    props:{
        root:Object
    },
    data(){
        return{
            show: false,
            code:  {label:'Prompt', value:'', env:'text'}
        }
    },
    mounted(){
        this.update()
    },
    computed:{
        gpt3(){
            return this.root.gpt3
        }
    },
    watch:{
        gpt3(){
            this.update()
        }
    },
    components:{
        CtrlHeader, ScripterCtrl
    },
    
    methods:{
        update(){
            if(this.root.gpt3){
                this.code.value = this.root.gpt3
            }else{
                this.code.value = ''
            }
        },
        
        onChange(){
            this.consolidate()
        },
        consolidate(){
            this.root.gpt3 = this.code.value
            this.$emit('change')
        }
    }
}
</script>



<style scoped>
.block{
    width: 100%;
}
.block:hover{
    background-color: #eee;
}
.block.selecting{
    outline:1px solid #24a1f9;
}
.block.open{
    box-shadow: 0 4px 5px #ccc;
    margin-bottom: 1rem;
    background-color: #eee;
}



.inline{
    padding: 0;
}
.inline >>> p{
    margin: 0;
}
.inline >>> label{
    display: flex;
}

select.inline{
    background: none;
    border: none;
}

.description{
    font-size: .75rem;
    width: 100%;
}

.summary{
    display: flex;
    flex-wrap: wrap;
}
.ctrlcontainer{
    width: 100%;
    display: flex;
}



.summary{
    padding: .5rem;
    background-color: white;
}

</style>
