<template>
    <ModalContainer @close="$emit('close')">
        <div class="wrap">
            <h3>Markdown importer</h3>
            <p>You can convert a <b>Markdown</b> file into a <b>PRESENTA</b> document using this tool.<br /> 
                The markdown needs to be formatted properly using the "---" separator for each frame.
                There are some limitations though, <a href="">here the full documentation</a>.</p>

            <div class="field">
                <p>Pick a Markdown file:</p>
                <input type="file" ref="upload" accept="text/*" @change="requestFile" />
            </div>

            <div v-if="parsed">
                <div class="field">
                    <p>Raw content:</p>
                    <textarea v-model="raw" placeholder="Paste Raw Markdown"></textarea>
                </div>
                <p>Detected: {{parsed.scenes.length}} frames.</p>
            </div>
            
            <div class="field">
            <button :disabled="!raw" @click="createProject">Import Markdown</button>
            </div>
        </div>
    </ModalContainer>
</template>



<script>
import { mapActions, mapGetters } from 'vuex'
import defaults, {fixScene} from '@/libs/defaults'
import {clone} from '@/libs/stringify'
import md2pjson from '@presenta/md2pjson'
import ModalContainer from '@/components/ModalContainer'

export default {
    data(){
        return{
            frames:[],
            purl:'',
            raw: '',
            parsed: null,
            name:'Untitled'
        }
    },
    computed:{
        ...mapGetters(['companyId'])
    },
    components:{
        ModalContainer
    },
    methods:{
        ...mapActions(['getTemplate']),
        requestFile(){
            var e = this.$refs.upload
            const file = e.files[0]

            this.name = file.name || 'Untitled'

            let fr = new FileReader()
            fr.readAsText(file)
            fr.onload = () => {
                this.raw = fr.result
                this.parsed = md2pjson.parse(fr.result)
            }; 
            fr.onerror = () => {
                console.log(fr.error)
            }; 
        },
        
        createProject(){
            
            let p = clone(defaults.defaultProject)
            p.curl = this.companyId
            p.name = this.name
            p.document.scenes = this.parsed.scenes

            p.document.scenes.forEach(s => {
                s.pFrameID = null
                s.pTemplateID = null
                fixScene(s)

                s.blocks.forEach(b => {
                    b.modules.coords = {top:5, left:5, width:90, height:90}
                })
            })

            this.$emit('done', p)
        },
        
        
    }
}
</script>


<style scoped>
.wrap{
    overflow: hidden;
    overflow-y: auto;
    padding-right: 1rem;
}
textarea, input{
    width: 100%;
}
</style>