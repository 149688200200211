<template>
    <ModalContainer hideClose="true">
        
        <div class="wrapperInner">

            <p>Updating the Template from Figma file. Please wait...</p>
            
        </div>
    </ModalContainer>
</template>




<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalContainer from '@/components/ModalContainer'
import figma2pjson from '@presenta/figma2pjson'
import defaults, {fixBlocks} from '@/libs/defaults'

export default {
    components:{
        ModalContainer
    },
    computed:{
        ...mapGetters(['document']),
        figmaUrl(){
            return this.document.figmaUrl
        },
    },
    async mounted(){
        this.goUpdate()
    },
    methods:{
        ...mapMutations(['notifyProjectUpdate', 'notifySlideUpdate']),
        async goUpdate(){
            console.log('figmaRefresher', this.document)

            try{

                const url = this.figmaUrl
                const furl = new URL(url)
                const parts = furl.pathname.split('/')
                const figmaId = parts[2]
                const params = furl.searchParams
                const urlFrameId = params.get('node-id')
                const frameId = urlFrameId.replace('-', ':')

                const doc =  await figma2pjson.init({docId: figmaId, proxyUrl: process.env.VUE_APP_FRONT_FUNCTIONS + '/fg'})

                this.fetchMessage = 'Analyzing (2/4) ... please wait...'

                const frame = doc.frames.find(d => d.id === frameId)

                if(frame){
                    await this.convertFigmaFrame(frame)
                }else{
                    console.log('Frame not found')
                    this.$emit('updated')
                }

            }catch(e){
                console.log('fetchFigmaDoc', e)
                this.$emit('updated')
            }

            

        },
        async convertFigmaFrame(frame) {

            const config = await figma2pjson.parse([frame])
            figma2pjson.flush()

            const scene = config.scenes[0]

            fixBlocks(scene)

            // here the logic to
            // replace only blocks that were changed
            // add the new one
            // remove the missing
            // upload images for IMG blocks that are new or changed

            this.$emit('updated', scene.blocks)

        }
    }
}
</script>



<style scoped>
.wrapperInner{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
</style>