<template>
    <ModalContainer @close="$emit('close')">
        <div class="wrap">

            <h3>CSV importer</h3>
            <p>You can convert a <b>CSV</b> file into a <b>PRESENTA</b> document using this tool.</p>

            <BarToggler  @changed="onToggle" :list="sections" />

            <div class="field" v-if="section === 'file'">
                <p>Pick a CSV file:</p>
                <input type="file" ref="upload" accept="text/csv" @change="requestFile" />
            </div>

            <div class="field" v-if="section === 'url'">
                <p>Put a CSV URL:</p>

                <input placeholder="https://..." type="text" />
                <button class="btn small">Load</button>
                
            </div>

            <div class="field" v-if="section === 'raw'">
                <p>Paste raw CSV:</p>
                <textarea v-model="raw" placeholder="title,section,text"></textarea>
                <button class="btn small" @click="parseRaw">Parse</button>
            </div>

            <div v-if="parsed">
                <p>Detected: {{parsed.length}} frames.</p>

                <div class="field">
                    <p>Select the Project Template</p>
                    <select v-model="purl">
                        <option v-for="project in apiProjects" :key="project.url" :value="project.url">{{project.name}}</option>
                    </select>
                    <p>Missing a project? Maybe it needs to be exported as Project API.</p>
                </div>
            
                <div class="field">
                    <button :disabled="!hasData" @click="createProject">Import CSV</button>
                </div>
            </div>

            
            
        </div>
    </ModalContainer>
</template>



<script>
import Papa from 'papaparse'
import { interpolate } from '@presenta/config-interpolator'
import { mapActions, mapGetters } from 'vuex'
import defaults, {fixScene} from '@/libs/defaults'
import {clone} from '@/libs/stringify'
import queryString from 'query-string'
import ModalContainer from '@/components/ModalContainer'
import BarToggler from '@/components/BarToggler'

export default {
    data(){
        return{
            frames:null,
            purl:'',
            raw: '',
            parsed: null,
            name:'Untitled',
            sections:[
                {label:'Upload CSV File', key:'file', selected:true},
                //{label:'Set Remote Url', key:'url', selected:false},
                {label:'Paste Raw', key:'raw', selected:false}
            ],
            section:'file'
        }
    },
    computed:{
        ...mapGetters(['companyId', 'company']),
        hasData(){
            return this.raw && this.purl
        },
        projects(){
            return this.company.projects
        },
        apiProjects(){
            return this.projects.filter(p => p.isProjectAPI)
        }
    },
    components:{
        ModalContainer, BarToggler
    },
    methods:{
        ...mapActions(['getTemplate']),
        requestFile(){
            var e = this.$refs.upload
            const file = e.files[0]

            this.name = file.name || 'Untitled'

            let fr = new FileReader()
            fr.readAsText(file)
            fr.onload = () => {
                this.raw = fr.result
                this.parseRaw()
            }; 
            fr.onerror = () => {
                console.log(fr.error)
            }; 
        },

        parseRaw(){
            const csv = Papa.parse(this.raw, {
                header: true,
                skipEmptyLines: 'greedy'
            })
            this.parsed = csv.data
        },
        
        onToggle(v){
            this.section = v
        },

        parseCSV(){
            this.frames = []

            this.parsed.forEach(r => {
                const qrystr = Object.keys(r).map(key => `${key}=${r[key]}`).join('&')
                const qry = queryString.parse('?' + qrystr)
                this.frames.push(qry)
            })

            console.log(this.frames)
        },
        
        async createProject(){
            this.parseCSV()

            const res = await this.getTemplate({purl: this.purl, curl: this.companyId })
            const doc = res.data
            console.log(doc)
            
            const config = await interpolate({frames: this.frames}, doc)
            
            let p = clone(defaults.defaultProject)
            p.curl = this.companyId
            p.name = this.name
            p.document = config

            p.document.pProjectAPIChanged = null
            p.document.pProjectID = null

            p.document.scenes.forEach(s => {
                s.pFrameID = null
                s.pTemplateID = null
                fixScene(s)
            })

            console.log(p)
            
            this.$emit('done', p)
        }
    }
}
</script>


<style scoped>
.wrap{
    overflow: hidden;
    overflow-y: auto;
    padding-right: 1rem;
}

textarea, input{
    width: 100%;
}

.row{
    display: flex;
}
</style>