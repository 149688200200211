<template>
    <div class="uploader" v-if="hasLoading">
        <div class="main" @click="showDetails = !showDetails">
            <UploaderItem :item="mainItem" />
        </div>
        <div class="details" v-if="showDetails">
            <UploaderItem v-for="item in uploadingList" :key="item.url"
                :item="item" @remove="onAbortUpload" />
        </div>
    </div>
</template>



<script>
import { mapGetters, mapMutations } from 'vuex'
import UploaderItem from './UploaderItem'

export default {
    components:{
        UploaderItem
    },
    data(){
        return{
            showDetails:false
        }
    },
    computed:{
        ...mapGetters(['uploadingList']),
        mainItem(){
            return {name: 'Uploading '+this.uploadingList.length+' items ...', loaded:this.loaded, size:this.size, unstop:true}
        },
        loaded(){
            let v = 0
            this.uploadingList.forEach(d => v += d.loaded)
            return v
        },
        size(){
            let v = 0
            this.uploadingList.forEach(d => v += d.size)
            return v
        },
        hasLoading(){
            return this.uploadingList.length > 0
        }
    },
    methods:{
        ...mapMutations(['abortUpload']),
        onAbortUpload(item){
            if(item.onError) item.onError('aborted')
            this.abortUpload(item)
        }
    }
}
</script>



<style scoped>
.uploader{
    background-color: #24a1f9;
    
    width: 100%;
    font-size:.75rem;
    z-index: 999;
}

/* @media screen and (min-width: 720px){
    .uploader{
        width: calc(100% - var(--sideEditWidth));
    }
} */
</style>