<template>
    <div style="width:100%; margin-top:1rem;">
        <p class="desc">
            <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
            <!-- <span class="label" @dblclick="custom=!custom">{{ctrl.label || ctrl.key}}</span></p> -->

        <div v-show="custom">
            <textarea class="rawarea" v-model="ctrl.value" @input="updateVisual"></textarea>
        </div>

        <div v-show="!custom">
            <EditorMenuBar :editor="editor" v-slot="{ commands, isActive }">
                <div>
                <div class="texttoolbar" v-if="multiblocks">
                    <v-popover trigger="manual" :open="blocksVisible" offset="5">
                        <button class="first menu small withicon" 
                            @click="blocksVisible=!blocksVisible">
                            Format as:
                            <img src="@/assets/icons/downdrop.svg" />
                        </button>
                        
                        <template slot="popover">
                            <ul class="listmenu">
                                <li><button @click="commands.heading({ level: 1 })">Title</button></li>
                                <li><button @click="commands.heading({ level: 2 })">Subtitle</button></li>
                                <li><button @click="commands.paragraph">Paragraph</button></li>
                                <li><button @click="commands.blockquote">Quote</button></li>
                                <li class="separator"></li>
                                <li><button @click="commands.horizontal_rule">Horizontal</button></li>
                                <!-- <li><button @click="commands.btn">Button Like</button></li> -->
                                <li><button @click="commands.bullet_list">Unordered</button></li>
                                <li><button @click="dump(commands)">Ordered</button></li> 
                            </ul>
                        </template>
                    </v-popover>
                </div>

                <div class="texttoolbar">

                    

                    <button @click="commands.bold" v-tooltip="'Bold'">B</button>
                    <button @click="commands.italic" v-tooltip="'Italic'">I</button>
                    <button @click="commands.underline" v-tooltip="'Underline'">U</button>
                    <!-- <button @click="commands.mark" v-tooltip="'Mark'">M</button> -->
                    <button @click="commands.strike" v-tooltip="'Strikethrough'">S</button>
                    <!-- <button @click="commands.high" v-tooltip="'Highlight'">H</button> -->
                    
                    
                    

                    <!-- <v-popover trigger="manual" :open="linkVisible" offset="5">
                        <button class="menu small withicon" 
                            @click="linkVisible=!linkVisible">
                            Link <img src="@/assets/icons/downdrop.svg" />
                        </button>
                        
                        <template slot="popover">
                            <ul class="listmenu" @keydown.stop="" @keyup.stop="">
                                <li>
                                    <span><input class="tfield" type="text" v-model="link" placeholder="http://..."
                                    @keydown.esc="linkVisible=false" @keydown.prevent.stop.enter="setLinkUrl(commands.link, link)" /></span>
                                </li>
                            </ul>
                        </template>
                    </v-popover> -->


                    <!-- <v-popover trigger="manual" :open="classVisible" offset="5">
                        <button class="menu small withicon" 
                            @click="classVisible=!classVisible">
                            Class <img src="@/assets/icons/downdrop.svg" />
                        </button>
                        
                        <template slot="popover">
                            <ul class="listmenu" @keydown.stop="" @keyup.stop="">
                                <li>
                                    <span><input class="tfield" type="text" v-model="className" placeholder="class1"
                                    @keydown.esc="classVisible=false" @keydown.prevent.stop.enter="setClassName(commands.customClass, className)" /></span>
                                </li>
                            </ul>
                        </template>
                    </v-popover> -->
                
                </div>
                </div>
            </EditorMenuBar>
            
            <EditorContent 
                class="texteditregion"
                :editor="editor" />
        </div>
    </div>
</template>

<script>
import extract from '@/libs/extract.js'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {TMark, THigh, TBord, TSmall, TBtn, TCustomClass} from '@/libs/TipTapExt'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  HorizontalRule,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  Placeholder,
  History,
} from 'tiptap-extensions'

export default {
    props:{
        ctrl:Object
    },
    components: {
        EditorContent,EditorMenuBar
    },
    data(){
        return{
            editor: null,
            blocksVisible:false,
            linkVisible:false,
            classVisible:false,
            link:null,
            className:null,
            custom: false,
            multiblocks:false
        }
    },
    mounted() {
        let src = this.ctrl.value ? extract.prepareHTMLForEdit(this.ctrl.value) : '<p></p>'
        this.editor = new Editor({
            content: src,
            extensions: [
                new Blockquote(),
                new CodeBlock(),
                new HardBreak(),
                new Heading({ levels: [1, 2, 3] }),
                new BulletList(),
                new OrderedList(),
                new ListItem(),
                new HorizontalRule(),
                new Bold(),
                new Code(),
                new Italic(),
                new Link(),
                new Strike(),
                new Underline(),
                new History(),
                new Placeholder({
                    emptyEditorClass: 'is-editor-empty',
                    emptyNodeClass: 'is-empty',
                    showOnlyWhenEditable: true
                }),
                new TMark(),
                new THigh(),
                new TBord(),
                new TSmall(),
                new TBtn(),
                new TCustomClass()
            ],
            // onFocus: () => this.$emit('focus'),
            // onBlur: () => this.$emit('blur'),
            onUpdate: ({ getJSON, getHTML }) => {
                let src = extract.prepareHTMLForSave(getHTML())
                this.ctrl.value = src
                this.onChange()
            }
        })


    },
    beforeDestroy() {
        this.editor.destroy()
    },
    methods:{
        onChange(){
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.$emit('change', this.ctrl)
            }, 200)
        },
        setLinkUrl(command, url) {
            command({ href: url })
            this.linkVisible = false
        },
        setClassName(command, name){
            console.log(command, name)
            command({ class: name })
            this.classVisible = false
        },
        updateVisual(){
            let src = this.ctrl.value ? extract.prepareHTMLForEdit(this.ctrl.value) : '<p></p>'
            this.editor.setContent(src)
            this.onChange()
        },

        dump(c){
            console.log(c)
        }
    }
}
</script>


<style scoped src="./edev.css"></style>


<style scoped>
.texteditregion{
  -webkit-user-select: text;
  user-select: text;
  border:1px solid #ddd;
  padding: 0.5rem;
  min-height: 80px;
}
.texteditregion >>> [contenteditable] *:first-child{
    margin-top: 0;
}
.texteditregion >>> [contenteditable] *:last-child{
    margin-bottom: 0;
}

.texteditregion >>> *.is-editor-empty{
    /* outline: 2px solid #000; */
}



.texttoolbar{
    pointer-events: all;
    display: flex;
    align-items: center;
    overflow: hidden;
    /* overflow-x: auto; */
    /* height: 35px; */
    /* position: absolute; */
    /* top:-35px; */
    width: 100%;
    height: 100%;
    border-bottom: none;


    /* box-shadow: 0 0 10px #ccc;

    border-top:1px solid #ddd;
    border-bottom:1px solid #ddd; */

    margin-bottom:5px;
}
.texttoolbar button{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0;
    min-width:25px;
    height: 25px;
    font-size: .7rem;
    box-shadow: none;
    color:#333;
    padding: 0 5px;
    border: 1px solid #eee;
    border-left: none;
}
.texttoolbar button:first-child{
    border-left: 1px solid #eee;
}
.texttoolbar button:hover{
    background-color: #fff;
}

.rawarea{
    height:200px;
}
</style>