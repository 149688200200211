<template>
    <ModalContainer @close="$emit('close')">
        <div class="templates">

            <div class="head">
                <h3>Start from a Template:</h3>
            </div>
            

            <ul class="grid">
                <li @click="selected(template)"
                 v-for="template in templates" :key="template.id">
                    <div class="img">
                        <img :src="'https://cloud.presenta.cc/v1/url/' + template.id" />
                    </div>
                </li>
            </ul>

            <div v-if="loading" class="loading">Working... please wait...</div>
        </div>
    </ModalContainer>
</template>



<script>
import {clone} from '@/libs/stringify'
import defaults, {fixScene} from '@/libs/defaults'
import { mapGetters } from 'vuex'
import ModalContainer from '@/components/ModalContainer'


/*

TO TEST LOCALLY YOU NEED TO ADD 
in staging REDIS a publishedUrl key with a production template url


*/

export default {
    data(){
        return{
            loading:false,
            templates:[
                {id: "zGywhb2oJn:mzT3zNoLn"},
                {id: "zGywhb2oJn:BEaGHrXOs"},
                {id: "zGywhb2oJn:0vx3VZVjP"},
                {id: "zGywhb2oJn:MiKViTKFM"},
                {id: "zGywhb2oJn:Gx5I8aeto"},
                {id: "zGywhb2oJn:9CF5pEILq"},
                {id: "zGywhb2oJn:6JaJpv7Qo"},
                {id: "zGywhb2oJn:JCNwsKA6w"}
            ]
        }
    },
    components:{
        ModalContainer
    },
    computed:{
        ...mapGetters(['companyId'])
    },
    methods:{
        selected(template){
            this.loading = true
            this.$emit('selected', template)
        }
    }
}
</script>




<style scoped>

.templates{
  width: 100%;
  padding: 1rem 0;
  overflow: hidden;
    overflow-y: auto;
}

.head{
    padding: 0 1rem;
}

.loading{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid{
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding:0;
  list-style-type: none;
  width: 100%;
}


.grid li{
  display: flex;
  flex-direction: column;
  padding:1rem;
  width: 100%;
  cursor: pointer;

  align-items: center;
  justify-content: center;
  transition: transform .25s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.grid li:hover{
    transform: scale(1.1);
}

.grid li > a{
  border:1px solid #eee;
  width: 100%;
  padding:1rem;
  display: flex;
  flex-direction: column;
  color: black;
}

.grid li:hover > a{
  background-color:var(--accentcolor);
}
.grid li p{
  text-align: center;
  margin: 0;
}

.grid .img{
  border:1px solid #eee;  
}

.grid .img img{
  width: 100%;
  display: flex;
}
@media screen and (min-width: 400px){
  .grid li{
      width:50%;
  }
}
@media screen and (min-width: 770px){
  
}
@media screen and (min-width: 1000px){
  .grid:not(.pair) li{
    width:33.333333333333%;
  }
}
@media screen and (min-width: 1400px){
  .grid li{
    width:25%;
  }
}

</style>