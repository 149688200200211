<template>
    <div ref="cont" class="regionEditable">
        <canvas id="fabricCanvas"></canvas>
        <FabricGuides v-if="canvas" 
            :canvas="canvas"
            :fz="fz" />
    </div>
</template>




<script>

import {fabric} from 'fabric'
import FabricGuides from './FabricGuides'

import { mapGetters, mapMutations } from 'vuex'


const strokeColorSelectIntent = '#24a1f9'
const fillColorSelectIntent = 'rgba(255,255,255,.15)'
const strokeColorSelected = '#24a1f9'
const fillColorSelected = 'rgba(255,255,255,.25)'

fabric.Object.prototype.set({
    transparentCorners: false,
    cornerColor: 'white',
    cornerStrokeColor: strokeColorSelected,
    cornerSize: 8,
    borderColor: strokeColorSelected,
    strokeUniform: true
}) 

export default {
    props:{
        pscene:Object,
        index:Number
    },
    components:{
        FabricGuides
    },
    data(){
        return{
            lastSelecting:null,
            lastSelected:null,
            canvas:null,
            isShiftPressed:false,
            prevPointer:null,
            fz:0
        }
    },
    mounted(){
        const scene = this.pscene.config.scenes[this.index]
        //scene._router.dispatch('live:block:begin')

        const bb = this.$refs.cont.getBoundingClientRect()

        var canvas = new fabric.Canvas('fabricCanvas')
        canvas.setDimensions({width:bb.width, height:bb.height})
        canvas.uniformScaling = false

        canvas.on('mouse:over',  (e) => {
            if(e.target && !e.target.selected) {
                setTimeout(() => {
                    this.setSelectingBlockIndex( e.target.index )
                })
            }
        })
        canvas.on('mouse:out',  (e) => {
            if(e.target && !e.target.selected) {
                this.setSelectingBlockIndex( -1 )
            }
        })


        
        const makeLocalSelected = (o) => {
            o.selected = true
            o.set('stroke', null)
            o.set('fill', fillColorSelected)
            canvas.renderAll()
        }
        const makeLocalUnselected = (o) => {
            o.selected = false
            o.set('fill', null)
            o.set('stroke', null)
            canvas.renderAll()
        }

        canvas.on('selection:created',  (e) => {
            e.selected.forEach(o => {
                makeLocalSelected(o)
            })
            if(e.selected.length === 1) {
                this.setSelectedBlockIndexFromFabric(e.selected[0].index)
            }
        })

        canvas.on('before:selection:cleared',  (e) => {
            if(e.target) {
                const usrObjects = e.target._objects
                if(usrObjects){
                    usrObjects.forEach(o => {
                        makeLocalUnselected(o)
                    })
                }else{
                    makeLocalUnselected(e.target)
                }
                this.setSelectedBlockIndexFromSidebar(-1)
                this.setSelectedBlockIndexFromFabric(-1)
            }
        })
        canvas.on('selection:updated',  (e) => {
            e.deselected.forEach(o => {
                makeLocalUnselected(o)
            })
            e.selected.forEach(o => {
                makeLocalSelected(o)
            })

            const selected = canvas.getActiveObjects()
            if(selected.length === 1) {
                this.setSelectedBlockIndexFromFabric(selected[0].index)
            }else{
                this.setSelectedBlockIndexFromFabric(-1)
            }
        })
        // canvas.on('mouse:up',  (e) => {
        //     const selected = canvas.getActiveObjects()
        //     if(selected.length === 1){
        //         if(selected[0] === e.target){
        //             console.log('me', e.target.selected)
        //             // canvas.discardActiveObject()
        //             // canvas.renderAll()
        //         }
        //     }
        // })


        this.fz = +window.getComputedStyle(scene._el).getPropertyValue('--presenta-fz')

        scene.blocks.forEach((b,i) => {
            const {coords, units} = this.beginEdit(b)

            coords.fill = null
            coords.index = i
            coords.units = units
            coords.snapAngle = 5

            canvas.add(new fabric.Rect(coords))
        })

        // postpone the init of these listeners
        // because FabricGuides mush be init before
        // need to find a more elegant solution here
        setTimeout(() => {
            canvas.on('object:moving', e => this.send(e))
            canvas.on('object:scaling', e => this.send(e))
            canvas.on('object:rotating', e => this.send(e))

            canvas.on('object:moved', e => this.$emit('consolidate'))
            canvas.on('object:scaled', e => this.$emit('consolidate'))
            canvas.on('object:rotated', e => this.$emit('consolidate'))
        })

        canvas.on('object:moving', e => this.checkLockAxix(e))

        this.canvas = canvas

        // regeneration need to remember prev selected status
        this.handleSidebarSelectedBlock(this.selectedBlockIndex)

        document.addEventListener('keydown', this.keyDown)
        document.addEventListener('keyup', this.keyUp)
    },
    beforeDestroy(){
        const scene = this.pscene.config.scenes[this.index]
        scene._router.dispatch('live:block:end')
        this.canvas.dispose()
        document.removeEventListener('keydown', this.keyDown)
        document.removeEventListener('keyup', this.keyUp)
    },
    computed:{
        ...mapGetters(['selectingBlockIndex', 'selectedBlockIndex', 'selectedBlockIndexFromSidebar'])
    },
    watch:{
        selectingBlockIndex(v){
            const f = v >= 0 ? this.makeSelecting : this.makeUnselecting
            const idx = v >= 0 ? v : this.lastSelecting
            const o = this.canvas.item(idx)
            if(o) f(o)
        },
        selectedBlockIndexFromSidebar(v){
            this.handleSidebarSelectedBlock(v)
        }
    },
    methods:{
        ...mapMutations(['setSelectingBlockIndex', 'setSelectedBlockIndexFromFabric', 'setSelectedBlockIndexFromSidebar']),
        send(e) {
            if(e.target) {
                // group handling
                if(e.target._objects){

                    const gMatrix = e.target.calcTransformMatrix()

                    e.target._objects.forEach((o,i) => {
                        // fabric.js doesn't update width/height at runtime
                        // thus, we calc it using scale factor
                        o.iwidth  = o.width  * e.target.scaleX * o.scaleX
                        o.iheight = o.height * e.target.scaleY * o.scaleY
                        //

                        // get the coords from a group selection requires something more.
                        // here the help I got: https://github.com/fabricjs/fabric.js/issues/7282
                        const pt = fabric.util.transformPoint({x:o.left, y:o.top}, gMatrix)
                        o.ileft = pt.x
                        o.itop = pt.y
                        
                        // special case in group selection, we add the angle of the group
                        o.iangle = e.target.angle

                        this.endEdit(o)
                    })
                }else{
                    const o = e.target

                    // fabric.js doesn't update width/height at runtime
                    o.iwidth  = o.getScaledWidth()
                    o.iheight = o.getScaledHeight()
                    //
                    
                    o.ileft = o.left
                    o.itop = o.top
                    o.iangle = 0

                    this.endEdit(o)
                }
            }
        },
        beginEdit(config){
            const scene = this.pscene.config.scenes[this.index]
            
            const bb = scene._el.getBoundingClientRect()
            const sw = bb.width
            const sh = bb.height
            
            const coords = { top: 0, left: 0, width: 100, height: 100, angle: 0 }
            const units = { top: '%', left: '%', width: '%', height: '%', angle: 'deg' }

            const cc = config.modules.coords || { top: 0, left: 0, width: 100, height: 100, angle: 0 }
            for (const k in coords) {
                if (cc[k]) {
                    const vv = typeof cc[k] === 'number' ? cc[k] + '%' : cc[k]
                    units[k] = vv.indexOf('px') > 0 ? 'px' : units[k]
                    units[k] = vv.indexOf('deg') > 0 ? 'deg' : units[k]

                    if (units[k] === '%') {
                        const dim = (k === 'left' || k === 'width') ? sw : sh
                        coords[k] = vv ? (dim * +vv.replace('%', '') / 100) : coords[k]
                    }

                    if (units[k] === 'px') {
                        coords[k] = +vv.replace('px', '') / this.fz
                    }

                    if (units[k] === 'deg') {
                        coords[k] = +vv.replace('deg', '')
                    }
                } else {
                    const dim = (k === 'left' || k === 'width') ? sw : sh
                    coords[k] = dim * +coords[k] / 100
                }

            }

            return {coords, units}
        },
        endEdit(o){
            const scene = this.pscene.config.scenes[this.index]

            const bb = scene._el.getBoundingClientRect()
            const sw = bb.width
            const sh = bb.height

            const units = o.units
            
            const res = { top: o.itop, left: o.ileft, width: o.iwidth, height: o.iheight }
            for (const k in res) {
                if (units[k] === '%') {
                    const dim = (k === 'left' || k === 'width') ? sw : sh
                    res[k] = res[k] * 100 / dim
                }
                if (units[k] === 'px') {
                    const dim = (k === 'left' || k === 'width') ? sw : sh
                    res[k] = res[k] * this.fz
                }
                res[k] += units[k]
            }

            res.angle = (o.iangle + o.angle) + 'deg'

            scene._router.dispatch('live:block:update', {index: o.index, coords: res})
        },

        makeSelecting(o){
            o.set('stroke', strokeColorSelectIntent)
            o.set('fill', fillColorSelectIntent)
            this.lastSelecting = o.index
            this.canvas.renderAll()
        },
        makeUnselecting(o){
            o.set('stroke', null)
            o.set('fill', null)
            this.canvas.renderAll()
        },


        makeSelected(o) {
            o.selected = true
            o.set('stroke', null)
            o.set('fill', fillColorSelected)

            this.canvas.setActiveObject(o)
            
            this.lastSelected = o.index
            this.canvas.renderAll()
        },
        makeUnselected(o) {
            o.selected = false
            o.set('fill', null)
            o.set('stroke', null)
            
            this.canvas.discardActiveObject(o)

            this.canvas.renderAll()
        },

        moveSelectedObject(x, y){
            const selected = this.canvas.getActiveObjects()
            let ob = selected[0]
            if(selected.length > 1) ob = ob.group

            ob.set('left', ob.get('left') + x)
            ob.set('top', ob.get('top') + y)

            this.send({target: ob})
            this.canvas.renderAll()
        },

        keyUp(e){
            const k = e.key
            const m = e.shiftKey ? 10 : 1

            if(k === 'ArrowRight') this.moveSelectedObject(m, 0)
            if(k === 'ArrowLeft')  this.moveSelectedObject(m*-1, 0)
            if(k === 'ArrowUp') this.moveSelectedObject(0, m*-1)
            if(k === 'ArrowDown')  this.moveSelectedObject(0, m)

            this.isShiftPressed = false
        },


        checkLockAxix(e){
            if(this.isShiftPressed){
                if(this.prevPointer
                    && !e.target.lockMovementY 
                    && !e.target.lockMovementX){
                    const vx = Math.abs(this.prevPointer.x - e.pointer.x)
                    const vy = Math.abs(this.prevPointer.y - e.pointer.y)
                    if(vx > vy){
                        e.target.lockMovementY = true
                    }else{
                        e.target.lockMovementX = true
                    }
                }else{
                    this.prevPointer = {...e.pointer}
                }
            }else{
                e.target.lockMovementX = false
                e.target.lockMovementY = false
                this.prevPointer = null
            }
        },

        handleSidebarSelectedBlock(v){
            const f = v >= 0 ? this.makeSelected : this.makeUnselected
            const idx = v >= 0 ? v : this.lastSelected
            const o = this.canvas.item(idx)
            console.log(idx, o)
            if(o) f(o)
        },

        keyDown(e){
            this.isShiftPressed = e.key === 'Shift'
        }
        

    }
}
</script>



<style scoped>
.regionEditable{
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    position: absolute;
}
canvas{
    width: 100%;
    height: 100%;
}
</style>