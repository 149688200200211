<template>
    <!-- <v-popover offset="2"> -->

        <div class="bot">
            <a target="blank" href="https://github.com/presenta-software/presenta-lib/issues">help</a> / <a target="blank" href="https://github.com/presenta-software/presenta-lib/discussions">suggest</a>
        </div>
        
        <!-- <template slot="popover">
            <div class="feedbackform" @keydown.stop="">
                <div class="send" v-if="!sent">
                    <p>Send us your feedback:</p>
                    <div>
                        <textarea placeholder="Be kind :)" v-model="message"></textarea>
                    </div>
                    <button :disabled="isEmpty" class="small" @click="send">Send</button>
                </div>
                <div class="sent" v-if="sent">
                    <p>Thank you!</p>
                    <p>We'll let you know shortly about your feedback.</p>
                    <button class="small" v-close-popover>Close</button>
                </div>
            </div>
        </template>
    </v-popover> -->

</template>



<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return{
            label:'Send feedback',
            message:'',
            sent:false
        }
    },
    computed:{
        ...mapGetters(['project']),
        isEmpty(){
            return !this.message || this.message === ''
        }
    },
    methods:{
        ...mapActions(['addFeedback']),
        async send(){
            let res = await this.addFeedback({comment:this.message, url:this.project.url})
            if(res.data && res.data.status === 'ok'){
                this.sent=true
                this.label = 'Feedback sent!'
            }
        }
    }
}
</script>



<style scoped>

.bot{
    font-size: .7rem;
    padding: 0 .5rem;
    color: white;
}
.bot a{
    text-decoration: underline;
}
p{
    margin-top:0;
}
.feedbackform{
    min-width: 250px;
    padding: 0 0.5rem;
}
.feedbackform textarea{
    width: 100%;
    height: 100px;
    resize: none;
    padding: .5rem;
}

a{
    color: white;
    text-decoration: none;
}
</style>