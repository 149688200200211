<template>
    <div class="container" @click="deselectElements">
        <div class="focusSlide">
            <SlideWrapper
                :style="getZoomFactor"
                :key="currentSlide.$url"
                :slide="currentSlide"
                :index="currentIndex"
                :islast="true" />
        </div>
    </div>
</template>


<script>
import {mapGetters, mapMutations} from 'vuex'

import SlideWrapper from './slide/SlideWrapper'

export default {
    components:{
        SlideWrapper
    },
    data(){
        return{
            perc: 1
        }
    },
    mounted(){
        this.resize()

        this.addToSelection(this.currentSlide)

        window.onresize = this.resize
    },
    computed:{
        ...mapGetters(['document', 'selection', 'slides']),

        currentSlide(){
            return this.slides[0]
        },
        currentIndex(){
            return this.slides.indexOf(this.currentSlide)
        },
        getZoomFactor(){
            const v = 100 * this.perc
            return {width: v + '%'}
        }
    },
    methods:{
        ...mapMutations(['setZoom', 'addToSelection',
            'notifyProjectUpdate',
            'setSelectedBlockIndexFromFabric']),
        deselectElements(){
            this.setSelectedBlockIndexFromFabric(-1)
        },
        resize(){
            const bbox = this.$el.getBoundingClientRect()

            const aspect = this.document.aspect 
                ? +this.document.aspect
                : 1.6

            const hNeeds = bbox.width / aspect
            this.perc = hNeeds > bbox.height ? bbox.height/hNeeds : 1
        }

    }
}
</script>



<style scoped>
.container{
    width: 100%;
    height: 100%;
    position: relative;

    flex:1;
    overflow: hidden;
}
.focusSlide{
    width: 100%;
    height: 100%;
    padding: 1rem;
    padding-bottom: 0rem;
    padding-top: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
