<template>
    <div class="noProjects">
        <div class="inner">
            <p>{{msg}}</p>
            <!-- <NewProjectBtn v-if="showBtn" class="noFullWidth"
                @select="$emit('newProject', $event)" /> -->
        </div>
    </div>
</template>



<script>
import NewProjectBtn from './NewProjectBtn.vue'

export default {
    props:{
        msg:String,
        showBtn:Boolean
    },
    components:{
        NewProjectBtn
    }
}
</script>



<style scoped>
.noProjects{
}
.inner{
    border: 1px dashed #ccc;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.noFullWidth{
    width: initial;
}
</style>