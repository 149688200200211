<template>
    <div class="block" :class="{selecting:selecting}">

        <div class="head">
            
            <!-- <img class="handle draggable" src="@/assets/icons/drag.svg" /> -->
            <img :src="require(`@/assets/icons/${item.key}.svg`)" />

            <div class="toggler" @mouseenter="setSelectingBlockIndex(index)" @mouseleave="setSelectingBlockIndex(-1)" @click="onSelect">
                <!-- <EditName 
                    :source="item.ukey"
                    @update="consolidateName" /> -->
                {{item.ukey}}
            </div>

            <!-- <img class="edit" @click="onDelete" src="@/assets/icons/delete.svg" />
            <img class="edit" @click="onDuplicate" src="@/assets/icons/duplicate.svg" /> -->
            
            <!-- <img v-if="!item.locked" class="edit" @click="onLockToggle" src="@/assets/icons/unlock.svg" />
            <img v-if="item.locked" class="edit" @click="onLockToggle" src="@/assets/icons/lock.svg" /> -->
        
        </div>

    </div>
</template>




<script>
import { mapGetters, mapMutations } from 'vuex'
import EditName from './ctrls/EditableName.vue'

export default {
    props:{
        item: Object,
        index: Number,
    },
    components:{
        EditName
    },
    computed:{
        ...mapGetters(['selectingBlockIndex', 'selectedBlockIndexFromFabric']),
        selecting(){
            return this.selectingBlockIndex === this.index
        }
    },
    methods:{
        ...mapMutations(['setSelectingBlockIndex', 'setSelectedBlockIndexFromSidebar']),
        
        onSelect(){
            this.$emit('select', this.index)
        },
        onDelete(){
            this.$emit('delete', this.index)
        },
        onDuplicate(){
            this.$emit('duplicate', this.index)
        },

        onLockToggle(){
            this.item.locked = !this.item.locked
            this.$emit('lock', {index:this.index, lock:this.item.locked})
        },


        consolidateName(src) {
            this.item.ukey = src
            this.$emit('ukey', {index:this.index, ukey:src})
        }
    }
}
</script>



<style scoped>
.block{
    width: 100%;
}
.block:hover{
    background-color: #eee;
}
.block.selecting{
    outline:1px solid #24a1f9;
}



.head{
    display: flex;
    align-items: center;
    background-color: none;
    font-size: .8rem;
}
.head img{
    width: 20px;
    height: 20px;
}
.head .toggler{
    padding: 0;
    margin: 0;
    flex: 1;
    padding: .5rem 0rem;
    padding-left: .25rem;
    user-select: none;
}
.draggable{
    cursor: move;
}
.edit{
    cursor: pointer;
}
.edit:hover{
    background: white;
}


</style>
