<template>
    <div></div>
</template>


<script>
export default {
    props:{
        ctrl:Object
    }
}
</script>