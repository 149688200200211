<template>
    <div class="pWrapper">
        <div ref="preview"></div>
    </div>
</template>



<script>
import * as Presenta from '@presenta/lib'

export default {
    props:{
        document:Object
    },
    mounted(){
        const proj = this.document
        new Presenta(this.$refs.preview, proj)
            .then(data => {
                console.log(data)
            })
    }
}
</script>



<style scoped>
.pWrapper{
    width: 250px;
    /* height: 150px; */
    overflow:hidden;
}
</style>