<template>
    <div class="header">
        <div v-for="item in list" :key="item.key" :title="item.label"
             :class="{active: item.selected, disabled: item.disabled, invisible: item.invisible}" @click="onClick(item)">
             <span class="label">{{item.label}}</span>
             <span class="close" v-if="item.closable" @click="$emit('close', item.key)">X</span>
        </div>
    </div>
</template>



<script>
export default {
    props:{
        list:Array
    },

    methods:{
        onClick(v){
            if(v.disabled) return
            this.list.forEach(d => {
                d.selected = false
            })
            v.selected = true
            this.$emit('changed', v.key)
        }
    }
}

</script>



<style scoped>
.header{
    display: flex;
}
.header > div{
    flex:1;
    text-align: center;
    font-size: .65rem;
    padding: .5rem .5rem;
    background-color: #ddd;
    color:#555;
    cursor: pointer;
    border-right: 1px solid #ccc;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

}
.header > div:last-child{
    border-right: none;
}
.header > div:hover{
    color:black;
}
.header > div.active{
    font-weight: bold;
    background-color: unset;
    color:black;
}
.header > div.disabled{
    cursor: auto;
}
.header > div.invisible{
    display: none;
}


.label{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
}
.close{
    border-radius: 50%;
    min-width: 15px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: .25rem;
    background: #ddd;
    color: #000;
    font-size: .5rem;
}
.close:hover{
    background: #fff;
}
</style>