<template>
    <div class="row">
        <div class="colleft">
            <p class="desc">
                <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
                <span class="label">{{ctrl.label || ctrl.key}}</span>
            </p>
        </div>
        <div class="colright">
            <input v-model="v" @input="onChange" :placeholder="ctrl.placeholder" />
        </div>
    </div>
</template>


<script>
export default {
    props:{
        ctrl:Object
    },
    data(){
        return{
            v: 0
        }
    },
    mounted(){
        this.v = this.ctrl.eval && this.ctrl.value ? JSON.stringify(this.ctrl.value) : this.ctrl.value
    },
    computed:{
        value(){
            return this.ctrl.value
        }
    },
    watch:{
        value(){
            this.v = this.ctrl.eval && this.ctrl.value ? JSON.stringify(this.ctrl.value) : this.ctrl.value
        }
    },
    methods:{
        onChange(){
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                try{
                    this.ctrl.value = this.ctrl.eval ? JSON.parse(this.v) : this.v
                }catch(e){
                    console.log(e)
                }
                this.$emit('change', this.ctrl)
            }, 200)
        }
    }
}
</script>


<style scoped src="./edev.css"></style>