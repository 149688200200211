<template>
  <div class="viewScroll">
      <div class="page">
        <div class="prewrapper">
            <div class="wrapper">
                
                <div class="main">
                    <div class="img">
                        <img :src="gravatar" />
                    </div>
                    <div class="info">
                        <h1>{{user.name}}</h1>
                        <p><a href="" @click.prevent="doLogout">Logout</a></p>
                        <p>Email: {{user.username}} <span class="badge" v-if="!notConfirmed">verified!</span></p>
                        <!-- <p>Enable developer mode: <input v-model="prefs.devmode" type="check" /></p> -->
                        <p class="notconfirmed" v-if="notConfirmed">
                            <span class="red">Not confirmed:</span><br />
                            <a v-if="!sent" href="" @click.prevent="doResend">Resend email! </a> if you're not received it.
                            <span v-if="sent"><b>Sent! Check your email.</b></span>
                        </p>
                    </div>
                </div>
                <hr />

                <div class="col" v-if="notConfirmed">
                  <div class="alertBanner"><span class="red">Email confirmation is required to continue using PRESENTA Platform.</span><br />
                  Check your inbox email and click the link to confirm it.</div>
                </div>

                <div class="col" v-if="!notConfirmed">
                    <div class="workspace" v-for="company in user.companies" :key="company.id">
                      <div class="row">
                        <h3><span style="cursor:pointer;" v-if="user.companies.length > 1" @click="company.showDetails=!company.showDetails">{{ company.showDetails ? '–' : '+' }}</span>Workspace: <router-link :to="'/projects/' + company.url">{{getLabel(company)}}</router-link> 
                          <span class="badge pro" v-if="isPro(company)">PRO!</span>
                          <span class="badge free" v-if="!isPro(company)">FREE!</span>
                        </h3>

                        <div class="col3" v-if="company.showDetails">
                          
                          <div class="notice" v-if="!isPro(company)">
                            <p style="padding:0; text-align:center;">Turn this Workspace <span class="badge pro">PRO!</span></p>
                            <div class="btnContainer"><button :disabled="reqCard" @click="addBillingCard(company)" class="btn small attract">Add Credit Card</button></div>
                            <p>Adding a Credit Card does not entail any charge.</p>
                            <p>To learn the details about pricing, plans and features, <a href="https://www.presenta.cc/pricing" target="_blank">check this page</a>.</p>
                          </div>

                          <div class="coupon" v-if="!isPro(company)">
                            <p style="padding:0; text-align:center;">Coupon Code:</p>

                            <div class="btnContainer">
                            <div class="form">
                              <input type="text" v-model="company.couponCode" />
                              <button style="margin-left:.5rem;" :disabled="!company.couponCode" @click="checkCoupon(company)" class="btn small positive">{{company.couponChecking ? 'Checking' : 'Redeem'}}</button>
                            </div>
                            </div>

                            <p class="couponFeedback">{{company.couponMessage}}</p>
                          </div>

                          <!-- <div class="connect" v-if="!company.connects">
                            <p style="padding:0; text-align:center;">Connect your Social Account:</p>
                            <div class="btnContainer"><button @click="startOAuthFlow(company)" class="btn small alternate">Connect Twitter</button></div>
                          </div> -->

                          <div class="connect" v-if="company.connects">
                            <p style="padding:0; text-align:center;">You are Social connected</p>
                            <div class="btnContainer"><button @click="removeConnect(company)" class="btn small">Remove {{ usrConnect(company) }}</button></div>
                            <p style="padding:0; text-align:center;"><a href="" @click.prevent="testConnection(company)">{{ alreadyTested(company) ? 'Check your Account feed' : 'Test Connection' }}</a></p>
                          </div>
                            

                        </div>
                      </div>

                      <APIMetrics :curl="company.url"  v-if="company.showDetails" />
                        
                    </div>
                </div>


            </div>
        </div>
      </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import APIMetrics from '@/components/profile/APIMetrics'

export default {
  components:{
    APIMetrics
  },
  computed: {
    ...mapGetters(['user', 'gravatar', 'companyId']),
    notConfirmed(){
      return this.user.ct !== '1'
    },
    prefs(){
      return this.user.prefs
    },
    
  },
  async mounted(){
    let cc = 0
    for(const comp of this.user.companies){
      const connects = await this.getConnect(comp.url)
      const connectsData = connects.data
      if(connectsData) connectsData.tested = false
      Vue.set(comp, 'connects', connectsData)
      Vue.set(comp, 'showDetails', cc == 0 ? true : false)
      cc++
    }

    const id = this.$route.params.id
    if(id){
      try{
        const sob = atob(id)
        const ob = JSON.parse(sob)
        const compExists = this.user.companies.find(c => c.url === ob.curl)
        if(compExists){
          const payload = {
            curl: compExists.url,
            twitter:{
              access_token_key: ob.accessToken,
              access_token_secret: ob.refreshToken,
              username: ob.username,
              provider: ob.provider,
              id: ob.id
            }
          }
          const res = await this.putConnect(payload)
          Vue.set(compExists, 'connects', payload)
        }
      }catch(e){
        console.log(e.message)
      }
    }
  },  
  data(){
    return{
      sent: false,
      reqCard: false,
    }
  },
  methods:{
    ...mapActions(['logout', 'resend', 'addCard', 'addCoupon', 
      'getConnect', 'putConnect', 'delConnect', 'testConnect'
    ]),
    ...mapMutations(['emptyUser']),
    async doLogout(){
      try{
        let res = await this.logout()
        if(res.data && !res.data.error) {
          this.emptyUser()
          this.$router.push('/login')
        }
      }catch(err){
        console.log(err)
      }
    },
   async doResend(){
      try{
        let res = await this.resend()
        if(res.data && !res.data.error) this.sent = true
      }catch(err){
        console.log(err)
      }
    },

    getLabel(c){
      let name = c.primary ? 'Personal' : c.name
      return name
    },


    async addBillingCard(c){
      this.reqCard = true
      
      const res = await this.addCard({
          customerId: c.customer,
          curl: c.url
      })
      
      const { data } = res || {}
      const { url } = data || {}
      if(url) location.href = url
    },

    startOAuthFlow(c){
      window.location.href = process.env.VUE_APP_SOCIAL_CONNECT_URL + 'twitter/init/' + c.url
    },  

    async removeConnect(c){
      await this.delConnect({curl: c.url})
      Vue.set(c, 'connects', null)
    },  

    usrConnect(c){
      return c.connects ? '@' + c.connects.twitter.username : 'none'
    },  

    async testConnection(c){
      await this.testConnect({curl: c.url, name: c.name})
      c.connects.tested = true
    },  

    alreadyTested(c){
      return c.connects && c.connects.tested
    },

    isPro(company){
      return company.plan.isPro
    },

    async checkCoupon(company){
      Vue.set(company, 'couponMessage', '')
      Vue.set(company, 'couponChecking', true)

      try{
        const res = await this.addCoupon({curl: company.url, coupon: company.couponCode})
        location.reload()
      }catch(e){
        let message = e.message
        const {response} = e
        if(response) message = response.data.message
        Vue.set(company, 'couponMessage', 'Error: ' + message)
      }

      Vue.set(company, 'couponChecking', false)
    }
    
  }
}
</script>



<style scoped>
.main{
  display: flex;
  align-items: center;
}
h1, h3, p{
  margin: 0;
  padding-bottom: .5rem;
}

p:last-child{
  padding: 0;
}

.col3{
  display: flex;
}
.col3 > div{
  flex:1;
  margin: 0 .5rem;
}
.col3 > div:first-child{
  margin-left: 0;
}
.col3 > div:last-child{
  margin-right: 0;
}

.notice{
  padding: 1rem;
  background-color:var(--ghighlightcolor);
  border: 1px solid var(--btnOrangeColorHover);
}

.connect{
  padding: 1rem;
  background-color:var(--lightblue);
  border: 1px solid var(--btnBlueColorHover);
}

.workspace{
  margin-top:3rem;
}

.img{
  padding-right: 1rem;
}
.img img{
  border-radius: 50%;
  overflow: hidden;
  border: 6px solid var(--borderselectedcolor);

  width: 100px;
  height: 100px;
}
.red{
  color: red;
}

.badge{
  background-color: var(--borderselectedcolor);
  color: white;
  padding: .1rem .4rem;
  border-radius: 10px;
  font-size: .75rem;
  margin-left: .25rem;
  margin-right: .25rem;
  font-weight: 700;
}
.pro{
  background-color: gold;
  color: black;
}

.free{
  background-color: #ddd;
  color: black;
}

.btnContainer{
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alertBanner{
  padding: 1rem;
  text-align: center;
  font-weight: bold;
}

.coupon{
  padding: 1rem;
  background-color:#f2fed7;
  border: 1px solid var(--btnGreenColorHover);
}

.coupon .form{
  display: flex;
}

.couponFeedback{
  margin-top: .5rem;
  color: var(--btnRedColor);
  font-weight: 900;
}
</style>