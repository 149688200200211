<template>
    <div class="inner" v-if="next.show_adminonly">

        <Format :root="document" @change="onChanged" />
        <PreScript :root="document" @change="onChanged" />
        
        <Fonts :root="document" @change="onChanged" />
        <Colors :root="document" @change="onChanged" />
        
        <div v-if="publicUrl">
            <Ctrl  @change="onCtrlChanged" :key="ctrl.name" :item="ctrl" v-for="ctrl in options" />
        </div>

    </div>
</template>



<script>

import Vue from 'vue'
import SelectCtrl from './ui/SelectCtrl'
import CheckboxCtrl from './ui/CheckboxCtrl'
import TextCtrl from './ui/TextCtrl'
import AreaCtrl from './ui/AreaCtrl'
import ListCtrl from './ui/ListCtrl'
import RangeCtrl from './ui/RangeCtrl'
import AssetCtrl from './ui/AssetCtrl'
import HtmlCtrl from './ui/HtmlCtrl'
import ColorCtrl from './ui/ColorCtrl'
import FileCtrl from './ui/FileCtrl'
import MultiCtrl from './ui/MultiCtrl'
import DynsetCtrl from './ui/DynsetCtrl'
import HiddenCtrl from './ui/HiddenCtrl'
import SeparatorCtrl from './ui/SeparatorCtrl'
import ScripterCtrl from './ui/ScripterCtrl'
Vue.component('select-ctrl', SelectCtrl)
Vue.component('checkbox-ctrl', CheckboxCtrl)
Vue.component('text-ctrl', TextCtrl)
Vue.component('area-ctrl', AreaCtrl)
Vue.component('list-ctrl', ListCtrl)
Vue.component('range-ctrl', RangeCtrl)
Vue.component('asset-ctrl', AssetCtrl)
Vue.component('html-ctrl', HtmlCtrl)
Vue.component('color-ctrl', ColorCtrl)
Vue.component('file-ctrl', FileCtrl)
Vue.component('multi-ctrl', MultiCtrl)
Vue.component('dynset-ctrl', DynsetCtrl)
Vue.component('hidden-ctrl', HiddenCtrl)
Vue.component('separator-ctrl', SeparatorCtrl)
Vue.component('scripter-ctrl', ScripterCtrl)


import { mapGetters, mapMutations } from 'vuex';
import Format from './ctrls/Format'
import Fonts from './ctrls/Fonts'
import Colors from './ctrls/Colors'
import PreScript from './ctrls/PreScript'

import Ctrl from './Ctrl'
import options from '@/libs/def/project_options'
import { fromUItoSettings, fromSettingsToUI } from './utils'
import { clone, stringify } from '@/libs/stringify'
import copyClipboard from '@/libs/copyClipboard'

export default {
    components:{
        Ctrl, Format, Fonts, Colors, PreScript
    },
    data(){
        return{
            options: []
        }
    },
    computed:{
        ...mapGetters(['project', 'document', 'company', 'publicUrl']),
        next(){
            return this.company && this.company.next ? this.company.next : {}
        }
    },
    mounted(){
        this.options = clone(options)
        fromSettingsToUI(this.options, this.document)
    },
    methods:{
        ...mapMutations(['notifyProjectUpdate']),

        onChanged(){
            this.notifyProjectUpdate(true)
        },
        
        onCtrlChanged(ob){
            fromUItoSettings(ob, this.document)
            this.notifyProjectUpdate(true)
        },

        dumpPresets(){
            const ob = {}

            const arr = [{
                def: {
                    pTemplateID: null,
                    blocks: [],
                    modules: {}
                }
            }]

            this.document.scenes.forEach(s => {
                const def = s
                def.pTemplateID = null
                arr.push({def})
            })

            ob.frames = arr

            copyClipboard(stringify(ob))
        }
        
    }
}
</script>




<style scoped>
.inner{
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    padding:.5rem 1px;
    display: flex;
    flex-direction: column;
    padding: .4rem;
}

.inner > p{
    font-size: .8rem;
    margin: 0;
    margin-bottom: .5rem;
    margin-top: .5rem;
    text-align: left;
    width: 100%;
}
</style>