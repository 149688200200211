export default {
  state: {
    company: null,
    pluginsLoaded: false
  },
  mutations: {
    setCompany (state, ob) {
      state.company = ob
    },
    unsetCompany (state) {
      state.company = null
    },
    removeProject (state, project) {
      const index = state.company.projects.indexOf(project)
      if (index >= 0) state.company.projects.splice(index, 1)
    },
    setPluginsLoaded (state, v) {
      state.pluginsLoaded = v
    }
  },
  getters: {
    company (state) {
      return state.company
    },
    projects (state) {
      return state.company ? state.company.projects : []
    },
    pluginsLoaded (state) {
      return state.pluginsLoaded
    }
  }
}
