<template>
    <v-popover trigger="manual" class="btnContainer"
        :open="subOpen"
        @hide="subOpen=false"
        :auto-hide="true">

        <button 
            @click="subOpen=!subOpen"
            class="withicon">
            <img class="icon" src="@/assets/icons/newprj.svg" />
            New project
        </button>
        
        <template slot="popover">
            <ul class="listmenu">

                <li @click="notify('figma')">
                    <span>Template from Figma...</span>
                </li>
<!-- 
                <li @click="notify('empty')">
                    <span>New empty Project</span>
                </li>

                <li @click="notify('templates')">
                    <span>Start with a Template...</span>
                </li>

                <li @click="notify('figma')">
                    <span>Template from Figma...</span>
                </li>

                <li @click="notify('csv')" :class="{disabled: !next.show_adminonly}">
                    <span>Project from CSV...</span>
                </li>

                <li @click="notify('markdown')" :class="{disabled: !next.show_adminonly}">
                    <span>Project from Markdown...</span>
                </li>

                 -->

                    
            </ul>
        </template>
    </v-popover>
</template>



<script>
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            subOpen:false
        }
    },
    computed:{
        ...mapGetters(['company']),
        next(){
            return this.company && this.company.next ? this.company.next : {}
        }
    },
    methods:{
        notify(e){
            this.subOpen = false
            this.$emit('select', e)
        }
    }
}
</script>




<style>
.trigger{
    width: 100%;
}
</style>
<style scoped>
.btnContainer,
.trigger,
button{
    width: 100%;
}

</style>