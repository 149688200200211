<template>
    <div @keydown.stop="">
        <transition name="offcanvas">
            <ProjectOptions key="s1" class="sidepanel" v-show="panel==='project'" />
        </transition>
        <transition name="offcanvas">
            <FrameOptions key="s2" class="sidepanel" v-show="panel==='frame'" />
        </transition>
        <transition name="offcanvas">
            <ElementOptions key="s3" class="sidepanel" v-show="panel==='element'" />
        </transition>
        <transition name="offcanvas">
            <MultipleFramesOptions key="s4" class="sidepanel" v-show="panel==='multi'" />
        </transition>
    </div>
</template>



<script>
import ProjectOptions from './sidebar/ProjectOptions'
import FrameOptions from './sidebar/FrameOptions'
import ElementOptions from './sidebar/ElementOptions'
import MultipleFramesOptions from './sidebar/MultipleFramesOptions'
import { mapGetters } from 'vuex'

export default {
    components:{
        ProjectOptions, FrameOptions, ElementOptions, MultipleFramesOptions
    },
    
    computed:{
        ...mapGetters(['selection', 'slides', 'selectedBlockIndex']),
        isEmpty(){
            return !this.slides || this.slides.length === 0
        },
        panel(){
            let v = 'project'
            if(this.selection.length > 0) v = 'multi'
            if(this.selection.length === 1) v = 'frame'
            if(this.selection.length === 1 && this.selectedBlockIndex >= 0) v = 'element'
            return v
        }
    }
}
</script>




<style scoped>
.sidepanel{
  position: absolute;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  display: flex;
  flex-direction: column;
}

.offcanvas-enter-active, .offcanvas-leave-active {
  transition: transform .35s cubic-bezier(0.19, 1, 0.22, 1);
}
.offcanvas-enter, .offcanvas-leave-to {
  transform: translateX(100%);
}
</style>