<template>
    <p>
        <button v-tooltip="'Delete'" class="small danger" @click.prevent="onDeleteProject()">
            <img class="icon" src="@/assets/icons/delete.svg" />
        </button>
    </p>
</template>



<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    props:{
        project:Object
    },
    computed:{
        ...mapGetters(['companyId']),
    },
    methods:{
        ...mapActions(['deleteProject', 'setStatus', 'unpublishPlugin']),
        async onDeleteProject () {
            console.log(this.project)
            if (window.confirm(`Sure to delete "${this.project.name}" project? Either the published API/URL or the Automated features will be removed if they are active.`)) {
                let p = this.project
                try {
                    // console.log(p)
                    // const ob = {curl: this.companyId, purl: this.purl, active}
                    // disable scheduler
                    // await this.setStatus({curl: this.companyId, purl: this.purl, active})

                    // remove frame API
                    // await this.unpublishPlugin({curl: this.companyId,purl: this.templateAPI.pTemplateID})
                    // this.templateAPI.pTemplateID = null
                    
                    let res = await this.deleteProject(p)
                    if(res.data) {
                        this.$store.commit('removeProject', p)
                    }
                } catch(err) {
                    console.log(err)
                }
            }
            
        }
    }
}
</script>
