<template>
    <div class="row">
        <div class="inner">
        <p class="desc">
            <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
            <span class="label">{{ctrl.label || ctrl.key}}</span> 
        </p>
        <component v-for="c in controls" :key="c.key"
            :is="c.type + '-ctrl'" 
            :ctrl="c"
            @change="$emit('change')" />
        </div>
    </div>
</template>


<script>
export default {
    props:{
        ctrl:Object
    },
    computed:{
        controls(){
            return this.ctrl.controls || []
        }
    }
}
</script>


<style scoped src="./edev.css"></style>
<style scoped>
.inner{
    border:1px dashed #fff;
}

</style>