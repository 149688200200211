import axios from 'axios'
const CancelToken = axios.CancelToken

export default {
  state: {
    uploading: []
  },
  getters: {
    uploadingList (state) {
      return state.uploading
    }
  },
  mutations: {
    addToUpload (state, fob) {
      const f = fob.file
      const ob = {
        o: f,
        name: f.name,
        type: f.type || fob.type,
        curl: fob.curl,
        key: fob.key,
        loaded: 0,
        size: f.size,
        inProgress: false,
        sourceToken: null,
        onSuccessClbk: fob.onSuccess,
        onErrorClbk: fob.onError
      }
      state.uploading.push(ob)
    },
    startUploading (state) {
      const toBeProcessed = state.uploading.filter(f => !f.inProgress)
      toBeProcessed.forEach(f => {
        f.inProgress = true
        this.commit('uploadItem', f)
      })
    },
    async uploadItem (state, file) {
      file.sourceToken = CancelToken.source()

      let res1 = null
      try {
        res1 = await axios.get(`${process.env.VUE_APP_API_BACKEND_URL}/assets/s3?filename=${file.name}&filetype=${file.type}&filesize=${file.size}&curl=${file.curl}`)
      } catch (err) {
        console.log(err)
      }

      try {
        await axios.put(res1.data.signedRequest, file.o, {
          withCredentials: false,
          onUploadProgress: pEvent => {
            file.loaded = pEvent.loaded
          },
          headers: { 'Content-Type': file.type },
          cancelToken: file.sourceToken.token
        })
      } catch (err) {
        if (file.onErrorClbk) file.onErrorClbk(err, file)
        if (axios.isCancel(err)) {
          return
        } else {
          throw err
        }
      }

      const res3 = await axios.post(`${process.env.VUE_APP_API_BACKEND_URL}/assets/uploaded`, res1.data)
      console.log(res3)

      if (file.onSuccessClbk) file.onSuccessClbk(res3, file)

      this.commit('removeItem', file)
    },
    abortUpload (state, f) {
      f.sourceToken.cancel('Operation canceled by user.')
      this.commit('removeItem', f)
    },

    removeItem (state, f) {
      const index = state.uploading.indexOf(f)
      state.uploading.splice(index, 1)
    }
  }
}
