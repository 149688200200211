<template>
    <div class="row">

        <div class="colleft">
            <p class="desc">
                <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
                <span class="label" @dblclick="custom=!custom">{{ctrl.label || ctrl.key}}</span>
            </p>
        </div>
        
        <div class="colright">
            <div class="flex" v-if="!custom">
                <div class="preview" v-if="ctrl.value" @click="remove">
                    <img :src="getPreview()" />
                </div>
                
                <button @click="showAssets=true">...</button>
            </div>

            <div class="flex" v-if="custom">
                <input v-model="ctrl.value" @input="onChangeCustom" :placeholder="ctrl.placeholder" />
            </div>
        </div>
        

        <ModalContainer v-if="showAssets" @close="showAssets=false">
            <AssetsManager 
                @selected="selected" 
                @close="showAssets=false"
                :type="ctrl.file" />
        </ModalContainer>

    </div>
</template>


<script>
import ModalContainer from '@/components/ModalContainer'
import AssetsManager from '@/components/assetsManager/AssetsManager'

export default {
    props:{
        ctrl:Object
    },
    data(){
        return{
            showAssets:false,
            v: '',
            custom: false
        }
    },
    components:{
        AssetsManager,ModalContainer
    },
    methods:{
        selected(v){
            const isNotImage = ['svg', 'ttf', 'otf']
            const lastChunk = isNotImage.indexOf(v.ext) >= 0 ? '?func=proxy' : '?w=900'
            this.ctrl.value = v.url + lastChunk
            this.$emit('change', this.ctrl)
        },
        onChangeCustom(){
            this.$emit('change', this.ctrl)
        },
        remove(){
            this.ctrl.value = null
            this.$emit('change', this.ctrl)
        },
        getPreview(){
            return this.ctrl.value.replace('?func=proxy', '?w=100')
        }
    }
}
</script>


<style scoped src="./edev.css"></style>

<style scoped>
.preview{
    margin-right: .5rem;
}
.preview img{
    width: 60px;
}
</style>