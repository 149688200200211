<template>
    <div class="body">
        <iframe class="plugin" :src="url"></iframe>
        <div class="loading" v-if="!loaded">
            <p>Loading plugin {{plugin.label}} ...</p>
        </div>
    </div>
</template>



<script>
import Vue from 'vue'
import { fixScene } from '@/libs/defaults.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {clone} from '@/libs/stringify'
import queryString from 'query-string'

export default {
    props:{
        plugin:Object
    },
    data(){
        return{
            loaded:false,
            url: null
        }
    },
    computed:{
        ...mapGetters(['slides', 'project', 'companyId', 'selection', 'busGetDocumentInfo', 'scenePlugins']),
        isActive(){
            return this.plugin.selected
        }
    },
    mounted(){
        this.url = this.plugin.url
        if(this.url) {
            window.addEventListener("message", this.listenMessage)
            this.checkLoading()
        }
    },
    beforeDestroy(){
        window.removeEventListener("message", this.listenMessage)
    },
    methods:{
        checkLoading(){
            Vue.nextTick(() => {
                let frame = this.$el.querySelector('iframe')
                if(frame){
                    frame.addEventListener("load",  e => {
                        this.loaded = true
                    })                    
                }
            })
        },

        ...mapActions([
            'sendPluginMessage', 
            'savePluginState',
            'getPluginState',
            'addSlideAt', 
            'deleteSlide', 
            'busDeleteAllSlides', 
            'busDeleteSelectedSlides', 
            'busDeleteSlide']),
        ...mapMutations([
            'notifySlideUpdate', 
            'notifyProjectUpdate', 
            'setCurrentPlugin', 
            'clearSelection', 
            'addToSelection', 
            'removeFromSelection',
            'setZoomIn', 
            'setZoomOut',
            'setZoomMin',
            'setZoomMax',
            'addImageToSlide'
        ]),

        async listenMessage(e){
            if(!this.isActive) return 

            let affected = false
            if(e.data && e.data.source){
                let pay = e.data.payload
                let indexAt = this.selection.length > 0 ? this.slides.indexOf(this.selection[0]) + 1 : null
                                
                switch(e.data.source){

                    case 'pplug.createScenes':
                        if(pay.scenes){
                            this.clearSelection()
                            pay.scenes.forEach((s,i) => {
                                let scene = s

                                const pFrameName = s.pFrameName
                                const templateID = s.pTemplateID

                                if(templateID){
                                    const templ = this.scenePlugins.find(d => d.id === templateID)
                                    if(templ){
                                        scene = clone(templ.def)

                                        scene.pFrameName = pFrameName
                                        scene.pPluginID = null
                                        scene.pTemplateID = null
                                        
                                        const qrystr = Object.keys(s).map(key => `${key}=${s[key]}`).join('&')
                                        const qry = queryString.parse('?' + qrystr)

                                        const defKey = {
                                            text: 'content',
                                            gtext: 'content',
                                            image: 'url',
                                            shape: 'color',
                                            video: 'url',
                                            youtube: 'url',
                                            qrcode: 'content'
                                        }

                                        for (const k in qry) {
                                            const p = qry[k]
                                            const block = scene.blocks.find(b => b.ukey === k)
                                            if (block) {
                                                if (typeof p === 'object') {
                                                    for (const y in p) {
                                                        const md = p[y]
                                                        if (typeof md === 'object') {
                                                            for (const g in md) {
                                                                if(!block.modules[y]) block.modules[y] = {}
                                                                block.modules[y][g] = md[g]
                                                            }
                                                        }else{
                                                            block[y] = md
                                                        }
                                                    }
                                                } else {
                                                    const d = defKey[block.type]
                                                    if (d) block[d] = p
                                                }
                                            }
                                        }
                                                  

                                    }else{
                                        scene = {
                                            blocks:[{
                                                type:'text', text:'Error: Missing Template ID :'+templateID,
                                                scale: 2
                                            }]
                                        }
                                    }
                                }
                                
                                fixScene(scene,i)
                                this.addSlideAt({slide: scene})
                                affected=true

                            })
                        }
                        break

                    default:
                        break
                    }
                
                if(affected) this.notifyProjectUpdate()
            }
        }
    }
}
</script>






<style scoped>

.body{
    flex:1;
    width: 100%;
    height: 100%;
    position: relative;
}

.plugin{
    flex:1;
    width: 100%;
    height: 100%;
    border: 0;
}


.loading{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
</style>