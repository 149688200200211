const opt = ['==', '!=']
const options = opt.map(o => ({ key: o, label: o, value: o }))

export default {
  key: 'showif',
  name: 'Show If',
  description: 'Show or Hide the Block based on a condition',
  context: 'modules',
  value: false,
  visible: ['block'],
  options: [
    { key: 'key', label: 'Property', type: 'text', value: '' },
    { key: 'op', label: 'Operator', type: 'select', value: '', options },
    { key: 'value', label: 'Value', type: 'text', value: '' }
  ]
}
