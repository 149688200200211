<template>
    <div class="handler" @mousedown="onDown"></div>
</template>



<script>
export default {
    data(){
        return{
            ix:0,
            iw:0
        }
    },
    methods:{
        onDown(e){
            this.ix = e.clientX
            let readw = getComputedStyle(this.$el).getPropertyValue('--pluginEditWidth')
            this.iw = parseInt(readw.replace('px', ''))
            window.addEventListener('mouseup', this.onUp)
            window.addEventListener('mousemove', this.onMove)
            this.$emit('startResizing')
        },
        onMove(e){
            let cx = e.clientX
            let diff = cx - this.ix
            let fw = this.iw + diff
            document.body.style.setProperty('--pluginEditWidth', fw+'px')
            window.dispatchEvent(new Event('resize'))
        },
        onUp(){
            window.removeEventListener('mouseup', this.onUp)
            window.removeEventListener('mousemove', this.onMove)
            this.$emit('stopResizing')
        }
    }
}
</script>



<style scoped>
.handler{
    width: 6px;
    max-width: 6px;
    min-width: 6px;
    background-color: var(--greybarsback);
    background-color: #fff;
    cursor: col-resize;
    border-left: 1px solid #ddd;

}
.handler:hover{
    background-color: #ddd;
}
</style>