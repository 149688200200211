import transitions from './controllers/transitions'

import fullscreen from './controllers/fullscreen'
import progressbar from './controllers/progressbar'
import loop from './controllers/loop'
import keyboard from './controllers/keyboard'
import limitswitch from './controllers/limitswitch'
import arrows from './controllers/arrows'
import cache from './controllers/cache'
import swiper from './controllers/swiper'

import steps from './modules/steps'
import autoplay from './modules/autoplay'
import enters from './modules/enters'

export default [

  transitions,
  enters

  // progressbar,
  // autoplay,
  // loop,
  // arrows,
  // swiper,
  // pagenum,

  // steps
  // fullscreen,

  // keyboard,
  // limitswitch
  // cache

]
