<template>
    <div class="row">

        <div class="colleft">
            <p class="desc">
                <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
                <span class="label">{{ctrl.label || ctrl.key}}</span>
            </p>
        </div>
        
        <div class="colright">
            <div class="flex">
                <div class="adder">
                    <label for="uploadmyfilesfromctrl">
                        <span>Pick file</span>
                    </label>
                    <input type="file" id="uploadmyfilesfromctrl" 
                        :accept="accept"
                        :multiple="multiple"
                        @change="requestFile" />
                </div>
            </div>
        </div>

    </div>
</template>


<script>

export default {
    props:{
        ctrl:Object
    },
    data(){
        return{
            showAssets:false
        }
    },
    computed:{
        multiple(){
            return this.ctrl.multiple
        },
        accept(){
            return this.ctrl.accept || 'text/*'
        }
    },
    methods:{
        requestFile(){
            var e = this.$el.querySelector('#uploadmyfilesfromctrl')
            const file = e.files[0]

            let fr = new FileReader()
            fr.readAsText(file)
            fr.onload = () => {
                console.log(fr.result)
                this.ctrl.value = fr.result
                this.$emit('change', this.ctrl)
            }; 
            fr.onerror = () => {
                console.log(fr.error)
            }; 
        },
    }
}
</script>


<style scoped src="./edev.css"></style>

<style scoped>
.preview{
    margin-right: .5rem;
}
.preview img{
    width: 60px;
}
input{
    display: none;
}
</style>