<template>
    <div class="inner">
        <p>Multiple frames selection options <br />not available yet</p>
    </div>
</template>


<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    components:{
    },
    data(){
        return{
        }
    },
    computed:{
        ...mapGetters(['selection'])
    },
    methods:{
        ...mapMutations(['notifyProjectUpdate'])
    }
}
</script>




<style scoped>
.inner{
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    padding:.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
}

.inner{
    justify-content: center;
    font-size: .8rem;
}
</style>