import md5 from 'md5'
import { stringify, clone } from './stringify'

export const sid = (i, s) => md5(i + stringify(s))

export default (project, forceUpdate) => {
  if (project && project !== undefined && project.document) {
    const cloned = clone(project)

    // this forces a regeneration even if there aren't any change
    if (forceUpdate) cloned.pidx = Math.random()

    delete cloned.document.scenes
    const duid = sid('', cloned)

    project.document.scenes.forEach((s, i) => {
      s.$uid = sid(duid + i, s)
    })
  }
}
