<template>
    <div class="wrapper">

        <div class="inner">

            <h3>Configure GoShare™ Landing <span v-if="present">{{saving ? 'Saving...' : 'Saved'}}</span></h3>

            <div class="row">
                <p>Title</p>
                <input @change="save" type="text" v-model="payload.title" />
            </div>

            <div class="row">
                <p>Description</p>
                <textarea @change="save" v-model="payload.description"></textarea>
            </div>

            <div class="row logo">
                <p>Logo URL</p>
                <div style="display:flex; align-items: center; justify-content: center;">
                    <img :src="payload.logo" />
                    <button class="btn small light" @click="showAssets=true">...</button>
                </div>
                
                
            </div>

            <div class="row">
                <p>Destination URL:</p>
                <input @change="save" type="text" placeholder="https://..." v-model="payload.url" />
            </div>



            <h4>Social Sharing</h4>
            <div class="row">
                <p>Twitter message:</p>
                <input @change="save" placeholder="leave empty to disable twitter" type="text" v-model="payload.share_with.twitter" />
            </div>

            <div class="cols">
                <div class="row">
                    <p>Linkedin:</p>
                    <input @change="save" type="checkbox" v-model="payload.share_with.linkedin" />
                </div>
                <div class="row">
                    <p>Facebook:</p>
                    <input @change="save" type="checkbox" v-model="payload.share_with.facebook" />
                </div>
                <div class="row">
                    <p>Copy:</p>
                    <input @change="save" type="checkbox" v-model="payload.share_with.copy" />
                </div>
                <div class="row">
                    <p>Download:</p>
                    <input @change="save" type="checkbox" v-model="payload.share_with.download" />
                </div>
            </div>



            <h4>Style</h4>
            <div class="row">
                <p>Main color:</p>
                <input @change="save" type="text" v-model="payload.color" />
            </div>

            <div class="row">
                <p>Background:</p>
                <input @change="save" type="text" placeholder="optional" v-model="payload.background" />
            </div>



        </div>

        <ModalContainer v-if="showAssets" @close="showAssets=false">
            <AssetsManager 
                @selected="onImageSelected" 
                @close="showAssets=false"
                type="image" />
        </ModalContainer>
        
        
    </div>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ModalContainer from '@/components/ModalContainer'
import AssetsManager from '@/components/assetsManager/AssetsManager'

export default {
    
    components:{
        AssetsManager,ModalContainer
    },
    data(){
        return {
            showAssets:false,
            saving:false,
            present:false,
            tid: null,
            payload: {
                title:'',
                description:'',
                share_with:{
                    twitter: '',
                    linkedin: true,
                    facebook: true,
                    copy: true,
                    download: true
                },
                logo: '',
                user_param:null,
                url: '',
                color: '',
                background: '',
                site: 'Presenta',
                creator: '@PresentaSw'
            }
        }
    },
    async mounted(){
        this.tid = this.companyId + ':' + this.templateAPI.pTemplateID
        const res = await this.getSharer(this.tid)
        const sharer = res.data
        if(sharer){
            this.present = true
            for(const k in this.payload){
                this.payload[k] = sharer[k]
            }

            // this.payload.user_param = {
            //     key: 'name',
            //     prompt: 'Hello there'
            // }
        }
    },
    methods:{
        ...mapActions(['getSharer', 'putSharer']),
        async save(){
            this.saving = true
            const res = await this.putSharer({curl:this.companyId, id: this.tid, data: this.payload})
            this.saving = false
            this.present = true
       },
        onImageSelected(ob){
            this.payload.logo = ob.url
            this.save()
        }
    },
    computed:{
        ...mapGetters(['templateAPI', 'companyId', 'document', 'company', 'project'])
    }
}

</script>



<style scoped>

h3{
    margin: 0;
    text-align: center;
    margin-bottom: 1rem;
}
h4{
    margin: 0;
    text-align: center;
    margin-top: 1rem;
}

h3 span{
    font-size: .9rem;
    color: var(--btnGreenColor);
}
.wrapper{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 999;
    overflow-y: auto;
}

textarea, input[type="text"]{
    width: 100%;
}


.cols{
    display: flex;
    flex-wrap: wrap;
}
.cols .row{
    width: 50%;
}
.row{
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #fff;
}

.row p{
    margin: 0;
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.row input[type="text"], .row textarea{
    width: 100%;
    padding: 0.25rem;
    border: 1px solid #ccc;
}
.row input[type="checkbox"]{
    margin: 0 0.5rem;
}

.row.logo img{
    width: 70px;
    height: auto;
}
.cta{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.innerWrapModal{
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background-color: rgba(255,255,255,.7);
}
</style>