export default {
  key: 'qrcode',
  name: 'QRCode',
  description: '',
  basic: true,
  value: true,
  options: [
    { key: 'content', type: 'text', value: '' },
    { key: 'color', type: 'color', value: 'var(--colorFore)' }
  ]
}
