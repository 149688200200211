<template>
    <button :class="{triggered, restored}" @click.prevent="onClicked">
     {{label}}
    </button>
</template>



<script>
export default {
    props:{
        labelA: String,
        labelB: String,
        handleClick: Function
    },
    data(){
        return {
            label: this.labelA,
            triggered: false,
            restored: false
        }
    },
    methods:{
        onClicked(){
            this.handleClick()
            this.label = this.labelB
            
            this.triggered = true

            setTimeout(() => {
                this.triggered = false
                this.label = this.labelA
            }, 750)
        }
    }
}
</script>


<style scoped>


</style>