<template>
    <div  class="head">
        <div class="toggler" @click="toggleSummary">
            <img v-if="show" src="@/assets/icons/downdrop.svg" />
            <img v-if="!show" src="@/assets/icons/rightdrop.svg" />
        </div>

        <p class="toggler" @click="toggleSummary">{{label}}</p>
    </div>
</template>


<script>
export default {
    props:{
        label:String,
        openOnInit:false
    },
    data(){
        return{
            show:false
        }
    },
    mounted(){
        this.show = this.openOnInit
    },
    methods:{
        toggleSummary(){
            this.show = !this.show
            this.$emit('changed')
        }
    }
}
</script>



<style scoped>

.head{
    display: flex;
    align-items: center;
    background-color: none;
    font-size: .8rem;
}
.head img{
    width: 20px;
    height: 20px;
}
.head p{
    padding: 0;
    margin: 0;
    flex: 1;
    padding: .5rem 0rem;
    user-select: none;
}

.toggler{
    cursor: pointer;
    display: flex;
}

</style>