<template>
    <div class="block" :class="{open:show}">

        <CtrlHeader @changed="show=!show" :label="'Frame ID'" />
        
        <div v-show="show" class="summary">

            <div class="description">Change the frameID useful to identify it in API.</div>

            <div class="ctrlcontainer">
                <TextCtrl :ctrl="item" @change="onChange" />                
            </div>

        </div>

    </div>
</template>




<script>
import CtrlHeader from './CtrlHeader.vue'
import TextCtrl from '../ui/TextCtrl'

export default {
    props:{
        root:Object
    },
    data(){
        return{
            show: false,
            item: { label: 'frameID', value: ''}
        }
    },
    mounted(){
        this.item.value = this.root.pFrameID
    },
    computed:{
        pFrameID(){
            return this.root.pFrameID
        }
    },
    watch:{
        pFrameID(){
            this.update()
        }
    },
    components:{
       TextCtrl, CtrlHeader
    },
    methods:{
        onChange(){
            this.root.pFrameID = this.item.value
            this.$emit('change')
        },
        update(){
            this.item.value = this.root.pFrameID
        }
    }
}
</script>



<style scoped>
.block{
    width: 100%;
}
.block:hover{
    background-color: #eee;
}
.block.selecting{
    outline:1px solid #24a1f9;
}
.block.open{
    box-shadow: 0 4px 5px #ccc;
    margin-bottom: 1rem;
    background-color: #eee;
}


.inline{
    padding: 0;
}
.inline >>> p{
    margin: 0;
}
.inline >>> label{
    display: flex;
}

select.inline{
    background: none;
    border: none;
}

.description{
    font-size: .75rem;
    width: 100%;
}

.summary{
    display: flex;
    flex-wrap: wrap;
}
.ctrlcontainer{
    width: 100%;
    display: flex;
}


.summary{
    padding: .5rem;
    background-color: white;
}

.more{
    font-size: .7rem;
    text-align: center;
    width: 100%;
    padding: .3rem;
    padding-top: .75rem;
    cursor: pointer;
}

</style>
