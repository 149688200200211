<template>
    <div class="row">

        <div class="colleft">
            <p class="desc">
                <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
                <span class="label">{{ctrl.label || ctrl.key}}</span> 
            </p>
        </div>

        <div class="colright">
            <div class="line nav">
            
                <button class="left" @click="moveLeft" v-if="ctrl.nav">«</button>
                
                <select v-model="ctrl.value" @change="$emit('change', ctrl)">
                    <option :value="null"></option>
                    <option v-for="opt in ctrl.options" :value="opt.value" :key="opt.key">{{opt.label}}</option>
                </select>
                
                <button class="right" @click="moveRight" v-if="ctrl.nav">»</button>

            </div>
        </div>
    </div>
</template>


<script>
export default {
    props:{
        ctrl:Object
    },
    methods:{
        getIndex(){
            let idx = -1
            this.ctrl.options.forEach((d,i) => {
                if(d.value === this.ctrl.value) idx = i
            })
            return idx
        },
        moveLeft(){
            const idx = this.getIndex() - 1
            if(idx >= 0) this.ctrl.value = this.ctrl.options[idx].value
            this.$emit('change', this.ctrl)
        },
        moveRight(){
            const idx = this.getIndex() + 1
            if(idx < this.ctrl.options.length) this.ctrl.value = this.ctrl.options[idx].value
            this.$emit('change', this.ctrl)
        }
    }
}
</script>


<style scoped src="./edev.css"></style>