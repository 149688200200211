<template>
  <div class="viewScroll"
    @dragover.prevent="dragOver" 
    @drop.prevent="dropped"
    @dragleave.prevent="dragLeave">
    <EditorHeader />
    <!-- <HelpBar @togglePlugin="onTogglePlugin" /> -->
    <div class="editor">

        <div class="prewrapper">

            <div class="left" v-if="pluginPanelOpen">
              <div class="plug-wrapper">
                <Plugins />
                <HandlerV @startResizing="isResizing=true" @stopResizing="isResizing=false" />
              </div>
            </div>

            <div id="scenesContainerWrapper" class="center">
                <!-- <Slides v-if="!showEmpty && !focusMode" /> -->
                <FullSlide v-if="!showEmpty" />

                <EmptyState :msg="msg" v-if="showEmpty">
                  <p style="margin:0;" v-if="slides">Empty Project</p>
                  <!-- <h1 v-tooltip.bottom="'New Frame'" style="margin:0;padding-top:.5rem;cursor:pointer;" @click="busAddNewSlideAt">+</h1> -->

                  <!-- <v-popover
                      @show="initPresetList=true">
                      <h1 style="margin:0;padding-top:.5rem;cursor:pointer;">+</h1>
                      <template slot="popover">
                        <PresetList v-if="initPresetList" :index="0" class="inpopover" />
                      </template>
                  </v-popover> -->
                  
                </EmptyState>

                <KeyboardHandler v-if="!templateAPI"
                  @toggleFocusMode="focusMode=!focusMode"
                  @unfocusMode="focusMode=false" />
                
            </div>

            <Sidebar class="right" v-if="project && sidebarOpen" />

        </div>
    </div>

    <ApiPanelFrame v-if="templateAPI" />
    <ApiAutomatePanel v-if="automateObject" />

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import EmptyState from '@/components/EmptyState'
import EditorHeader from '@/components/editor/EditorHeader'
import HelpBar from '@/components/editor/HelpBar'
import DropToSlides from '@/components/editor/DropToSlides'
import Slides from '@/components/editor/Slides'
import FullSlide from '@/components/editor/FullSlide'
import HandlerV from '@/components/editor/HandlerV'
import Sidebar from '@/components/editor/Sidebar'
import PresetList from '@/components/editor/PresetList'
import KeyboardHandler from '@/components/editor/KeyboardHandler'
import ApiPanelFrame from '@/components/editor/ApiPanelFrame'
import ApiAutomatePanel from '@/components/editor/ApiAutomatePanel'

import Plugins from '@/components/editor/plugins/Index'

import format from '@/libs/format'



export default {
  components:{
    HelpBar, 
    Slides, EditorHeader, FullSlide,
    Sidebar, DropToSlides,
    EmptyState, 
    HandlerV, Plugins,
    PresetList,
    KeyboardHandler,
    ApiPanelFrame, ApiAutomatePanel
  },
  data(){
    return{
      isError: false,
      isLoading: false,
      msg: 'Loading Project...',
      dragging: false,
      debouncer: 0,
      requireCoverUpdate:false,
      pluginPanelOpen:false,
      isResizing:false,
      initPresetList:false,

      debounceResize:0,
      focusMode: false,

      observeScenes:null
    }
  },
  watch:{
    pidx(){
      if(!this.project) return
      if(this.saveStatusCode === 2) return

      this.setSaveStatusCode(1)
      clearTimeout(this.debouncer)

      this.debouncer = setTimeout(async () => {
        this.setSaveStatusCode(2)

        let payload = format.fromFrontToBack(this.project)
        let res = await this.saveProject( payload )

        if(res.data && !res.data.error){
          this.setSaveStatusCode(0)
        }else{
          this.setSaveStatusCode(3)
        }

        if(this.requireCoverUpdate){
          this.requireCoverUpdate = false
          let res2 = await this.updateProjectCover( format.firstSlideOnly(payload) )
          console.log(res2)
        }

      }, 1500)

    },

    cidx(){
      this.requireCoverUpdate = true
    }
  },
  computed:{
    ...mapGetters(['user', 'project', 'pidx', 'cidx', 'slides', 'document',
      'isEmptySelection', 'selection', 
      'sidebarOpen', 'isFileDropping', 'saveStatusCode',
      'pluginsLoaded', 'isSingleSelection',
      'templateAPI', 'automateObject']),
    showEmpty(){
      return !this.slides || this.slides.length === 0 || this.isError
    }
  },
  async created(){
    this.unsetProject()
    this.isLoading = true

    try {
      let res = await this.getProject(this.$route.params.id)
      let project = format.fromBackToFront(res.data)
      
      this.setProject(project)
      
      console.log(project)

      // set project/company plugin list
      const cmp = this.user.companies.find(c => c.url === project.curl)
      this.setCompany(cmp)
      this.setCompanyId(project.curl)
      this.msg = null
    } catch(err) {
      this.msg = 'The project is not found or not accessible'
      this.isError = true
    }

    this.isLoading = false
  },
  mounted(){
    this.clearSelection()
    window.addEventListener('resize', this.onWinResize)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.onWinResize)
  },
  methods:{
    ...mapMutations(['clearSelection',
      'setIsFileDropping', 'unsetIsFileDropping',
      'setSaveStatusCode',
      'setProject', 'setCompanyId', 'unsetProject', 'setCompany',
      'notifyProjectUpdate']),
    ...mapActions([
      'saveProject', 'updateProjectCover',
      'getProject', 'busAddNewSlideAt'
    ]),
    


    dragOver(){
      this.dragging = true
      this.setIsFileDropping()
    },
    dropped(){
      this.dragging = false
      this.unsetIsFileDropping()
    },
    dragLeave(){
      this.dragging = false
      //this.unsetIsFileDropping()
    },


    onTogglePlugin(url){
      this.pluginPanelOpen = !this.pluginPanelOpen
      this.notifyProjectUpdate(true)
    },
    onWinResize(){
      clearTimeout(this.debounceResize)
      this.debounceResize = setTimeout(() => {
        this.notifyProjectUpdate(true)
      }, 150)
      
    }
  }
}
</script>




<style scoped>
.editor {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.prewrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.draggingStatus .left{
  background-color: var(--ghighlightcolor);
}

.left, .right, .center{
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.left{
  width: var(--pluginEditWidth);
  min-width: 200px;
  overflow-y: hidden;
  background-color: var(--greybarsback);
  /* border-right: 1px solid #ddd; */
}

.center {
  flex:1;
  width: 100%;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.right {
  position: absolute;
  width:70%;
  height: 100%;
  right: 0;
  background-color: var(--greybarsback);;
  z-index: 999;
  border-left: 1px solid #ddd;
}

.sidepanel{
  position: absolute;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 720px){
  .right{
    position: relative;
    width: var(--sideEditWidth);
    max-width: var(--sideEditWidth);
    min-width: var(--sideEditWidth);
    height: inherit;
    transform: inherit;
    z-index: inherit;
  }
}

.offcanvas-enter-active, .offcanvas-leave-active {
  transition: transform .35s cubic-bezier(0.19, 1, 0.22, 1);
}
.offcanvas-enter, .offcanvas-leave-to {
  transform: translateX(100%);
}

.plug-wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
}



.inpopover{
    width: 300px;
    height: 220px;
    overflow: hidden;
    overflow-y: auto;
}
</style>


