<template>
    <div class="row">

        <div class="colleft">
             <p class="desc">
                <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
                <span class="label">{{ctrl.label || ctrl.key}}</span> </p>
        </div>

        <div class="colright">
            <textarea :placeholder="ctrl.placeholder" rows="5" v-model="ctrl.value" @input="onChange"></textarea>
        </div>
       
    </div>
</template>


<script>
export default {
    props:{
        ctrl:Object
    },
    data(){
        return{
            timer:null
        }
    },
    methods:{
        onChange(){
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.$emit('change', this.ctrl)
            }, 300)
        }
    }
}
</script>


<style scoped src="./edev.css"></style>