<template>
    <div class="row">

        <div class="colleft">
            <p class="desc">
                <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
                <span class="label">{{ctrl.label || ctrl.key}}</span>
            </p>
        </div>
        
        <div class="colright">
            <div class="flex">
                <button @click="showScriptEditor=true">...</button>
            </div>
        </div>
        
        <ModalContainer v-if="showScriptEditor" @close="showScriptEditor=false">
            <ScriptEditor v-if="typeEditor === 'script'"
                @saved="saved" 
                @close="showScriptEditor=false"
                :source="ctrl.value"
                :type="ctrl.env" />

            <PromptEditor v-if="typeEditor === 'prompt'"
                @saved="saved" 
                @close="showScriptEditor=false"
                :source="ctrl.value"
                :type="ctrl.env" />
        </ModalContainer>

    </div>
</template>


<script>
import ModalContainer from '@/components/ModalContainer'
import ScriptEditor from '@/components/miniEditors/ScriptEditor'
import PromptEditor from '@/components/miniEditors/PromptEditor'

export default {
    props:{
        ctrl:Object,
        type:String
    },
    data(){
        return{
            showScriptEditor:false,
            v: ''
        }
    },
    components:{
        ScriptEditor,PromptEditor,ModalContainer
    },
    computed:{
        typeEditor(){
            return this.type || 'script'
        }
    },  
    methods:{
        saved(v){
            this.ctrl.value = v
            this.$emit('change', this.ctrl)
        }
    }
}
</script>


<style scoped src="./edev.css"></style>

<style scoped>

</style>