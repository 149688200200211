<template>
    <div class="scriptEditor">
        

        <Ace v-model="code" 
                ref="editor"
                @init="editorInit" 
                :lang="type" 
                theme="chrome"></Ace>

        <div class="toolbar">
            <button class="light small" @click="$emit('close')">Discard</button>
            <button class="alternate small" @click="save">Confirm changes</button>
        </div>

    </div>
</template>



<script>
import Ace from 'vue2-ace-editor'

import { mapGetters } from 'vuex'

export default {
    props:{
        source:String,
        type:String
    },
    components:{
        Ace
    },
    data(){
        return{
            code:''
        }
    },
    mounted(){
        let ed = this.$refs.editor.editor
        ed.session.setTabSize(2)
        ed.session.setUseWorker(false) // disable error/warning hints in side bar
        ed.setOptions({
            placeholder: "Enter CSS Code", // doesnt work
            showPrintMargin: false,
            fontSize: "13pt",
            animatedScroll:true,
            showLineNumbers:true,
            showFoldWidgets:false,
            spellChecker:false,
            status:false,
            hideIcons: ['guide', 'preview', 'side-by-side', 'fullscreen'],
            showIcons: ['code', 'underline', 'strikethrough', 'horizontal-rule'],
        })

        this.code = this.source
    },
    computed:{
        ...mapGetters([])
    },
    methods:{
        editorInit(){
            require('brace/ext/language_tools')
            //require('brace/mode/yaml')
            //require('brace/mode/markdown')
            require('brace/mode/javascript')
            require('brace/mode/json')
            require('brace/mode/html')
            require('brace/mode/text')
            require('brace/mode/css')
            require('brace/theme/chrome')
        },
        save(){
            this.$emit('saved', this.code)
            this.$emit('close')
        }
    }
    
    
}
</script>



<style>
.ace_editor{
    font-size:14px;
    border:1px solid #ccc;
}
.ace-chrome .ace_gutter{
    background-color: #eee!important;
    color:#999!important;
}
.ace-chrome .ace_gutter-active-line{
    background-color: #eee!important;
}

.list .slide > .wrapper{
    border:10px solid black;
}
</style>


<style scoped>
.scriptEditor{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.toolbar{
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.toolbar button{
    margin: 0 .3rem;
}
</style>