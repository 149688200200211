<template>
    <div class="separator"></div>
</template>


<script>
export default {
    props:{
        ctrl:Object
    }
}
</script>



<style scoped>
.separator{
    height: 1px;
    background: #ddd;
    margin: 1rem;
    margin-top: 2rem;
}
</style>