const image = {
  key: 'image',
  name: 'Image',
  basic: true,
  description: '',
  value: true,
  options: [
    { key: 'url', type: 'area', value: `${process.env.VUE_APP_FRONT_DOMAIN}/sample.png` },
    //{ key: 'url', type: 'asset', file: 'image', value: `${process.env.VUE_APP_FRONT_DOMAIN}/sample.png` },
    // { key: 'scale', type: 'range', value: 1, min: 0.5, max: 10, step: 0.1 },
    // { key: 'position', type: 'text', value: '', placeholder: 'center' },
    //{ key: 'size', type: 'select', value: '', options: [{ key: 'cover', label: 'Cover', value: 'cover' }, { key: 'contain', label: 'Contain', value: 'contain' }] },
    //{ key: 'filter', type: 'select', value: '', options: [{ key: 'grayscale', label: 'Grayscale', value: 'grayscale()' }, { key: 'sepia', label: 'Sepia', value: 'sepia()' }] }
    // { key: 'noPreload', type: 'checkbox', value: false }
  ]
}

export default image
