import presetFrames from './presetFrames.json'

export default {
  state: {
    autoPlugins: [],
    blockPlugins: [],
    scenePlugins: [],
    presetFrames: presetFrames.frames
  },
  mutations: {
    setAutoPlugins (state, v) {
      v.forEach(d => {
        state.autoPlugins.push(d)
      })
    },
    setBlockPlugins (state, v) {
      v.forEach(d => {
        const b = d.meta
        b.$userDefined = true
        state.blockPlugins.push(b)
      })
    },
    setScenePlugins (state, v) {
      v.forEach(d => {
        this.commit('addScenePlugin', d)
      })
    },
    addScenePlugin (state, d) {
      const s = d.meta
      s.$userDefined = true
      s.$pluginID = d.id
      state.scenePlugins.push(s)
    },
    updateScenePlugin (state, v) {
      const b = v.meta
      const p = state.scenePlugins.find(d => d.id === b.id)
      const idx = state.scenePlugins.indexOf(p)
      state.scenePlugins.splice(idx, 1, b)
    }
  },
  getters: {
    autoPlugins (state) {
      return state.autoPlugins
    },
    blockPlugins (state) {
      return state.blockPlugins
    },
    scenePlugins (state) {
      return state.scenePlugins
    },
    presetFrames (state) {
      return state.presetFrames
    }
  },
  actions: {
    sendPluginMessage (context, ob) {
      const frame = context.state.currentPlugin.querySelector('iframe')
      if (frame && frame.contentWindow) {
        frame.contentWindow.postMessage({
          source: 'peditor.update',
          payload: ob
        }, '*')
      }
    }
  }
}
