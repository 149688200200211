<template>
    <div class="block" :class="{open:show}">

        <CtrlHeader @changed="show=!show" :label="'Preflight Script'" />
        
        <div v-show="show" class="summary">

            <div class="description">Write JS to alter the "query" object, the properties used in template.
                <a href="https://www.presenta.cc/blog/scripting" target="_blank">Learn more...</a>
            </div>

            
            <div class="ctrlcontainer">
                <ScripterCtrl type="script" :ctrl="code" @change="onChange" />                
            </div>

            <!-- <div class="ctrlcontainer">
                <ScripterCtrl :ctrl="header" @change="onChange" />                
            </div> -->

        </div>

    </div>
</template>




<script>
import CtrlHeader from './CtrlHeader.vue'
import AreaCtrl from '../ui/AreaCtrl'
import ScripterCtrl from '../ui/ScripterCtrl'

export default {
    props:{
        root:Object
    },
    data(){
        return{
            show: false,
            header:  {label:'Header', value:'', env:'javascript'},
            code:  {label:'Code', value:'', env:'javascript'}
        }
    },
    mounted(){
        this.update()
    },
    computed:{
        prescript(){
            return this.root.prescript
        }
    },
    watch:{
        prescript(){
            this.update()
        }
    },
    components:{
        AreaCtrl, CtrlHeader, ScripterCtrl
    },
    
    methods:{
        update(){
            if(this.root.prescript){
                this.header.value = this.root.prescript.header
                this.code.value = this.root.prescript.code
            }else{
                this.header.value = ''
                this.code.value = ''
            }
        },
        
        onChange(){
            this.consolidate()
        },
        consolidate(){
            this.root.prescript = {
                header: this.header.value,
                code: this.code.value
            }
            this.$emit('change')
        }
    }
}
</script>



<style scoped>
.block{
    width: 100%;
}
.block:hover{
    background-color: #eee;
}
.block.selecting{
    outline:1px solid #24a1f9;
}
.block.open{
    box-shadow: 0 4px 5px #ccc;
    margin-bottom: 1rem;
    background-color: #eee;
}



.inline{
    padding: 0;
}
.inline >>> p{
    margin: 0;
}
.inline >>> label{
    display: flex;
}

select.inline{
    background: none;
    border: none;
}

.description{
    font-size: .75rem;
    width: 100%;
}

.summary{
    display: flex;
    flex-wrap: wrap;
}
.ctrlcontainer{
    width: 100%;
    display: flex;
}


.summary{
    padding: .5rem;
    background-color: white;
}

</style>
