<template>
    <span class="edittext editablefield"
        contenteditable
        v-text="text"
        @blur="consolidateName"
        @keydown.enter.prevent.stop="endEditing"
        @keydown.stop="" 
        @click.stop=""></span>
</template>



<script>

export default {
    props:{
        source:String
    },
    data(){
        return{
            text:''
        }
    },
    mounted(){
        this.text = this.source
    },
    methods:{
        consolidateName(evt){
            this.$emit('update', evt.target.innerText)
        },
        endEditing(){
            this.$el.blur()
        }
    }
}
</script>



<style scoped>
.editablefield {
  border: 1px dashed #ccc;
  padding: 0.25rem .5rem;
  font-weight: bolder;
  overflow: hidden;
  white-space: nowrap;
}
.editablefield:hover {
  border: 1px dashed #ccc;
  background-color: white;
}
.editablefield:focus {
  background-color: cornsilk;
}
</style>