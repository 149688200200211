const displ = [
  { key: 'show', value: 'true', label: 'show' },
  { key: 'hide', value: 'false', label: 'hide' }
]

export default {
  key: 'barcode',
  name: 'Barcode',
  description: '',
  value: true,
  options: [
    { key: 'content', type: 'text', value: '' },
    { key: 'lineColor', type: 'color', value: 'var(--colorFore)' },
    { key: 'displayValue', type: 'select', options: displ, value: 'true' }
  ]
}
