<template>
    <div class="newslide" :class="{last:isLast}">

        <div class="extraslideconsole">
            
            <button class="grey small withicon"
                    @pointerdown.stop="" 
                    @mousedown.stop=""
                    @mouseup.prevent.stop="addSlideBefore"><img src="@/assets/icons/plusgrey.svg" /></button>
        
            <!-- <v-popover
                @show="initPresetList=true"
                @hide="initPresetList=false">
                
                <button class="grey small withicon"
                    @pointerdown.stop="" 
                    @mousedown.stop=""><img src="@/assets/icons/plusgrey.svg" /></button>
                
                <template slot="popover">
                    <PresetList @added="initPresetList=false" v-if="initPresetList" :index="index" class="inpopover" />
                </template>
                
            </v-popover> -->


        </div>

    </div>
</template>



<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import PresetList from '../PresetList'

export default {
    props:{
        index:Number,
        isLast:Boolean
    },
    components:{
        PresetList
    },
    data(){
        return{
            pover:false,
            initPresetList:false
        }
    },
    computed:{
        ...mapGetters(['slides', 'defaultScene', 'isFileDropping', 'companyId'])
    },
    methods:{
        ...mapMutations(['clearSelection', 'addToSelection', 'addToUpload', 'startUploading', 
            'notifyProjectUpdate', 'notifySlideUpdate']),
        ...mapActions([
            'busAddNewSlideAt',
            'busCreateSlideWithImagesAt'
        ]),
        addSlideBefore(){
            this.clearSelection()
            this.busAddNewSlideAt(this.index)
        }
    }
}
</script>



<style scoped>
.newslide{
    position: absolute;
    width: 24px;
    height: 100%;
    top:0;
    left:-28px;
}

.newslide.last{
    left:calc(100% + 4px);
}

.extraslideconsole{
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.last .extraslideconsole{
  right:0;
}

.extraslideconsole button{
    width: 100%;
    height: 32px;
}

.droppos{
  width: 24px;
  height: 100%;
  pointer-events: none;
}


.droppos.fileDropping{
  pointer-events: all;
}



button{
    padding:.3rem 0;
    background-color: unset;
    opacity: .5;
    box-shadow: none;
}
button:hover{
    opacity: 1;
}
button img{
    width:100%;
}

.inpopover{
    width: 300px;
    height: 220px;
    overflow: hidden;
    overflow-y: auto;
}
</style>