
export default {
  state: {
    history: []
  },
  mutations: {
    pushHistory (state, v) {
      state.history.push(v)
    },
    popHistory (state) {
      state.history.pop()
    }
  },
  getters: {
    current (state) {
      const idx = state.history.length - 1
      return state.history[idx]
    }
  }
}
