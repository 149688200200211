<template>
    <div class="export"
        :class="{done:done, error:inerr}" >
        <div class="mmodal">
            <div class="dismiss"  @click="dismiss" v-if="done || inerr">X</div>
            <div class="mwrapper">
                <p v-if="!done && !inerr">The file <strong>'{{project.name}}.{{type}}'</strong> is on the way... Please be patient...</p>
                <p v-if="done"><a :href="id" target="_blank">GO</a> The download should start automatically.</p>
                <p v-if="inerr">There's some error with the service. Sorry.</p>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { clone } from '@/libs/stringify'
import axios from 'axios'

export default {
    props:{
      type: String
    },
    data () {
        return {
            done: false,
            inerr: false,
            id:''
        }
    },
    computed:{
        ...mapGetters(['project', 'selection'])
    },
    async mounted(){

      const p = clone(this.project)
      const s = clone(this.selection)

      if(s.length > 0) p.document.scenes = s

      p.exportFileFormat = this.type

      const res1 = await this.exportFile(p)

      const dt = res1.data
      if(dt.error){
        return this.inerr = dt.message
      }

      const fileUrl = dt.url

      console.log(fileUrl)
      this.done = true
      this.id = fileUrl

      return false
      const res2 = await axios({
        method: 'post',
        responseType: 'blob',
        withCredentials: false,
        url: fileUrl
      })

      const url = window.URL.createObjectURL(new Blob([res2.data]))
      const link = document.createElement('a')
      link.href = url //res.data.url
      link.setAttribute('download', `${p.name}.${this.type}`)
      document.body.appendChild(link)
      link.click()
      setTimeout(() => {
        this.done = true
      }, 1000)
      
    },
    methods:{
      ...mapActions(['exportFile']),
      dismiss(){
        if(this.done || this.inerr) this.$emit('dismiss')
      }
    }
}
</script>



<style scoped>
.export {
  position: absolute;
  top:0;
  left:0;
  background-color: #24a1f9;
  width:100%;
  height:100%;
  z-index: 9999;
  color:white;
  font-weight: bold;
  transition: background-color 0.4s;
}

.done{
  background-color: #90ce00;
}
.error{
  background-color: #F4A108;
}

.dismiss{
  position: absolute;
  left:1rem;
  cursor: pointer;
}

.mmodal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mmodal .mwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
}

</style>