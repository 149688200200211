<template>
    <div class="row">
        <p class="desc">
            <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
            <span class="label">{{ctrl.label || ctrl.key}}</span> 
        </p>

        <div class="line">
            
            <select v-model="ctrl.value" @change="$emit('inject', ctrl)">
                <option :value="null"></option>
                <option v-for="opt in ctrl.options" :value="opt.value" :key="opt.key">{{opt.label}}</option>
            </select>
            
        </div>
    </div>
</template>


<script>
export default {
    props:{
        ctrl:Object
    },
    mounted(){
        if(this.ctrl.options) this.$emit('inject', this.ctrl)
    },
    methods:{
        
    }
}
</script>


<style scoped src="./edev.css"></style>