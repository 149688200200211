export default {
  key: 'markup',
  name: 'Markup',
  description: '',
  basic: true,
  value: true,
  options: [
    { key: 'html', placeholder: '<../>', type: 'scripter', env:'html', value: '' },
    { key: 'css', placeholder: '#a{...}', type: 'scripter', env:'css', value: '' }
  ]
}
