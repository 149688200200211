<template>
    <div class="scriptEditor">
        
        <div class="columns">
            <Ace v-model="code" 
                ref="editor"
                @init="editorInit" 
                :lang="type" 
                class="main"
                theme="chrome"></Ace>

            <div class="side">
                <textarea placeholder="Result of the Prompt, it needs to be a JSON format" readonly v-model="jsonResult"></textarea>
            </div>
        </div>
        

        <div class="toolbar">
            <button class="light small" @click="$emit('close')">Discard</button>
            <button :disabled="testing" class="positive small" @click="test">{{ testing ? 'Fetching GPT3' : 'Test Prompt' }}</button>
            <button :disabled="!enableSave" class="alternate small" @click="save">Use this Prompt</button>
        </div>

    </div>
</template>



<script>
import Ace from 'vue2-ace-editor'

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    props:{
        source:String,
        type:String
    },
    components:{
        Ace
    },
    data(){
        return{
            code:'',
            readyToSave:false,
            result:'',
            testing:false
        }
    },
    computed:{
        jsonResult(){
            return this.result ? JSON.stringify(this.result) : ''
        },
        enableSave(){
            return this.readyToSave || this.code === ''
        }
    },
    mounted(){
        let ed = this.$refs.editor.editor
        ed.session.setTabSize(2)
        ed.session.setUseWorker(false) // disable error/warning hints in side bar
        ed.session.setUseWrapMode(true)
        ed.setOptions({
            placeholder: "Enter CSS Code", // doesnt work
            showPrintMargin: false,
            fontSize: "15pt",
            animatedScroll:true,
            showLineNumbers:false,
            showFoldWidgets:false,
            spellChecker:false,
            status:false,
            hideIcons: ['guide', 'preview', 'side-by-side', 'fullscreen'],
            showIcons: ['code', 'underline', 'strikethrough', 'horizontal-rule'],
        })

        this.code = this.source
    },

    methods:{
        ...mapActions(['getGPT3']),
        editorInit(){
            require('brace/ext/language_tools')
            //require('brace/mode/yaml')
            //require('brace/mode/markdown')
            require('brace/mode/javascript')
            require('brace/mode/json')
            require('brace/mode/html')
            require('brace/mode/text')
            require('brace/mode/css')
            require('brace/theme/chrome')
        },
        async test(){
            this.testing = true
            this.readyToSave = false
            this.result = null
            const res = await this.getGPT3({prompt:this.code})
            
            this.readyToSave = !(res.data.notJson)

            this.result = this.readyToSave ? res.data : res.data.result

            this.testing = false
        },
        save(){
            this.$emit('saved', this.code)
            this.$emit('close')
        }
    }
    
    
}
</script>



<style>
.ace_editor{
    font-size:14px;
    border:1px solid #ccc;
}
.ace-chrome .ace_gutter{
    background-color: #eee!important;
    color:#999!important;
}
.ace-chrome .ace_gutter-active-line{
    background-color: #eee!important;
}

.list .slide > .wrapper{
    border:10px solid black;
}
</style>


<style scoped>
.scriptEditor{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.toolbar{
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.toolbar button{
    margin: 0 .3rem;
}


.columns{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.columns > div{
    flex:1;
}

.side{
    width: 100%;
    padding: 1rem;
    background-color: beige;
}
.side textarea{
    width: 100%;
    height: 100%;
    padding: .5rem;
}
</style>