
export default {
  key: 'embed',
  name: 'Embed',
  description: '',
  value: true,
  options: [
    // { key: 'url', placeholder: 'http://...', type: 'text', value: '' },
    { key: 'code', placeholder: '<iframe...', type: 'scripter', env:'html', value: '' },
    //{ key: 'poster', type: 'asset', file: 'image', value: `${process.env.VUE_APP_FRONT_DOMAIN}/sample.png` },
    // { key: 'waitReady', type: 'checkbox', value: false },
    { key: 'overrideMode', type: 'checkbox', value: false }
  ]
}
