<template>
    <ModalContainer @close="unsetModal">
        
        <div class="wrapperInner">

            <div class="ui scrollable">
                <h3>API/URL Test Tool</h3>
                <p> 
                    In this tool you can:
                    <ul>
                        <li>Test the parameters and the generated image.</li>
                        <li>Set the merge-tags for external platforms (such as MailChimp, MailJet). <!--<span><a href="">learn more</a></span>--></li>

                    </ul>
                </p>

                <div class="row" v-for="item in editableBlocks" :key="item.ukey">
                    
                    <p class="innerrow">{{item.key}}</p>
                    <input type="text" :placeholder="item.def"  v-model="item.value" @input="onParamChanged(item)" @keyup.enter="setTestCode(getTestUrl)" />
                    <!-- <input type="checkbox" tabindex="-1" v-model="item.active" /> -->
               </div>

                
            </div>

            <div class="code">

                <div class="outerCode" v-if="showGoShare">
                    <div class="minibar">
                        <div class="left">
                            <ButtonFeedback key="a" class="positive small" :handleClick="() => copyPlainCode(goShareCodeSnippet)" labelA="Copy HTML Snippet" labelB="Snippet Copied! 👍" />
                            <ButtonFeedback class="alternate small" :handleClick="() => copyPlainCode(goShareDirectUrl)" labelA="Copy URL" labelB="Copied! 👍" />
                        </div>
                        <div class="right">
                            <button class="small dark" @click="toggleGoShareSection">Back to API/URL</button>
                        </div>
                    </div>
                    <div class="preview">
                        <APIGoSharePanel />
                    </div>

                </div>

                <div class="outerCode" v-if="!showGoShare">
                    <div v-if="showUrl" ref="urlSrc" class="codearea" @keydown.stop="" @keyup.stop="" v-html="getURLString"></div>
                    
                    <div class="minibar">
                        <div class="left">
                            <ButtonFeedback key="b" class="alternate small" :handleClick="() => copyTestCode(getTestUrl)" labelA="Copy Image URL" labelB="Copied! 👍" />
                            <button class="positive small" @click.prevent="showUrl=!showUrl">{{showUrl ? 'Hide' : 'Show'}} URL</button>
                            <button class="attract small" @click.prevent="setTestCode(getTestUrl)">Render</button>
                            <!-- <div class="jitCheck"><label for="jit" v-if="isPro"><input id="jit" type="checkbox" /> JIT</label></div> -->
                        </div>
                        <div class="right">
                            <button class="small light" @click="toggleGoShareSection" v-tooltip="'Enable and Configure<br /> the Sharing Feature'">GO/SHARE</button>
                            <button class="danger small" @click="unpublish" v-tooltip="'Unpublish this URL to make it private, <br />you can re-publish it again with a different URL'">unpublish URL</button>
                        </div>
                    </div>

                    <div class="preview">
                        <div ref="preview"></div>
                        <div class="message" v-if="loadingPreviewImage">{{loadingPreviewImage}}</div>
                    </div>

                </div>

            </div>

            
        </div>
    </ModalContainer>
</template>


<script>
import axios from 'axios'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import ModalContainer from '@/components/ModalContainer'
import defKeys from '@/libs/defKeys'
import { clone } from '@/libs/stringify'
import copyClipboard from '@/libs/copyClipboard'
import uuid from '@/libs/uuid'
import pluginsMap from '@/libs/def/blocks/plugins'
import APIGoSharePanel from './ApiGoSharePanel.vue'
import ButtonFeedback from '../ButtonFeedback.vue'

const tab = '<span class="tab"></span>'

const esc = (v) => {
    var escape = document.createElement('textarea')
    escape.textContent = v
    return escape.innerHTML
}
const txt = (v) => {
    var escape = document.createElement('div')
    escape.innerHTML = v
    return escape.innerText
}

export default {
    components:{
        ModalContainer, APIGoSharePanel, ButtonFeedback
    },
    data(){
        return{
            apiType: '',
            clonedTemplate:null,
            editableBlocks: [],
            loadingPreviewImage:'Preparing...',
            showGoShare:false,
            showUrl:false
        }
    },
    mounted(){
        if(this.templateAPI.scenes){
            this.apiType = 'project'
            this.cloneProject()
            this.publishProjectAPI()
        }else{
            this.apiType = 'frame'
            this.cloneFrame()
            if(this.isTemplate){
                if(this.isTemplateChanged) {
                    this.update()
                }else{
                    this.setTestCode(this.getTestUrl)
                }
            }else{
                this.publish()
            }
        }

        console.log(this.templateAPI)
    },
    watch:{
        isTemplate(){
            if(this.apiType === 'frame') this.cloneFrame()
            if(this.apiType === 'project') this.cloneProject()
        }
    },
    computed:{
        ...mapGetters(['templateAPI', 'companyId', 'document', 'company', 'project']),
        baseURL(){
            return process.env.VUE_APP_RENDERER
        },
        
        getTestUrl(){
            let u = txt(this.getURLString)
            u = u.replace('?&', '?')
            //u = u.replace('/url/', '/jit/')
            return u
        },
        isTemplate(){
            const k = this.apiType === 'frame' ? 'pTemplateID' : 'pProjectID'
            return this.templateAPI[k]
        },
        isTemplateChanged(){
             const k = this.apiType === 'frame' ? 'pFrameAPIChanged' : 'pProjectAPIChanged'
           return this.templateAPI.pFrameAPIChanged
        },
        
        getURLString(){
            if(!this.clonedTemplate) return ''
            const blocks = this.editableBlocks
            let blocksStr = ''
            blocks.forEach((b,i) => {
                if(b.active){
                    const v = b.value
                    const ev = esc(v).replace(/ /g, '+') //encodeURIComponent(esc(v)).replace(/%20/g, '+')
                    blocksStr += `<br />${tab}&<span class="key">${b.key}</span>=<span class="value">${ev}</span>`
                }
            })
            
            const key = this.apiType === 'frame' ? 'pTemplateID' : 'pProjectID'
            const s = `<span class="base">${this.baseURL}/${this.companyId}:${this.clonedTemplate[key]}?${blocksStr}`
            return s
        },
        next(){
            return this.company && this.company.next ? this.company.next : {}
        },

        goShareDirectUrl(){
            const url = this.getTestUrl.replace(this.baseURL + '/', process.env.VUE_APP_GOSHARE_BASE)
            return url
        },
        goShareCodeSnippet(){
            const snippet = `<div class="mcnTextContent">
    <a href="${this.goShareDirectUrl}">
        <img style="display:block;width:100%;" src="${this.getTestUrl}"/>
    </a>
</div>`
            return snippet
        },
        plan(){
            return this.company ? this.company.plan : {}
        },
        isPro(){
            return this.plan.isPro
        }
    },
    methods:{
        ...mapMutations(['setTemplateAPI', 'notifyProjectUpdate']),
        ...mapActions(['publishPlugin', 'unpublishPlugin']),
        cloneProject(){
            const ob = clone(this.templateAPI, true)
            this.clonedTemplate = ob
        },
        cloneFrame(){
            this.editableBlocks = []
            const ob = clone(this.templateAPI, true)

            
            //this.editableBlocks.push({index:blocks.length, defKey:'content', key:'_sample', value: '', active: false, type: 'text'})

            this.clonedTemplate = ob

            console.log('ob.query', ob.query)
            if(ob.query){
                for(let k in ob.query){
                    this.addCustomProp(k, '', ob.query[k]) //encodeURIComponent(ob.query[k])
                }
            }

            if(this.templateAPI.pMergeTags){
                this.templateAPI.pMergeTags.forEach(d => {
                    const g = this.editableBlocks.find(c => d.key === c.key)
                    if(g && d.value) {
                        g.value = d.value
                        g.active = true
                    }
                })
            }

            // temporary, adding image prop
            const blocks = ob.blocks
            blocks.forEach((b,i) => {
                if(b.type === 'image') this.addCustomProp(b.ukey, '', b.url)
            }) 
        },
        addCustomProp(k, v, d){
            const key = k || ''
            const value = v || ''
            const def = d || ''
            const active = v || false
            this.editableBlocks.push({index:this.editableBlocks.length, defKey: '', key, ukey: uuid.block(), value, active, def, type: 'custom'})
        },
        unsetModal(){
            this.notifyProjectUpdate()
            this.setTemplateAPI(null)
        },
        
        onParamChanged(item){
            item.active = item.value
            this.templateAPI.pMergeTags = this.editableBlocks.map(d => ({key:d.key, value:d.value}))
        },

        toggleGoShareSection(){
            this.showGoShare = !this.showGoShare
            if(!this.showGoShare) {
                setTimeout(() => {
                    this.setTestCode(this.getTestUrl)
                })
            }
        },

        copyTestCode(c){
            copyClipboard(c)
        },
        copyPlainCode(c){
            copyClipboard(c)
        },


        async setTestCode(c){
            this.$refs.preview.innerHTML = ''
            this.loadingPreviewImage = 'Generating...'

            // const prps = await axios('https://props.presenta.workers.dev/', {withCredentials: false})
            // const props = prps.data
            // console.log(props)

            const u = txt(c)
            const url = u + '&cachebusting=' + uuid.cache()

            const img = new Image()
            img.onload = () => {
                this.$refs.preview.appendChild(img)
                this.loadingPreviewImage = ''
            }
            img.onerror = () => {
                this.loadingPreviewImage = 'Error'
            }
            img.src = url
        },

        publish(){
            this.templateAPI.pTemplateID = uuid.plugin()
            this.update()
        },

        async unpublish(){
            if(this.apiType === 'frame') this.unpublishFrameAPI()
            if(this.apiType === 'project') this.unpublishProjectAPI()
        },

        async unpublishFrameAPI(){
            if (window.confirm(`Are you sure to unpublish this URL? Once unpublished, the URL won't be available anymore. By re-publishing it, you'll get a new URL.`)) {
                await this.unpublishPlugin({
                    curl: this.companyId,
                    purl: this.templateAPI.pTemplateID
                })
                this.templateAPI.pTemplateID = null
                this.notifyProjectUpdate()
                this.unsetModal()
            }
        },




        async publishProjectAPI(){
            this.templateAPI.pProjectID = this.project.url
            const ob = {
                ...this.project.document,
                curl: this.companyId,
                purl: this.project.url,
                pProjectID: this.project.url
            }

            const plugins = this.getPluginsArray(ob)
            ob.plugins = plugins

            await this.publishPlugin(ob)
            this.notifyProjectUpdate()
            this.document.pProjectAPIChanged = false

            this.setTestCode(this.getTestUrl)
        },

        async unpublishProjectAPI(){
            await this.unpublishPlugin({
                curl: this.companyId,
                purl: this.project.url
            })
            this.document.pProjectID = null
            this.notifyProjectUpdate()
            this.unsetModal()
        },



        getPluginsArray(p){
            const res = []
            p.scenes.forEach(s => {
                s.blocks.forEach(b => {
                    if(pluginsMap[b.type]) res.push({url: pluginsMap[b.type]})
                })
            })
            return res
        },

        async update(){
            const scene = clone(this.templateAPI)

            const readyToPublish = {
                curl: this.companyId,
                purl: scene.pTemplateID,
                controllers:this.document.controllers,
                modules:this.document.modules,
                aspect: this.document.aspect,
                format: this.document.format,
                prescript: this.document.prescript,
                gpt3: this.document.gpt3,
                query: scene.query,
                scenes: [scene],
            }

            const plugins = this.getPluginsArray(readyToPublish)
            readyToPublish.plugins = plugins

            console.log('readyToPublish', readyToPublish)

            this.templateAPI.pFrameAPIChanged = false
            await this.publishPlugin(readyToPublish)
            this.notifyProjectUpdate()

            this.setTestCode(this.getTestUrl)
        }
    }
}
</script>



<style scoped>

.wrapperInner{
    width: 100%;
    height: 100%;
    
    display: flex;
    position: relative;
    padding-top: .75rem;
}

.scrollable{
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

.outerCode{
    padding: 1rem;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.code{
    flex:2;
    background: var(--greybarsback);
    border-left: 1px solid var(--greybarsback);
    border-right: 1px solid var(--greybarsback);
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.ui{
    flex:1;
    padding: .5rem;
    min-width: 300px;
    width: 300px;
}
.ui h3{
    margin: 0;
}

.ui ul{
    margin-top: 0.5rem;
    padding-left: 1.25rem;
}
.ui ul li{
    padding-top: .25rem;
}

.ui li span a{
    font-size: .8rem;
    color: var(--btnBlueColor);
}

.row{
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid #eee;
}
.innerrow{
    display: flex;
    align-items: center;
    width: 100px;
}
.row p{
    margin: 0;
    width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row input[type="text"]{
    width: 100%;
    padding: 0.25rem;
    border: 1px solid #ccc;
}
.row input[type="checkbox"]{
    margin: 0 0.5rem;
}
.innerrow input[type="text"]{
    width: 100px;
}

.row img{
    width: 25px;
}

.minibar{
    display:flex;
}
.minibar button{
    margin-right: .75rem;
}
.minibar .left{
    flex:1;
    display:flex;
}
.minibar .right{
    display:flex;
}
.minibar .right button:last-child{
    margin-right: 0;
}

.preview{
    position: relative;
    margin-top:1rem;
    width: 100%;
    height: 100%;
    flex:1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}
.preview > div{
    width: 100%;
    height: 100%;
}
.preview img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.preview .message{
    border: 1px solid #eee;
    background: rgba(255,255,255,.3);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
}
.codearea{
    padding: 1rem;
    background: #333;
    line-height: 1.5rem;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 10px #999;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
}

.codearea >>> .base{
    color: #999;
}
.codearea >>> .value{
    color: white;
}
.codearea >>> .key {
    color: yellow;
}
.codearea >>> .tab {
    padding: 0 1rem;

}
/* .urlArea >>> .uid {
    color: purple;
    font-weight: 700;
}

.urlArea >>> .value {
    color: red;
} */

.unpubBtn{
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.activate{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.94);
}


.alertPanel{
    width: 100%;
    display: flex;
    background: rgb(200, 0, 0);
    color: white;
    font-size: 1rem;
    user-select: none;
    padding: .5rem;
    margin-bottom: .5rem;
}

.hint{
    background-color: #999;
    border-radius: 50%;
    font-size: 9px;
    width: 14px;
    color:white;
    height: 14px;
    min-width: 14px;
    margin-left: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.jitCheck{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}
</style>