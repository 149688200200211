const upper = (s) => s.charAt(0).toUpperCase() + s.slice(1)

const rblends = [
  'normal',
  'multiply',
  'screen',
  'overlay',
  'darken',
  'lighten',
  'color-dodge',
  'color-burn',
  'hard-light',
  'soft-light',
  'difference',
  'exclusion',
  'hue',
  'saturation',
  'color',
  'luminosity'
]

const cOpt = [
  { key: 'colorBack', value: 'var(--colorBack)', label: 'Background Color' },
  { key: 'colorFore', value: 'var(--colorFore)', label: 'Foreground Color' },
  { key: 'colorAccent', value: 'var(--colorAccent)', label: 'Accent Color' },
  { key: 'colorAlt', value: 'var(--colorAlt)', label: 'Alternate Color' }
]

const oshapes = {
  triangle: 'polygon(50% 0%, 0% 100%, 100% 100%)',
  triangleLeft: 'polygon(100% 0, 0% 100%, 100% 100%)',
  triangleRight: 'polygon(0 0, 0% 100%, 100% 100%)',
  trapezoidRight: 'polygon(50% 0, 100% 0, 100% 100%, 0% 100%)',
  trapezoidLeft: 'polygon(0 0, 50% 0, 100% 100%, 0% 100%)',
  trapezoidLessRight: 'polygon(35% 0, 100% 0, 100% 100%, 0% 100%)',
  trapezoidLessLeft: 'polygon(0 0, 35% 0, 100% 100%, 0% 100%)',
  circle: 'ellipse(50% 50% at 50% 50%)',
  parallelogram: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
  cross: 'polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%)'
}
const shapes = []
for (const k in oshapes) {
  shapes.push({ key: k, value: oshapes[k], label: upper(k) })
}

const blends = rblends.map(d => {
  return { key: d, value: d, label: upper(d) }
})

export default {
  key: 'styles',
  name: 'Styles',
  description: 'Set general styles to the element container:',
  context: 'modules',
  value: false,
  basic:true,
  visible: ['block'],
  options: [
    { key: 'background', w: '50%', label: 'Background', type: 'color', value: '' },
    { key: 'color', w: '50%', label: 'Color', type: 'color', value: '' },
    { key: 'opacity', w: '50%', label: 'Opacity', type: 'text' },
    { key: 'padding', w: '50%', label: 'Padding', type: 'text' },
    { key: 'border', w: '50%', label: 'Border', description: '', type: 'text' },
    { key: 'radius', w: '50%', label: 'Radius', description: '', type: 'text' },
    { key: 'blend', label: 'Blend', type: 'select', nav: true, value: null, options: blends },
    { key: 'shadow', label: 'Shadow', type: 'text', value: null },
    { key: 'clip', label: 'Clip Shape', type: 'select', nav: true, value: null, options: shapes },
    { key: 'svgClip', label: 'SVG Clip', type: 'area', value: null }
  ]
}
