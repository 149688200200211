import emojiFix from 'node-emoji'
import { stringify } from '@/libs/stringify'
import { fixScene } from '@/libs/defaults'

export default {
  fixEmoji (project) {
    project.document.scenes.forEach(s => {
      s.blocks.forEach(b => {
        if (b.type === 'text') {
          b.content = emojiFix.unemojify(b.content)
        }
      })
    })
  },

  fromFrontToBack (project) {
    const clone = JSON.parse(stringify(project))

    clone.document.scenes.forEach(s => {
      delete s.$selected

      s.blocks.forEach(b => {
        if (b.type === 'text') {
          b.content = emojiFix.unemojify(b.content)
        }
        if (b.type === 'gtext') {
          b.type = 'text'
        }
      })
    })
    return clone
  },

  fromBackToFront (project) {
    const doc = project.document
    doc.scenes.forEach(s => {
      fixScene(s)
      s.blocks.forEach(b => {
        if (b.type === 'text') {
          b.content = emojiFix.emojify(b.content)
        }
        if (b.type === 'stext') {
          b.type = 'gtext'
        }
        if (b.type === 'gtext') {
          b.type = 'text'
        }
      })
    })
    return project
  },

  firstSlideOnly (project) {
    const doc = project.document.scenes
    return {
      document: {
        scenes: [doc[0]]
      },
      name: project.name,
      curl: project.curl,
      url: project.url
    }
  }
}
