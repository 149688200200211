import imageUtil from '@/libs/imageUtil'
import { getDefaultScene, cloneAsDefault, fixScene } from '@/libs/defaults'

export default {
  getters: {
    currentSingleSelectedIndex (state, getters, rootState, rootGetters) {
      const selection = rootGetters.selection
      const slides = rootGetters.slides
      const index = selection.length === 1 ? slides.indexOf(selection[0]) : -1
      return index
    },

    getMultipleSelectedToProcess (state, getters, rootState, rootGetters) {
      const a = rootGetters.slides
      const d = rootGetters.selection

      let maxIndex = 0
      let minIndex = a.length
      d.forEach(s => {
        const idx = a.indexOf(s)
        maxIndex = Math.max(maxIndex, idx)
        minIndex = Math.min(minIndex, idx)
      })

      const arr = []

      a.forEach(s => {
        d.forEach(p => {
          if (s === p) arr.push(s)
        })
      })

      return { maxIndex: maxIndex, minIndex: minIndex, arr: arr }
    },

    busGetDocumentInfo (state, getters, rootState, rootGetters) {
      const slides = []
      rootGetters.slides.forEach(s => {
        const textVariant = s.prefs.classes && s.prefs.classes.slidevariant ? s.prefs.classes.slidevariant : 'laya'
        const colorVariant = s.prefs.classes && s.prefs.classes.colorvariant ? s.prefs.classes.colorvariant : 'vara'
        const textLayout = s.prefs.classes && s.prefs.classes.textlayout ? s.prefs.classes.textlayout : 'over'

        let images = null
        if (s.asset) {
          if (s.asset.key === 'eimages') {
            images = s.asset.values.map(d => {
              return { key: d.key }
            })
          }
        }

        slides.push({
          url: s.$url,
          hasText: !!s.text,
          hasAsset: !!s.asset,
          images,
          textVariant,
          colorVariant,
          textLayout,
          isSelected: s.$selected
        })
      })

      return {
        permissions: rootGetters.currentPluginPermissions,
        slides: slides,
        aspectRatio: rootGetters.aspect,
        zoom: rootGetters.zoom
      }
    }
  },
  actions: {
    busDeleteAllSlides (context) {
      context.commit('clearSelection')
      context.commit('updateSlides', [])
    },
    busDeleteSlide (context, s) {
      context.commit('removeFromSelection', s)
      context.dispatch('deleteSlide', s)
      context.commit('notifyProjectUpdate')
    },
    async busSelectNextOrCreateNewSlide (context) {
      const selection = context.rootGetters.selection
      const slides = context.rootGetters.slides
      const len = slides.length
      if (selection.length === 1) {
        const index = slides.indexOf(selection[0])

        if (index === len - 1) {
          context.commit('clearSelection')
          const newSlide = await context.dispatch('busAppendNewSlide')
        } else {
          const nexts = slides[index + 1]
          context.commit('setSelection', nexts)
        }
      }
    },
    busSelectNextSlide (context) {
      const selection = context.rootGetters.selection
      const slides = context.rootGetters.slides
      const len = slides.length
      if (selection.length === 1) {
        const index = slides.indexOf(selection[0])
        if (index < len - 1) {
          const prevs = slides[index + 1]
          context.commit('setSelection', prevs)
        }
      }
    },
    busSelectPrevSlide (context) {
      const selection = context.rootGetters.selection
      const slides = context.rootGetters.slides
      if (selection.length === 1) {
        const index = slides.indexOf(selection[0])
        if (index > 0) {
          const prevs = slides[index - 1]
          context.commit('setSelection', prevs)
        }
      }
    },

    async busDuplicateSlide (context, s) {
      const wasSelected = s.$selected
      context.commit('removeFromSelection', s)
      const newSlide = await context.dispatch('duplicateSlide', s)
      if (wasSelected) context.commit('addToSelection', newSlide)
      context.commit('notifyProjectUpdate')
    },

    getImageSourceFromFiles (context, e) {
      return imageUtil.getImageSourceFromFiles(e)
    },

    busDeleteSelectedSlides (context) {
      context.rootGetters.selection.forEach(s => {
        context.dispatch('deleteSlide', s)
      })
      context.commit('clearSelection')
      context.commit('notifyProjectUpdate')
    },

    busDuplicateSelectedSlides (context) {
      const nextSlides = []
      context.rootGetters.selection.forEach(s => {
        const newSlide = context.dispatch('duplicateSlide', s)
        nextSlides.push(newSlide)
      })

      context.commit('clearSelection')

      Promise.all(nextSlides).then(values => {
        values.forEach(s => {
          context.commit('addToSelection', s)
        })
        context.commit('notifyProjectUpdate')
      })
    },

    busAddNewSlideAt (context, index) {
      const slide = getDefaultScene()
      context.dispatch('addSlideAt', { slide, index })
      context.commit('addToSelection', slide)
      context.commit('notifyProjectUpdate')
      return slide
    },

    busAddNewSlideFromSource (context, ob) {
      fixScene(ob.slide)
      context.commit('clearSelection')
      context.dispatch('addSlideAt', ob)
      context.commit('addToSelection', ob.slide)
      context.commit('notifyProjectUpdate')
    },

    busAppendNewSlide (context) {
      const index = context.rootGetters.slides.length
      return context.dispatch('busAddNewSlideAt', index)
    },

    busAppendSlidesFromImages (context, ob) {
      const imgs = ob.images
      const keys = ob.keys
      imgs.forEach((img, idx) => {
        const slide = getDefaultScene()
        context.commit('addImageToSlide', { slide: s, image: img, key: keys[idx] })
        const index = context.rootGetters.slides.length
        context.dispatch('addSlideAt', { slide, index })
        context.commit('addToSelection', s)
      })
    },

    busCreateSlideWithImagesAt (context, ob) {
      const imgs = ob.images
      const index = ob.index
      const keys = ob.keys
      const slide = getDefaultScene()
      imgs.forEach((img, idx) => {
        context.commit('addImageToSlide', { slide, image: img, key: keys[idx] })
      })
      context.dispatch('addSlideAt', { slide, index })
      context.commit('addToSelection', s)
    },

    busCopySelectedSlides (context) {
      const clone = JSON.stringify(context.rootGetters.selection)
      if (navigator.clipboard) {
        navigator.clipboard.writeText(clone).then(function () {
          console.log('ok')
        }, function () {
          console.log('no')
        })
      }
    },
    busPasteCopiedSlides (context) {
      const curr = context.getters.getMultipleSelectedToProcess
      console.log(curr)
      if (navigator.clipboard) {
        navigator.clipboard.readText().then(src => {
          const clone = JSON.parse(src)
          context.dispatch('appendMultipleSlides', clone)
        })
      }
    },

    busFillSelection (context, s) {
      const selection = context.rootGetters.selection
      const slides = context.rootGetters.slides
      if (selection.length <= 0) return context.commit('setSelection', s)

      // in order to be consistent with the way MultiDrag of VueDraggable works
      // fill selection doesn't mix CTRL and SHIFT selection
      // when pressing SHIFT the last selection will be used as starting point to fill the row

      const first = selection[selection.length - 1]
      const firstIndex = slides.indexOf(first)
      const lastIndex = slides.indexOf(s)
      const start = Math.min(firstIndex, lastIndex)
      const end = Math.max(firstIndex, lastIndex)
      context.commit('clearSelection')
      for (let i = start; i <= end; ++i) {
        const slide = slides[i]
        context.commit('addToSelection', slide)
      }
    }
  }
}
