import ShortUniqueId from 'short-unique-id'
const uuid = new ShortUniqueId()

export default {
  image () {
    return uuid.randomUUID(11)
  },
  client () {
    return uuid.randomUUID(10)
  },
  plugin () {
    return uuid.randomUUID(9)
  },
  project () {
    return uuid.randomUUID(8)
  },
  scene () {
    return uuid.randomUUID(5)
  },
  item () {
    return uuid.randomUUID(4)
  },
  block () {
    return uuid.randomUUID(3)
  },
  frame () {
    return uuid.randomUUID(3)
  },
  cache(){
    return uuid.randomUUID(16)
  }
}
