import uuid from './uuid.js'
import { sid } from './commonProps'
import { clone } from './stringify.js'

const defaultProject = {
  curl: null,
  name: 'Untitled',

  prefs: {
    zoom: 2
  },

  document_type: 'presenta',

  document: {
    pProjectID: null,
    pProjectAPIChanged: null,
    prescript: null,
    gpt3: null,
    aspect: 1.9,
    controllers: {
      keyboard: true,
      arrows: true,
      limitswitch: true,
      fullscreen: true,
      transitions: {
        preset: 'fadeIn'
      },
      swiper: true,
      focus: true
    },
    modules: {
      steps: true,
      enters: {
        transition: 'slideUp',
        stagger: true
      }
    },
    scenes: []
  }
}

const defaultScene = {
  blocks: [],
  modules: {},

  pTemplateID: null,
  pPluginID: null,
  pFrameID: uuid.frame(),
  pFrameAPIChanged: false,

  $selected: false,
  $uid: null,
  $url: null
}

const defaultBlock = {
  type: null,
  aliasType: null,
  modules: {},
  $selected: true,
  $locked: false
}

const fixBlocks = (s) => {
  s.blocks.forEach(b => {
      b.$selected = false
      b.$locked = false
      b.$url = uuid.block()
      if (!b.ukey) b.ukey = b.type
      if (!b.modules) b.modules = {}
  })
}

const fixScene = (s, index) => {
  s.$selected = false
  s.$uid = sid(index, s)
  s.$url = uuid.scene()

  if (!s.blocks) s.blocks = []
  if (!s.modules) s.modules = {}

  fixBlocks(s)

  if (!s.pFrameID) s.pFrameID = uuid.frame()
  if (!s.pTemplateID) s.pTemplateID = null
  if (!s.pFrameAPIChanged) s.pFrameAPIChanged = false

  return s
}

const getDefaultScene = () => {
  return fixScene(clone(defaultScene), 0)
}

const cloneAsDefault = s => {
  const ss = fixScene(clone(s), 0)
  ss.pPluginID = null
  ss.pTemplateID = null
  ss.pFrameID = null
  return ss
}

const getDefaultBlock = () => {
  return clone(defaultBlock)
}

export default { defaultProject, defaultScene }
export { fixScene, fixBlocks, getDefaultScene, getDefaultBlock, cloneAsDefault }
