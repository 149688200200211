import stringifySafe from 'json-stringify-safe'

const replacerStrong = (key, value) => {
  if (key.indexOf('_') === 0 || key.indexOf('$') === 0) {
    return undefined
  }
  return value
}

const replacerLight = (key, value) => {
  if (key.indexOf('_') === 0) {
    return undefined
  }
  return value
}

const stringify = (ob, light) => {
  const replacer = light ? replacerLight : replacerStrong
  return stringifySafe(ob, replacer)
}

const clone = (ob, light) => {
  return JSON.parse(stringify(ob, light))
}

export { stringify, clone }
