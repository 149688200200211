const validTrans = ['fadeIn', 'zoomOut', 'zoomIn', 'slideUp', 'slideDown']
const validModes = ['sequential', 'match']

export default {
  key: 'steps',
  name: 'Steps',
  context: 'modules',
  description: '',
  value: false,
  options: [
    { key: 'selector', label: 'Selector', description: '', type: 'text', value: '.step' },
    { key: 'transition', label: 'Transition', description: '', type: 'select', value: '', options: validTrans.map(o => ({ key: o, label: o, value: o })) },
    { key: 'mode', label: 'Mode', description: '', type: 'select', value: 'sequential', options: validModes.map(o => ({ key: o, label: o, value: o })) },
    { key: 'out', label: 'Out', description: '', type: 'checkbox', value: false }
  ]
}
