const getBase64 = file => {
  const reader = new FileReader()
  return new Promise(resolve => {
    reader.onload = ev => {
      resolve(ev.target.result)
    }
    reader.readAsDataURL(file)
  })
}

const getImageSourceFromFiles = async (_files) => {
  const files = [..._files]
  const images = files.filter(file => file.type.indexOf('image/') >= 0)
  const promises = []
  images.forEach(file => {
    promises.push(getBase64(file))
  })

  return Promise.all(promises)
}

export default { getImageSourceFromFiles }
