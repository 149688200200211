<template>
    <div class="row">

        <div class="colleft">
            <p class="desc">
                <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
                <span class="label" @dblclick="custom=!custom">{{ctrl.label || ctrl.key}}</span>
            </p>
        </div>

        <div class="colright">
            <input v-if="!custom" :min="ctrl.min" :max="ctrl.max" :step="ctrl.step" v-model="v" @input="onChange" type="range" />
            <input v-if="custom" v-model="ctrl.value" @input="onChangeCustom" :placeholder="ctrl.placeholder" />
        </div>
    </div>
</template>


<script>
export default {
    props:{
        ctrl:Object
    },
    data(){
        return{
            v: 0,
            custom: false
        }
    },
    mounted(){
        if(this.ctrl.unit && this.ctrl.value){
            this.v = this.ctrl.value.toString().replace(this.ctrl.unit, '')
        }else{
            this.v = this.ctrl.value
        }
    },
    methods:{
        onChange(){
            this.ctrl.value = this.ctrl.unit ? this.v + this.ctrl.unit : this.v
            this.onChangeCustom()
        },
        onChangeCustom(){
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.$emit('change', this.ctrl)
            }, 200)
        }
    }
}
</script>


<style scoped src="./edev.css"></style>

