<template>
    <div class="row">
        <div class="header">
            <p class="desc">
                <span class="hint" v-if="ctrl.hint" v-tooltip.top="ctrl.hint">?</span>
                <span class="label">{{ctrl.label || ctrl.key}}</span>
                <span class="hint add" @click="addNew">+</span>
            </p>
        </div>
        <div class="body">
            <Draggable @end="sorted" v-model="ctrl.values" v-bind="dragOptions" handle=".handle">
                    <div class="listitem" v-for="c in ctrl.values" :key="c.key">
                        <div class="edit">
                            <div class="hint draggable handle"><img src="@/assets/icons/drag.svg" /></div>
                            <div class="hint remove" @click="remove(c)">X</div>
                        </div>
                        <component 
                            :is="c.type + '-ctrl'" 
                            :ctrl="c"
                            @change="$emit('change', ctrl)" />
                    </div>
            </Draggable>
        </div>
    </div>
</template>

<script>
import uuid from '@/libs/uuid'
import Draggable from 'vuedraggable'
import { clone } from '@/libs/stringify'

export default {
    components:{
        Draggable
    },
    props:{
        ctrl:Object
    },
    computed:{
        dragOptions() {
            return {
                animation: 100,
                group: this.ctrl.key,
                disabled: false,
                ghostClass: 'ghost',
                dragClass:'dragging',
                direction: 'vertical'
            }
        }
    },
    methods:{
        addNew(){
            console.log(this.ctrl)
            let cloned = clone(this.ctrl.template)
            cloned.key = uuid.item()

            this.ctrl.values.push(cloned)

            if(cloned.controls){
                cloned.controls.forEach(c => {
                    c.key = uuid.item()
                })
            }

            this.$emit('change', this.ctrl)
        },
        remove(c){
            let ls = this.ctrl.values
            let idx = ls.indexOf(c)
            ls.splice(idx, 1)
            this.$emit('change', this.ctrl)
        },
        sorted(){
            this.$emit('change', this.ctrl)
        }
    }
}
</script>

<style scoped>
.header{
    display: flex;
    justify-content: space-between;
}

.listitem{
    display: flex;
}
</style>
<style scoped src="./edev.css"></style>