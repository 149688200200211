<template>
    <div class="outer">
        <div class="pluginContent">
            <div class="panel inner">
                <PluginContainer :plugin="{url:autoUrl, selected:true}" />
            </div>
        </div>
    </div>
</template>


<script>
import PluginContainer from './PluginContainer'

export default {
    components:{
        PluginContainer
    },
    computed:{
        autoUrl(){
            return process.env.VUE_APP_AUTOAPP_URL
        }
    },
    methods:{
    }
}
</script>




<style scoped>
.outer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
}
.pluginContent{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.inner{
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.panel{
    position: absolute;
    width: 100%;
    height: 100%;
}

.inner p{
    font-size: .8rem;
    margin: 0.2rem 0;
    text-align: left;
}

.launcher{
    line-height: 1.5rem;
}
</style>