<template>
    <div class="row">

        <div class="colleft">
            <p class="desc">
                <span v-if="!noLabel" class="label">{{ctrl.label || ctrl.key}}</span>
                <span class="hint" v-if="ctrl.hint" v-tooltip.left="ctrl.hint">?</span> 
            </p>
        </div>


        <div class="colright">
            <label>
                <input type="checkbox" v-model="ctrl.value" @change="$emit('change', ctrl)" />
            </label>
        </div>
        
    </div>
</template>


<script>
export default {
    props:{
        ctrl:Object,
        noLabel:Boolean
    }
}
</script>


<style scoped src="./edev.css"></style>