export default {
  state: {
    user: null,
    companyId: null,
    primaryCompanyId: null
  },
  mutations: {
    setUser (state, ob) {
      state.user = ob
      if (!ob) return
      const primaryCompany = ob.companies.find(c => c.primary)
      if (primaryCompany) {
        // this.commit('setCompanyId', primaryCompany.url)
        state.primaryCompanyId = primaryCompany.url
      }
    },
    setCompanyId (state, v) {
      state.companyId = v
    },
    emptyUser (state) {
      this.commit('setUser', null)
      this.commit('setCompanyId', null)
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    companyId (state) {
      return state.companyId
    },
    primaryCompanyId (state) {
      return state.primaryCompanyId
    },
    gravatar (state) {
      return state.user ? `${process.env.VUE_APP_IMAGE_CDN_BASE}${process.env.VUE_APP_IMAGE_CDN}/${state.primaryCompanyId}/__userprofilepicture__.png?w=100` : null
    }
  }
}
