import coords from './modules/coords'
import styles from './modules/styles'
import css from './modules/css'
import showif from './modules/showif'
import enters from './modules/enters'
import steps from './modules/steps'
import autoplay from './modules/autoplay'
import pagenum from './modules/pagenum'
import reveal from './modules/reveal'

import moreSettings from './modules/moreSettings'

export default [
  // colors,
  coords,
  styles,
  // enters,
  // steps,
  // autoplay,
  // pagenum,
  // reveal,
  //css
  // moreSettings
  // showif
]
