<template>
    <div class="block" :class="{open:show}">

        <CtrlHeader :openOnInit="false" @changed="show=!show" :label="'Default values'" />

        <div v-show="show" class="summary">

            <div class="description">Optional implicit value for parameters that are missing or without explicit value:</div>
            <div class="description">Default values are visible only in the rendered image, open the API-URL panel to see them in action.</div>

            <div v-for="p in params" :key="p.key" class="row">

                <div class="colleft">
                    <p class="desc">
                        <span>{{p.name}}</span>
                    </p>
                </div>
                <div class="colright">
                    <textarea v-model="p.val" @change="onChange" rows="1"></textarea>
                </div>
                
            </div>

            <div v-if="params.length === 0">No explicit parameters found.</div>

        </div>

    </div>
</template>




<script>
import CtrlHeader from './CtrlHeader.vue'
import AreaCtrl from '../ui/AreaCtrl'
import uuid from '@/libs/uuid'

export default {
    props:{
        root:Object
    },
    data(){
        return{
            show: false,
            params:[]
        }
    },
    mounted(){
        this.update()
    },
    computed:{
        query(){
            return this.root.query
        }
    },
    watch:{
        query(){
            this.update()
        }
    },
    components:{
        AreaCtrl, CtrlHeader
    },
    
    methods:{
        update(){
            this.params = []
            if(this.root.query){
                for(var k in this.root.query){
                    const v = this.root.query[k]
                    this.params.push({key: uuid.item(), name:k, val:v})
                }
            }
        },
        
        onChange(){
            this.consolidate()
        },
        consolidate(){
            
            try{
                const cd = {}
                this.params.forEach(d => {
                    cd[d.name] = d.val
                })
                this.root.query = cd
                this.$emit('change')
            }catch(e){
                console.log('no valid code')
            }
        }
    }
}
</script>


<style scoped src="../ui/edev.css"></style>
<style scoped>
.block{
    width: 100%;
}
.block:hover{
    background-color: #eee;
}
.block.selecting{
    outline:1px solid #24a1f9;
}
.block.open{
    box-shadow: 0 4px 5px #ccc;
    margin-bottom: 1rem;
    background-color: #eee;
}

.colright textarea{
    width: 100%;
    resize: vertical;
}

.inline{
    padding: 0;
}
.inline >>> p{
    margin: 0;
}
.inline >>> label{
    display: flex;
}

select.inline{
    background: none;
    border: none;
}

.description{
    font-size: .75rem;
    width: 100%;
    margin-bottom: .5rem;
}

.summary{
    display: flex;
    flex-direction: column;
}
.ctrlcontainer{
    width: 100%;
    display: flex;
}


.summary{
    padding: .5rem;
    background-color: white;
}


</style>
