const transitions = ['fadeIn', 'hSlide', 'vSlide', 'slideOver']

export default {
  key: 'transitions',
  name: 'Frame Transition',
  context: 'controllers',
  value: false,
  description: 'Frame Transitions are visible on shares projects.',
  options: [
    { key: 'preset', label: 'Transition', description: '', type: 'select', value: '', nav: true, options: transitions.map(d => ({ key: d, label: d, value: d })) }
  ]
}
