<template>
  <div class="view">
      <div class="modal">

        <div class="wrapper">
            
            <div class="inner" v-if="status === 0">
            <h3>Forgot the password?</h3>
            <p>No prob! Get the link to reset and set a brand new password:</p>

            <div class="field">
                <p>Email:</p>
                <input :class="usernameClass" @blur="checkUsernameField" type="email" v-model="username" placeholder="your email" @keyup.enter="submit" />
            </div>

            <div class="field" v-if="message">
                <p class="alert">{{message}}</p>
            </div>

            <div class="field">
                <button @click.prevent="submit" :disabled="noPass()">Go ahead!</button>
            </div>

            <div class="field">
                <p><router-link :to="{path:'login', query:{username: username}}">Na! Bring me back</router-link></p>
            </div>
            </div>

            <div class="inner" v-if="status === 1">
            <h3>Great!</h3>
            <p>Now check your email.</p>
            </div>

        </div>

        </div>
  </div>
</template>

<script>
import extract from '@/libs/extract'
import {mapActions} from 'vuex'

export default {
  data: function () {
    return {
      username: '',
      usernameClass: '',
      status: 0,
      message: null,
      submitting: false
    }
  },
  mounted () {
    this.username = this.$route.query.username || ''
  },
  methods: {
    ...mapActions(['forgot']),
    async submit () {
      if (!this.noPass()) {
        this.submitting = true
        var res = await this.forgot({ username: this.username.toLowerCase() })
        if(!res.error){
          this.status = 1
        }else{
          this.message = res.message
        }
        this.submitting = false
      }
    },
    noPass () {
      var res = !(extract.email(this.username) && !this.submitting)
      return res
    },
    back (msg) {
      this.$router.go(-1)
    },
    checkUsernameField () {
      this.usernameClass = (extract.email(this.username)) ? 'valid' : 'invalid'
    }
  }
}
</script>
