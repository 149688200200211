<template>
    <div class="outwrapped" :class="{slideSelected:selected}" >
        <div class="wrapped" @paste="onPaste">
            <div class="inwrapped"
                @mousedown.stop=""
                @mouseup="onSetSelection($event)">
                <VuePresentaScene v-if="renderContent"
                    ref="presenta" 
                    @pscene="onPSceneRef"
                    :index="index" 
                    :key="slide.$uid" 
                    :document="document" />
                <FabricViewer
                    :key="fabricUID" 
                    v-if="renderContent && isSingleSelection && selected && fabricUID" 
                    :index="index"
                    :pscene="pScene" />

                <!-- <FabricEditor
                    :key="fabricUID" 
                    v-if="renderContent && isSingleSelection && selected && fabricUID" 
                    :index="index"
                    :pscene="pScene"
                    @consolidate="consolidate" /> -->
                <!-- <div class="dragHandle"></div> -->
                <!-- <div @mousedown.stop="" @pointerdown.stop="" v-tooltip="getTipString" @click="openTemplateAPI" class="mono templateMarker" :class="{apiActive:isTemplate, apiChanged:frameAPIChanged}">{{getBadgeLabel}}</div>
                <div v-if="isProjectApi" @mousedown.stop="" @pointerdown.stop="" class="mono frameMarker">{{pFrameID}}</div> -->
            </div>

            <!-- <AddSlide class="paddslide" :index="index" />
            <AddSlide class="paddslide" :isLast="true" v-if="islast" /> -->

        </div>

    </div>
</template>


<script>
import axios from 'axios'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import uuid from '@/libs/uuid'

import AddSlide from './AddSlide'
import VuePresentaScene from './VuePresentaScene'
import FabricEditor from './FabricEditor'
import FabricViewer from './FabricViewer'


export default {
    props:{
        slide:Object,
        index:Number,
        islast:Boolean
    },
    data(){
        return{
            toolVisibility:false,
            scene:null,
            pScene:null,
            fabricUID:null,
            observer:null,
            renderContent:true
        }
    },
    watch:{
        selected(v){
            if(!v){
                this.setSelectedBlockIndexFromFabric(-1)
            }
        }
    },
    mounted(){
        // const options = {
        //     root: document.querySelector("#scenesContainerWrapper"),
        //     rootMargin: "0px",
        //     threshold: 1.0
        // }
        // this.observer = new IntersectionObserver(([entry]) => {
        //     //this.renderContent = entry.isIntersecting
        // })
        // this.observer.observe(this.$el)
    },
    beforeDestroy(){
        //this.observer.disconnect()
    },
    computed:{
        ...mapGetters(['project', 'document', 'isSingleSelection', 'isDrawering', 'isTouchMode', 'companyId']),
        selected(v){
            return this.slide.$selected
        },
        isTemplate(){
            return this.slide.pTemplateID
        },
        hasProjectID(){
            return this.document.pProjectID
        },
        pFrameID(){
            return this.slide.pFrameID
        },
        frameAPIChanged(){
            return this.isTemplate && this.slide.pFrameAPIChanged
        },
        getTipString(){
            let s = this.isTemplate ? 'View & Test' : 'Activate & Test'
            if(this.frameAPIChanged) s = 'Update & Test'
            return s
        },
        isUniquelySelected(){
            return this.selected && this.isSingleSelection
        },
        getBadgeLabel(){
            let s = 'Frame API'
            if(this.frameAPIChanged) s = 'Update Frame API!'
            return s
        },
        isProjectApi(){
            return this.document ? this.document.pProjectID : false
        }
    },
    components:{
        AddSlide,
        VuePresentaScene,
        FabricEditor,
        FabricViewer
    },
    methods:{
        ...mapActions(['busFillSelection']),
        ...mapMutations(['setSelection', 'addToSelection', 
            'addImageToSlide', 'notifySlideUpdate', 'notifyProjectUpdate', 'addToUpload', 'startUploading',
            'setSelectedBlockIndexFromFabric',
            'setTemplateAPI']),
        
        consolidate(){
            const iblocks = this.slide.blocks
            const fblocks = this.pScene.config.scenes[this.index].blocks
            iblocks.forEach((b,i) => {
                b.modules.coords = fblocks[i].modules.coords
            })
            this.notifySlideUpdate(this.slide)
        },

        openTemplateAPI(){
            this.setTemplateAPI(this.slide)
        },

        

        onSetSelection(e){

            if(this.selected) return false

            if(this.isDrawering) return false
            
            if(e.shiftKey){
                return this.busFillSelection(this.slide)
            }
            if(e.ctrlKey){
                return this.addToSelection(this.slide)
            }
            
            this.setSelection(this.slide)
        },
        loadIncomingImages(){
            var arrprom = []
            let data = []
            this.incomingAssets.forEach(img => {
                data.push({url: img.url, name:uuid.image()})
                arrprom.push(axios.get(img.url, {withCredentials: false, responseType: 'blob'}))
            })
            

            axios.all(arrprom)
                .then(res => {
                    this.slide.incomingAssets = null
                    
                    data.forEach((d, i) => {
                        d.blob = res[i].data
                        var blob = d.blob
                        blob.lastModifiedDate = new Date()
                        blob.name = d.name

                        let key = uuid.image()
                        this.addImageToSlide({ slide: this.slide, image:d.url, key:key })
                        this.addToUpload({file:blob, curl:this.companyId, key:key, onSuccess:this.fixture, onError:null})
                    })
                    this.startUploading()
                })
        },

        onImagesReady(ob){
            let imgs = ob.src
            imgs.forEach((img, index) => {
                let key = uuid.image()
                this.addImageToSlide({ slide: this.slide, image:img, key:key })

                let f = ob.files[index]
                this.addToUpload({file:f, curl:this.companyId, key:key, onSuccess:this.fixture, onError:null})
            })
            
            this.startUploading()
        },
        fixture(res, file){
            let url = res.data.url
            this.asset.values.forEach(im => {
                if(im.key === file.key) {
                    im.url = url
                }
            })
            this.notifyProjectUpdate()
        },


        onPSceneRef(s){
            this.pScene = s

            // we update the fabric uid to trigger the update of FabricEditor
            // only after the new Presenta Scene has been returned by the promise
            this.fabricUID = this.slide.$url + '-' + this.slide.$uid
        },

        onPaste(e){
            console.log('p', e)
            const v = e.clipboardData.getData('text/plain')
            console.log(v)
        }

    }
}
</script>


<style scoped>

.outwrapped{
    padding: 16px;
    padding-bottom: 32px;
    position: relative;
    pointer-events: none;
}


.wrapped{
    width:100%;
    height: 100%;
    position: relative;
    pointer-events: all;
}



.inwrapped{
    box-shadow: 0 0 10px rgba(0,0,0,.15);
    box-shadow: 0 1px 4px rgba(0,0,0,0.15), 
              0 2px 2px rgba(0,0,0,0.15), 
              0 4px 4px rgba(0,0,0,0.15), 
              0 8px 8px rgba(0,0,0,0.15);

    position: relative;

    /* aspect-ratio: 16 / 10; */
}

.templateMarker{
    position: absolute;
    left: 0;
    bottom:-20px;
    background: #ccc;
    color: #fff;
    padding: .15rem .35rem;
    border-radius: 9px;
    font-size: 0.6rem;
    display: flex;
    align-items: center;
    cursor: initial;
    cursor: pointer;
}

.apiActive{
    background: var(--btnGreenColor);
}
.apiChanged{
    background: var(--btnOrangeColor);
}

.frameMarker{
    position: absolute;
    right: 0;
    top:-20px;
    background: #eee;
    color: #333;
    padding: .15rem .35rem;
    border-radius: 9px;
    font-size: 0.6rem;
    display: flex;
    align-items: center;
    cursor: initial;
}

.dragHandle{
    width:100%;
    top:-18px;
    left:0;
    position: absolute;
    height: 13px;
    cursor: move;
}
.dragHandle:hover{
    background: var(--greybarsback);
}


.ptool{
    position: absolute;
    z-index: 2;

    /* width: calc(100% + 16px);
    height: calc(100% + 16px);
    left:-8px;
    top:-8px; */

    width: 100%;
    height: 100%;
    top:0;
    left:0;

    /* display: none; */
}


</style>


<style>
.gHasSelection .wrapped{
    opacity: .5;
}

.gHasSelection .slideSelected .wrapped{
    opacity: 1;
    outline:5px solid var(--borderselectedcolor); 
    /*rgb(255, 217, 0);*/
}

.gDrawering .paddslide{
    visibility: hidden;
}

.gIsDragging .paddslide,
.gIsDragging .ptool{
    visibility: hidden;
}
.ghost{
    opacity: 0;
}

/* .dragHandle{
    display: none;
}
.gIsMobile .dragHandle{
    display: block;
} */

.sortable-chosen .ptool,
.sortable-chosen .paddslide {
    visibility: hidden;
    /* background-color: rgba(255,0,0,.5); */
}

</style>