import axios from 'axios'
axios.defaults.withCredentials = true
const base = process.env.VUE_APP_API_BACKEND_URL

export default {
  actions: {
    checkLogin (context) {
      return axios.get(`${base}/session`)
    },
    login (context, ob) {
      return axios.post(`${base}/session/login`, ob)
    },
    logout (context) {
      return axios.post(`${base}/session/logout`)
    },
    signup (context, ob) {
      return axios.post(`${base}/session/signup`, ob)
    },
    forgot (context, ob) {
      return axios.post(`${base}/session/forgot`, ob)
    },
    resend (context) {
      return axios.post(`${base}/session/resend`)
    },
    confirmEmail (context, ob) {
      return axios.post(`${base}/session/confirm`, ob)
    },
    resetPassword (context, ob) {
      return axios.post(`${base}/session/reset`, ob)
    },

    getCompany (context, ob) {
      return axios.get(`${base}/companies/${ob}`)
    },

    exportFile (context, ob) {
      return axios.post(`${base}/exports/job`, ob)
    },

    getProject (context, ob) {
      return axios.get(`${base}/projects/${ob}`)
    },
    saveProject (context, ob) {
      return axios.post(`${base}/projects/save`, ob)
    },
    createProject (context, ob) {
      return axios.post(`${base}/projects/new`, ob)
    },
    deleteProject (context, ob) {
      return axios.post(`${base}/projects/delete`, ob)
    },
    duplicateProject (context, ob) {
      return axios.post(`${base}/projects/duplicate`, ob)
    },
    publishProject (context, ob) {
      return axios.post(`${base}/projects/publish`, ob)
    },
    unpublishProject (context, ob) {
      return axios.post(`${base}/projects/unpublish`, ob)
    },

    addFeedback (context, ob) {
      return axios.post(`${base}/feedback/add`, ob)
    },

    updateProjectCover (context, ob) {
      return axios.post(`${base}/projects/cover`, ob)
    },

    addPlugin (context, ob) {
      return axios.post(`${base}/plugins/add`, ob)
    },
    updatePlugin (context, ob) {
      return axios.post(`${base}/plugins/update`, ob)
    },
    removePlugin (context, ob) {
      return axios.post(`${base}/plugins/delete`, ob)
    },
    getPlugins (context, ob) {
      return axios.get(`${base}/plugins/${ob}`)
    },
    publishPlugin (context, ob) {
      return axios.post(`${base}/plugins/publish`, ob)
    },
    unpublishPlugin (context, ob) {
      return axios.post(`${base}/plugins/unpublish`, ob)
    },

    savePluginState (context, ob) {
      return axios.post(`${base}/plugins/meta/`, ob)
    },
    getPluginState (context, ob) {
      return axios.get(`${base}/plugins/meta/${ob}`)
    },

    getTemplate (context, id) {
      return axios.post(`${base}/plugins/template/`, id)
    },

    getAssets (context, ob) {
      return axios.get(`${base}/assets/${ob}`)
    },

    addCard (context, ob) {
      return axios.post(`${base}/merchant/card/`, ob)
    },

    stats (context, curl) {
      return axios.get(`${base}/stats/${curl}`)
    },

    getSharer (context, id) {
      return axios.get(`${base}/goshare/${id}`)
    },
    putSharer (context, ob) {
      return axios.post(`${base}/goshare`, ob)
    },

    addCoupon (context, ob){
      return axios.post(`${base}/companies/coupon`, ob)
    },



    getGPT3 (context, ob) {
      return axios.post(`${base}/externals/gpt3`, ob)
    },



    getConnect (context, id) {
      return axios.get(`${base}/scheduler/connect/${id}`)
    },
    putConnect (context, ob) {
      return axios.post(`${base}/scheduler/connect`, ob)
    },
    delConnect (context, ob) {
      return axios.post(`${base}/scheduler/unconnect`, ob)
    },
    testConnect (context, ob) {
      return axios.post(`${base}/scheduler/test`, ob)
    },

    
    getStatus (context, ob) {
      return axios.get(`${base}/scheduler/status/${ob.curl}/${ob.purl}`)
    },
    setStatus (context, ob) {
      return axios.post(`${base}/scheduler/status`, ob)
    },

    getSettings (context, ob) {
      return axios.get(`${base}/scheduler/settings/${ob.curl}/${ob.purl}`)
    },
    setSettings (context, ob) {
      return axios.post(`${base}/scheduler/settings`, ob)
    },
    

  }
}
