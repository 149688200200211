import Vue from 'vue'

export default {
  state: {
    assets: {}
  },
  mutations: {
    setAssets (state, ob) {
      Vue.set(state.assets, ob.key, ob.list)
    },
    unsetAssets (state, cid) {
      Vue.set(state.assets, cid, null)
    }
  },
  getters: {
    assets (state) {
      return state.assets
    }
  }
}
