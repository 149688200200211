<template>
    <div class="presetList">

        <div class="thumbCont">

            <div class="blockThumb" v-for="scn in presetFrames"
                :key="scn.id">
                <div class="blockThumbInner">
                    <VuePresentaScene :document="getDocFromScene(scn)" />
                </div>

                <div class="blockActions" v-if="scn.$userDefined">
                    <div class="binner">
                        <div @click="addTemplate(scn)">+</div>
                    </div>
                </div>

                <div class="blockActions" v-if="!scn.$userDefined">
                    <div class="binner" @click="addTemplate(scn)"><div class="adder">+</div></div>
                </div>
            </div>

        </div>
    </div>
</template>




<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import VuePresentaScene from './slide/VuePresentaScene'
import { clone } from '@/libs/stringify'
import { fixScene } from '@/libs/defaults'
import copyClipboard from '@/libs/copyClipboard'

export default {
    props:{
        index:Number
    },
    components:{
        VuePresentaScene
    },
    mounted(){
    },
    data(){
        return{
            scenes: []
        }
    },
    computed:{
        ...mapGetters(['document', 'presetFrames'])
    },
    methods:{
        ...mapMutations(['clearSelection']),
        ...mapActions(['busAddNewSlideFromSource']),
        copyId(scn){
            copyClipboard(scn.id)
        },
        addTemplate(s){
            const ns = clone(s.def)
            fixScene(ns)
            this.clearSelection()
            this.busAddNewSlideFromSource({slide:ns, index:this.index})
            this.$emit('added')
        },
        
        
        getDocFromScene(scn){
            const aspect = this.document.aspect || 1.6
            const fonts = this.document.modules && this.document.modules.fonts ? this.document.modules.fonts : {}
            const colors = this.document.modules && this.document.modules.colors ? this.document.modules.colors : {}
            return {
                aspect,
                scenes: [scn.def],
                controllers: {},
                modules:{
                    fonts: clone(fonts),
                    colors: clone(colors)
                }
            }
        },
    }
}
</script>



<style scoped>

.presetList{

}

.header{
    padding: .5rem;
}

.thumbCont{
    display: flex;
    flex-wrap: wrap;
}
.blockThumb{
    width: 50%;
    padding: .5rem;
    display: flex;
    position: relative;
}
.blockThumbInner:hover{
    background-color: #eee;
}   
.blockThumbInner{
    width: 100%;
    height: 100%;
    background-color: white;
    font-size: .7rem;
    text-align: center;
    pointer-events: none;
    overflow: hidden;
    position: relative;
}
.blockThumbInner span{
    font-size: .65rem;
}

.blockThumb:hover .blockActions{
    display: block;
}
.blockActions{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    padding: .5rem;
    display: none;
}
.blockActions .binner{
    background: rgba(0,0,0,.65);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.blockActions .binner div{
    color:white;
    cursor: pointer;
    font-size: .65rem;
    text-align: center;
}
.blockActions .binner .adder{
    font-size: 2rem;
    padding: 2rem;
}

.field{
    width: 100%;
    border:1px solid #eee;
}
</style>