const fromUItoSettings = (ob, doc) => {
  const key = ob.item.key
  const enabled = ob.item.value
  const opts = ob.item.options

  console.log(doc)

  const mod = ob.item.context ? doc[ob.item.context] : doc

  if (enabled === '__disabled') return mod[key] = false

  if (enabled) {
    if (!mod[key]) mod[key] = {}
    opts.forEach(c => {
      if (c.value) {
        mod[key][c.key] = c.value
      } else if (c.values) {
        if (c.values.length === 0) {
          delete mod[key][c.key]
        } else {
          mod[key][c.key] = c.values.map(m => m.value)
        }
      } else {
        delete mod[key][c.key]
      }
    })
  } else {
    delete mod[key]
  }
}

const fromSettingsToUI = (ctrls, doc) => {
  ctrls.forEach(ui => {
    const k = ui.key
    const cnfg = doc[ui.context][k]
    if (cnfg) {
      ui.value = (cnfg)
      ui.options.forEach(d => {
        d.value = cnfg ? cnfg[d.key] : null
      })
    }
    if (!cnfg && cnfg !== undefined) ui.value = '__disabled'
  })
}

export { fromUItoSettings, fromSettingsToUI }
