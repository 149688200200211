// import gtext from './gtext'
import image from './image'
// import video from './video'
import embed from './embed'
import svg from './svg'
import notfound from './notfound'
// import fitty from './fitty'
// import youtube from './youtube'
import qrcode from './qrcode'
import emoji from './emoji'
import text from './text'
import barcode from './barcode'
import markup from './markup'

export default [
  // gtext,
  text,
  image,
  svg,
  embed,

  // video,
  // youtube,
  qrcode,
  emoji,
  markup,

  barcode,
  // fitty,
  notfound
]
