import list from '@/assets/webfonts.json'

const pList = []
list.items.forEach(f => {
  f.variants.forEach(v => {
    const key = f.family + '_' + v
    const label = f.family + ' ' + v
    pList.push({
      key,
      label,
      value: f.files[v].replace('http://', 'https://')
    })
  })
})

export default {
  key: 'text',
  name: 'Text',
  basic: true,
  props: {
    coords: {
      top: 40, left: 10, width: 80, height: 20
    }
  },
  description: '',
  value: true,
  options: [
    { key: 'autoscale', type: 'checkbox', value: false },

    { key: 'content', type: 'html', value: 'Text' },
    { key: 'scale', type: 'text', value: '48px' },

    { key: 'background', w: '33.33333%', type: 'color', value: null },
    { key: 'color', w: '33.33333%', type: 'color', value: null },
    //{ key: 'accent', w: '33.33333%', type: 'color', value: null },

    { key: 'font', type: 'select', options: pList, value: '' },
    // { key: 'font', type: 'asset', file: 'font', value: '' },

    // { key: 'align', type: 'select', value: '', options: [{ label: 'Left', value: 'left' }, { label: 'Center', value: 'center' }, { label: 'Right', value: 'right' }] },
    // { key: 'horizontal', w: '50%', type: 'select', value: '', options: [{ label: 'Left', value: 'flex-start' }, { label: 'Center', value: 'center' }, { label: 'Right', value: 'flex-end' }] },
    // { key: 'vertical', w: '50%', type: 'select', value: '', options: [{ label: 'Top', value: 'flex-start' }, { label: 'Center', value: 'center' }, { label: 'Bottom', value: 'flex-end' }] },

    // { more: true, key: 'padding', w: '50%', type: 'text' },
    // { more: true, key: 'interline', w: '50%', type: 'text' },
    // { more: true, key: 'spacing', w: '50%', type: 'text' },
    // { more: true, key: 'clamp', w: '50%', type: 'text' },

    // { more: true, key: 'underline', w: '25%', type: 'checkbox', value: false },
    // { more: true, key: 'uppercase', w: '25%', type: 'checkbox', value: false },
    // { more: true, key: 'marked', w: '25%', type: 'checkbox', value: false },

    // { more: true, w: '50%', key: 'borderTop', type: 'text', value: '' },
    // { more: true, w: '50%', key: 'borderRight', type: 'text', value: '' },
    // { more: true, w: '50%', key: 'borderBottom', type: 'text', value: '' },
    // { more: true, w: '50%', key: 'borderLeft', type: 'text', value: '' },
    // { more: true, key: 'borderRadius', type: 'text', value: '' },
    // { more: true, key: 'shadow', type: 'text', value: '' },
    // { more: true, key: 'blend', type: 'text', value: '' }

  ]
}
