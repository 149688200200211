import { render, staticRenderFns } from "./FrameOptions.vue?vue&type=template&id=ea8dd0ac&scoped=true&"
import script from "./FrameOptions.vue?vue&type=script&lang=js&"
export * from "./FrameOptions.vue?vue&type=script&lang=js&"
import style0 from "./FrameOptions.vue?vue&type=style&index=0&id=ea8dd0ac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea8dd0ac",
  null
  
)

export default component.exports