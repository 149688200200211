<template>
    <ModalContainer @close="$emit('close')" w="50%">
        <div class="figma">
            <h2>Import Figma Frame</h2>

            <img src="@/assets/figma-link.png" />

            <div class="field">
                <p>Paste the Figma Frame URL:</p>
                <input ref="inputfield" type="text" placeholder="i.e. https://www.figma.com/file/..." v-model="figmaUrl" @keyup.enter="fetchFigmaDocument" />
            
                <div class="info">
                    <p>Don't know how to begin?</p>
                    <p>1. Use <a href="" @click.prevent="addSample">this Sample</a> to start fast.</p>
                    <p>2. Read this <a target="_blank" href="https://www.presenta.cc/blog/figma-to-presenta-getting-started">quick guide</a> to learn how to create a template in <b>Figma</b> ready for <b>PRESENTA</b>.</p>
                    <p>3. Clone our <a target="_blank" href="https://www.figma.com/community/file/1176431749719792877">open-graph templates</a> to learn from more samples.</p>
                </div>
            </div>

            <div class="field">
                <button :disabled="!figmaUrl" @click="fetchFigmaDocument">Import Figma Frame</button>
            </div>


            <div v-if="loading" class="loading">{{fetchMessage}}</div>
        </div>
    </ModalContainer>
</template>



<script>
import figma2pjson from '@presenta/figma2pjson'
import {clone} from '@/libs/stringify'
import defaults, {fixScene} from '@/libs/defaults'
import { mapGetters, mapMutations } from 'vuex'
import ModalContainer from '@/components/ModalContainer'
import uuid from '@/libs/uuid'
import axios from 'axios'

export default {
    data(){
        return{
            figmaUrl:'',
            figmaDocument:null,
            frameSelected:null,
            loading:false,
            fetchMessage: '',

            refConfig:null,
            imageCount:[],
            imageMap:{},
            imageFound:0
        }
    },
    mounted(){
        setTimeout(() => this.$refs.inputfield.focus())
    },
    components:{
        ModalContainer
    },
    computed:{
        ...mapGetters(['companyId'])
    },
    methods:{
        ...mapMutations(['addToUpload', 'startUploading']),
        async fetchFigmaDocument(){

            if(!this.figmaUrl) return

            this.fetchMessage = 'Fetching (1/4) ... please wait...'

            this.loading = true

            try{

                const url = this.figmaUrl
                const furl = new URL(url)
                const parts = furl.pathname.split('/')
                const figmaId = parts[2]
                const params = furl.searchParams
                const urlFrameId = params.get('node-id')
                const frameId = urlFrameId.replace('-', ':')
                console.log(frameId)
                this.figmaDocument = null

                const doc =  await figma2pjson.init({docId: figmaId, proxyUrl: process.env.VUE_APP_FRONT_FUNCTIONS + '/fg'})
                console.log('doc', doc) 

                this.fetchMessage = 'Analyzing (2/4) ... please wait...'

                const frame = doc.frames.find(d => d.id === frameId)
                
                this.figmaDocument = doc

                if(frame){
                    this.frameSelected = frame
                    await this.convertFigmaFrame()
                }else{
                    this.fetchMessage = 'Frame not found'
                }

            }catch(e){
                console.log('fetchFigmaDoc', e)
                this.fetchMessage = e
            }
        },
        async convertFigmaFrame() {

            const config = await figma2pjson.parse([this.frameSelected])
            figma2pjson.flush()

            this.fetchMessage = 'Preparing (3/4) ... please wait...'

            let p = clone(defaults.defaultProject)
            p.curl = this.companyId

            p.name = this.frameSelected.name
            p.document.aspect = config.aspect
            p.document.scenes = config.scenes
            p.document.format = config.format
            p.document.figmaUrl = this.figmaUrl
            p.document.scenes.forEach(s => fixScene(s))

            

            // get all image urls
            const allImages = []
            const allBlocks = []
            p.document.scenes.forEach(s => {
                s.blocks.forEach(b => {
                    if(b.type === 'image'){
                        b.ikey = uuid.image()
                        allImages.push(axios.get(b.url, {withCredentials: false, responseType: 'blob'}))
                        allBlocks.push(b)
                    }
                })
            })

            if(allImages.length === 0){
                return this.$emit('done', p)
            } 

            this.refConfig = p
            this.imageCount = []
            this.imageMap = {}
            this.imageFound = allImages.length

            // load all images
            const allRes = await Promise.all(allImages)

            this.fetchMessage = 'Finalizing (4/4) ... please wait...'

            // upload all images
            allRes.forEach((b, i) => {
                var blob = b.data
                blob.lastModifiedDate = new Date()
                blob.name = allImages[i]
                this.addToUpload({file:blob, curl:this.companyId, key: allBlocks[i].ikey, onSuccess:this.onImageUploaded, onError:null})
            })

            this.startUploading()
            
            
        },
        onImageUploaded(res, file){
            console.log('onImageUploaded', res, file.key)

            const url = process.env.VUE_APP_IMAGE_CDN_BASE + process.env.VUE_APP_IMAGE_CDN + '/' + res.data.url

            this.imageCount.push(url)
            this.imageMap[file.key] = url
            
            if(this.imageCount.length === this.imageFound){

                const allBlocks = []
                this.refConfig.document.scenes.forEach(s => {
                    const params = []
                    s.blocks.forEach((b,j) => {
                        if(this.imageMap[b.ikey]) {
                            const value = this.imageMap[b.ikey]
                            // const key = 'image' + j
                            // params.push({key, value})
                            // b.url = `{{${key}}}`
                            b.url = value
                        }
                    })
                    //s.query = params.reduce((a, v) => ({ ...a, [v.key]: v.value}), {})
                })

                this.$emit('done', this.refConfig)
            }
            
        },

        addSample(){
            this.figmaUrl = 'https://www.figma.com/file/RJ25FpMLO13YOPFmV2g0qe/FREE-Open-Graph-Social-Preview-Templates?node-id=0%3A24&t=jOxaL99fFwIKB92S-4'
        }
    }
}
</script>




<style scoped>

.figma{
  width: 100%;
}
.figma input{
  width: 100%;
}

h2{
    margin-top: .3rem;
}

img{
    height: initial;
}

.info p{
    font-size: .9rem;
    color: #777;
}
.loading{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}
</style>