export default {
  bind: function (el, binding, vnode) {
    const selector = binding.value
    let bounds = []
    const drawRect = document.createElement('div')
    drawRect.className = 'drawer'
    el.appendChild(drawRect)

    let bbContainer = null

    let drawer

    function startCheck () {
      bounds = []
      const items = el.querySelectorAll(selector)
      items.forEach((e, i) => {
        const b = e.getBoundingClientRect()
        bounds.push(b)
      })
    }

    function check () {
      const ids = []
      drawer = drawRect.getBoundingClientRect()

      bounds.forEach((b, i) => {
        if (intersectRect(drawer, b)) ids.push(i)
      })
      return ids
    }

    function intersectRect (r1, r2) {
      return !(r2.left > r1.right ||
                        r2.right < r1.left ||
                        r2.top > r1.bottom ||
                        r2.bottom < r1.top)
    }

    let initPt
    let lastPt

    function beginRect (pt) {
      drawRect.style.left = pt.x + 'px'
      drawRect.style.top = pt.y + 'px'
      drawRect.style.display = 'block'
    }

    const onDown = function (e) {
      bbContainer = el.getBoundingClientRect()
      initPt = { x: e.clientX - bbContainer.left, y: e.clientY - bbContainer.top }
      beginRect(initPt)
      startCheck()
      if (vnode.componentInstance) {
        vnode.componentInstance.$emit('begindrawer')
      } else {
        vnode.elm.dispatchEvent(new CustomEvent('begindrawer'))
      }
    }

    const onMove = function (e) {
      if (initPt) {
        lastPt = { x: e.clientX - bbContainer.left, y: e.clientY - bbContainer.top }

        const w = Math.abs(lastPt.x - initPt.x)
        const h = Math.abs(lastPt.y - initPt.y)

        const pt = { x: initPt.x, y: initPt.y }
        if (lastPt.x - initPt.x < 0) {
          pt.x = initPt.x - w
        } else {
          pt.x = initPt.x
        }
        if (lastPt.y - initPt.y < 0) {
          pt.y = initPt.y - h
        } else {
          pt.y = initPt.y
        }
        beginRect(pt)

        drawRect.style.width = `${w}px`
        drawRect.style.height = `${h}px`

        const arr = check()

        const obres = { arr: arr, reset: !e.shiftKey }
        if (vnode.componentInstance) {
          vnode.componentInstance.$emit('drawering', { detail: obres })
        } else {
          vnode.elm.dispatchEvent(new CustomEvent('drawering', { detail: obres }))
        }
      }
    }

    const onEnd = function (e) {
      initPt = null
      lastPt = null
      drawRect.style.width = 0
      drawRect.style.height = 0
      drawRect.style.display = 'none'
      if (vnode.componentInstance) {
        vnode.componentInstance.$emit('enddrawer')
      } else {
        vnode.elm.dispatchEvent(new CustomEvent('enddrawer'))
      }
    }

    el.addEventListener('mousedown', onDown)
    el.addEventListener('mousemove', onMove)
    el.addEventListener('mouseup', onEnd)
  }
}
