<template>
    <div class="dropper" :class="{active:thisDragOver}"
        @dragover.prevent="dragOver" 
        @dragleave.prevent="dragLeave"
        @drop.prevent="drop($event)">
    </div>
</template>



<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    computed:{
        ...mapGetters(['isSlideDragging', 'isFileDropping'])
    },
    data(){
        return{
            thisDragOver:false
        }
    },
    methods:{
        ...mapActions(['getImageSourceFromFiles']),
        dragOver(e){
            if(!this.isSlideDragging){
                this.thisDragOver = true
            }
        },
        dragLeave(){
            if(!this.isSlideDragging){
                this.thisDragOver = false
            }
        },
        async drop(e){
            if(!this.isSlideDragging && this.isFileDropping){
                let files = e.dataTransfer.files
                let src = await this.getImageSourceFromFiles(files)
                this.$emit('images', {files:files, src:src})
                this.thisDragOver = false
            }
        }
    }
}
</script>



<style scoped>
.dropper{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background-color: rgba(0,255,255,0);
}

.active{
    background-color: rgba(0,255,255,.2);
    border: 3px dashed blue;
}


</style>