<template>
    <div class="outer">
        <div class="inner">
            <DropAndHandle @images="onDrop" class="droppos" :class="{fileDropping:isFileDropping}"></DropAndHandle>

            <label for="uploadmyfilestocreateslides">
                <p>drop or <span class="link">pick</span> images to create slides</p>
            </label>
            <input type="file" id="uploadmyfilestocreateslides" multiple @change="requestUploadFile" />
            
        </div>

    </div>
</template>




<script>
import DropAndHandle from './DropAndHandle'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import uuid from '@/libs/uuid'

export default {
    components:{
        DropAndHandle
    },
    computed:{
        ...mapGetters(['isFileDropping', 'companyId', 'slides'])
    },
    methods:{
        ...mapActions(['getImageSourceFromFiles', 'busAppendSlidesFromImages']),
        ...mapMutations(['addToUpload', 'startUploading', 'notifyProjectUpdate', 'unsetAssets']),
        async requestUploadFile(){
            var e = this.$el.querySelector('#uploadmyfilestocreateslides')
            let src = await this.getImageSourceFromFiles(e.files)
            this.handleAssets({src:src, files:e.files})
        },
        onDrop(ob){
            this.handleAssets(ob)
        },

        handleAssets(ob){
            console.log('handleAssets', ob)
            let keys = []
            ob.src.forEach(p => keys.push(uuid.image()))
            this.busAppendSlidesFromImages({images:ob.src, keys:keys})

            const files = [...ob.files]
            files.forEach((f, i) => {
                this.addToUpload({file:f, curl: this.companyId, key:keys[i], onSuccess:this.fixture, onError:null})
            })
            this.startUploading()
        },
        fixture(res, file){
            let url = res.data.url
            this.slides.forEach(s => {
                if(s.blocks){
                    s.blocks.forEach(im => {
                        if(im.key === file.key) {
                            im.url = process.env.VUE_APP_IMAGE_CDN_BASE + process.env.VUE_APP_IMAGE_CDN + '/' + url
                            this.notifyProjectUpdate()
                            this.unsetAssets(this.companyId)
                        }
                    })
                }
            })
        }
    }
}
</script>


<style scoped>
.outer{
    padding: 2rem;
}
.inner{
    text-align: center;
    background-color: #fff;
    border:2px dashed #eee;
    color:#999;
    font-size: .8rem;
    position: relative;
}

p{
    padding: 1.5rem;
    margin: 0;
}
input{
    display: none;
}


.droppos{
  pointer-events: none;
}


.droppos.fileDropping{
  pointer-events: all;
}

.link{
    text-decoration: underline;
}
</style>