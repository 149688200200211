<template>
    <div class="block" :class="{open:show}">

        <CtrlHeader @changed="show=!show" :label="'Frame Format'" />

        
        <div v-show="show" class="summary">

            <div class="description">Choose a Frame format preset of specify a custom size.</div>

            <div class="ctrlcontainer">
                <SelectCtrl :ctrl="aspect" @change="onChangeAspect" />                
            </div>

            <div class="ctrlcontainer">
                <TextCtrl :ctrl="width" @change="onChangeSize" />                
                <TextCtrl :ctrl="height" @change="onChangeSize" />    
            </div>

        </div>

    </div>
</template>




<script>
import CtrlHeader from './CtrlHeader.vue'
import SelectCtrl from '../ui/SelectCtrl'
import TextCtrl from '../ui/TextCtrl'

export default {
    props:{
        root:Object
    },
    data(){
        return{
            show: false,
            aspect: { label: 'Preset', options: [
                { key: '1.9', value: '1.9', label: 'Social Card Preview' },
                { key: '1.6', value: '1.6', label: 'Slide 16/10' },
                { key: '1.777777777777', value: '1.777777777777', label: 'Slide 16/9' },
                { key: '1.333333333333', value: '1.333333333333', label: 'Slide 4/3' },
                { key: '1', value: '1', label: 'Squared' },
                { key: '2', value: '2', label: 'Horizontal Banner' },
                { key: '0.7', value: '0.7', label: 'Vertical Banner' }
                ], value: '1.6' },
            width:  {label:'Width', value:null},
            height: {label:'Height', value:null}
        }
    },
    mounted(){
        this.update()
    },
    computed:{
        format(){
            return this.root.format
        }
    },
    watch:{
        format(){
            this.update()
        }
    },
    components:{
        SelectCtrl, TextCtrl, CtrlHeader
    },
    
    methods:{
        update(){
            if(this.root.format){
                console.log('this.root.format', this.root.format)
                this.aspect.value = this.root.format.aspect
                this.width.value = this.root.format.width
                this.height.value = this.root.format.height
            }else{
                this.aspect.value = '1.9'
                this.width.value = null
                this.height.value = null
            }
        },
        onChangeAspect(ctrl){
            this.width.value = null
            this.height.value = null

            this.root.aspect = ctrl.value
            this.consolidate()
        },
        onChangeSize(){
            if(this.width.value && this.height.value){
                this.aspect.value = this.width.value / this.height.value
                this.root.aspect = this.aspect.value
            }
            this.consolidate()
        },
        consolidate(){
            this.root.format = {
                aspect: this.aspect.value,
                width: this.width.value,
                height: this.height.value
            }
            this.$emit('change')
        }
    }
}
</script>



<style scoped>
.block{
    width: 100%;
}
.block:hover{
    background-color: #eee;
}
.block.selecting{
    outline:1px solid #24a1f9;
}
.block.open{
    box-shadow: 0 4px 5px #ccc;
    margin-bottom: 1rem;
    background-color: #eee;
}



.inline{
    padding: 0;
}
.inline >>> p{
    margin: 0;
}
.inline >>> label{
    display: flex;
}

select.inline{
    background: none;
    border: none;
}

.description{
    font-size: .75rem;
    width: 100%;
}

.summary{
    display: flex;
    flex-wrap: wrap;
}
.ctrlcontainer{
    width: 100%;
    display: flex;
}


.summary{
    padding: .5rem;
    background-color: white;
}

</style>
