export default {
  key: 'script',
  name: 'Script',
  description: '',
  context: 'modules',
  value: true,
  options: [
    { key: 'code', type: 'scripter', value: '', env:'javascript' },
    { key: 'header', type: 'scripter', value: '', env:'javascript' },
    { key: 'forceRun', type: 'checkbox', value: false }
  ]
}
