import Vue from 'vue'
import Vuex from 'vuex'

import Api from './api.js'
import User from './user.js'
import Company from './company.js'
import Project from './project.js'
import Selection from './selection.js'
import Editor from './editor.js'
import Slide from './slide.js'
import Upload from './upload.js'
import Bus from './bus.js'
import Plugin from './plugin.js'
import Assets from './assets.js'
import History from './history.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    Api,
    User,
    Company,
    Project,
    Selection,
    Editor,
    Slide,
    Upload,
    Bus,
    Plugin,
    Assets,
    History
  }
})

export default store
