<template>
    <div class="searchComp">
      <input type="text" v-model="searchkey" @input="doSearch" placeholder="Type to search Projects" />
    </div>
</template>


<script>
import lunr from 'lunr'

export default {
    props:{
      projects:Array
    },
    data(){
      return{
        searchkey:'',
        engine: null,
        results:[]
      }
    },
    created(){
      this.buildIndex()
    },
    watch:{
      projects(v){
        this.buildIndex()
      }
    },
    
    methods:{
      buildIndex(){
        let prj = this.projects
        if(prj){
          this.engine = lunr(function () {
            this.ref('url')
            this.field('name')
            prj.forEach(d => {
              this.add(d)
            })
          })
          this.doSearch()
        }
      },
      doSearch(){
        this.results = this.engine.search(this.searchkey)
        this.$emit('resultChanged', this.results)
      }
    }
}
</script>



<style scoped>

.searchComp{
  width: 100%;
}
.searchComp input{
  width: 100%;
  padding: .5rem;
  font-size: .85rem;
  border: 1px solid #ddd;
}

</style>