<template>
    <div class="uploaderItem">
        <div class="progressbar" :style="getProgressWidth"></div>
        <div class="wrapper">
            <div class="label">{{item.name}} ({{getSize}}KB) - {{getProgressLength}}%</div>
            <div v-if="!item.unstop" class="rem" @click="$emit('remove', item)">x</div>
        </div>
    </div>
</template>



<script>
export default {
    props:{
        item:Object
    },
    computed:{
        getProgressLength(){
            let v = (this.item.loaded / this.item.size * 100)
            return parseInt(v)
        },
        getProgressWidth(){
            return {width: this.getProgressLength + '%'}
        },
        getSize(){
            return parseInt(this.item.size / 1000)
        }
    }
}
</script>



<style scoped>

.uploaderItem{
    position: relative;
    height:32px;
    color: white;
    border-bottom: 1px solid rgba(255,255,255, 0.5);
}

.wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0;
    display: flex;
    align-items: center;
    padding: .5rem;
}

.progressbar{
    background-color: blue;
    position: absolute;
    left:0;
    top:0;
    height: 100%;
}
.label{
    flex:1;
}
</style>