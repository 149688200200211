<template>
  <div
    class="workspace"
    @dragstart.stop.prevent="returnFalse"
    @drop.stop.prevent="returnFalse"
    @dragover.stop.prevent="returnFalse">

    <div class="wrapper">

      <div class="left" v-if="user">
        <select v-if="!isSingleCompany" v-model="currentCompany" @change="switchCompany">
          <option disabled>Switch WorkSpace</option>
          <option
              :value="comp.url" :key="comp.url"
              v-for="comp in user.companies">{{getCompanyLabel(comp)}}</option>
          
        </select>

        <div v-if="isSingleCompany">
          {{getCompanyLabel(company)}}
        </div>

      </div>

      <div class="center">
        <SearchBar :projects="projects"
                  @resultChanged="onResultChanged" />
      </div>

      <div class="right">
        <NewProjectBtn @select="$emit('newProject', $event)" />
      </div>

    </div>
  </div>
</template>



<script>
import {mapGetters} from 'vuex'
import SearchBar from './SearchBar'
import NewProjectBtn from './NewProjectBtn'

export default {
  components:{
    SearchBar, NewProjectBtn
  },
  data(){
    return{
      currentCompany:null
    }
  },
  computed: {
    ...mapGetters(['user', 'gravatar', 'companyId', 'company']),
    projects(){
      return this.company ? this.company.projects : []
    },
    isSingleCompany(){
      return this.user.companies.length === 1
    }
  },
  mounted(){
    this.currentCompany = this.$route.params.id || this.companyId
  },
  
  methods: {
    switchCompany(){
      this.$router.push('/projects/' + this.currentCompany)
    },
    returnFalse() {
      return false;
    },
    getCompanyLabel(comp){
      let prim = comp && comp.primary ? '*' : ''
      return `${comp ? comp.name : ''} ${prim}`
    },

    onResultChanged(v){
      this.$emit('resultChanged', v)
    }
  }
};
</script>



<style scoped>
.workspace{
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.left,
.center,
.right{
  display: flex;
  align-items: center;
  width:200px;
}

.center{
  padding: 0 .5rem;
  flex:1;
  width: 100%;
}




.left select{
  width: 100%;
  height: 100%;
  padding: .25rem;
  border: 1px solid #ddd;
}

.left img{
  width: 40px;
}



.wrapper{
  padding: .5rem 1rem;
  display: flex;
  height: 50px;
}


a{
  background-color: #fff;
  text-decoration: none;
  padding: 1rem;
  transition: background-color .25s ease-in-out;
}

.tiny{
  font-size: .8rem;
}



.menu{
  padding: 1rem;
}





.user{
  display: block;
  width: 40px;
  height:40px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  transition: transform .2s ease-in-out;
}
.user img{
  width: 100%;
  height:100%;
}


@media(max-width: 700px){
  .wrapper{
    flex-direction: column;
    height: initial;
    padding: 1rem;
  }
  .left,
  .center,
  .right{
    display: flex;
    align-items: center;
    width:100%;
  }
  .center{
    margin: 0.5rem 0;
    padding: 0;
  }
}
</style>