<template>
  <div class="editheader">
    <div class="wrapper">
      <router-link class="iconlink" to="/projects" v-tooltip="'Back to Projects'">
        <img src="@/assets/icons/back.svg" />
      </router-link>

      <div v-if="project"
        class="editname editablefield"
        contenteditable
        v-text="name"
        @blur="consolidateName"
        @keydown.enter.stop="endEditing"
        @keydown.stop=""
      ></div>

      <div class="savingLabel" v-if="project">{{ savemessages[saveStatusCode] }}</div>

    <div class="btnRun">

      <div v-if="next.show_disable" class="projMarkWrap" @click="openProjectApiPanel" :class="{apiActive:isProjectApi, apiChanged:projectAPIChanged}">
        <div class="mono projectMarker" v-tooltip="getTipString">{{getBadgeLabel}}</div>
      </div>
     

      <v-popover trigger="manual" 
          v-if="next.show_disable"
          :open="shareOpen"
          @hide="shareOpen=false"
          :auto-hide="true">

          <button 
            :disabled="!hasSlides"
            @click="shareOpen=!shareOpen"
            class="withicon alternate">
            Share / Export
          </button>
          
          <template slot="popover">
            <ul class="listmenu">

              <ShareProject :project="project" />
              
              <li class="separator"></li>
              <li @click="onInitExporter('pdf')" :class="{disabled:!isPro}">
                <span>{{getPdfLabel}}</span>
                <span class="badge" v-if="!isPro">PRO</span>
              </li>

              <li @click="onInitExporter('zip')" :class="{disabled:!isPro}">
                <span>{{getPngLabel}}</span>
                <span class="badge" v-if="!isPro">PRO</span>
              </li>
              
              <li @click="onInitExporter('gif')" :class="{disabled:!isPro}">
                <span>{{getGifLabel}}</span>
                <span class="badge" v-if="!isPro">PRO</span>
              </li>
              
                    
            </ul>
            
          </template>
      </v-popover>

    </div>

    </div>

    <Exporter v-if="initExporter" :type="initExporter" @dismiss="initExporter=false" />

  </div>
</template>



<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Exporter from './Exporter'
import ShareProject from './editorHeader/ShareProject.vue'
import { clone, stringify } from '@/libs/stringify'
import LZ from 'lz-string'


export default {
  components:{
    Exporter, ShareProject
  },
  data() {
    return {
      savemessages: [
        "All saved.",
        "Waiting for saving...",
        "Saving...",
        "Not saved."
      ],
      initExporter:false,
      shareOpen:false
    }
  },
  mounted(){
  },
  computed: {
    ...mapGetters(['project', 'prefs', 'saveStatusCode', 'currentIndex',
      'slides', 'selection', 'isSingleSelection', 'companyId', 'document', 'company']),
    name: {
      get() {
        return this.project ? this.project.name : "";
      },
      set(v) {
        if (this.project) {
          this.project.name = v;
        }
      }
    },
    hasSlides() {
      return this.slides && this.slides.length > 0;
    },
    getPdfLabel(){
      return this.selection.length > 0 ? 'Export selected as PDF' : 'Export as PDF'
    },
    getPngLabel(){
      return this.selection.length > 0 ? 'Export selected as PNGs' : 'Export as PNGs'
    },
    getGifLabel(){
      return this.selection.length > 0 ? 'Export selected as GIF' : 'Export as GIF'
    },

    currentSlide(){
      return this.selection[0]
    },
    
    next(){
      return this.company && this.company.next ? this.company.next : {}
    },
    isProjectApi(){
      return this.document ? this.document.pProjectID : false
    },
    projectAPIChanged(){
      return this.isProjectApi && this.document.pProjectAPIChanged
    },
    getBadgeLabel(){
        let s = 'Project API'
        if(this.projectAPIChanged) s = 'Update Project API!'
        return s
    },
    getTipString(){
        let s = this.isProjectApi ? 'View & Test' : 'Activate & Test'
        if(this.projectAPIChanged) s = 'Update & Test'
        return s
    },
    plan(){
      return this.company ? this.company.plan : {}
    },
    isPro(){
      return this.plan.isPro
    }
  },
  methods: {
    ...mapMutations(['notifyProjectUpdate',
      'addScenePlugin', 'updateScenePlugin', 'setTemplateAPI']),
    ...mapActions(['publishPlugin', 'addPlugin', 'updatePlugin']),
    consolidateName(evt) {
      this.name = evt.target.innerText;
      this.notifyProjectUpdate()
    },
    endEditing (evt) {
      this.$el.querySelector('.editname').blur()
    },

    runPresentation () {
      const doc = clone(this.project.document)
      
      doc.pro = true
      
      const aspect = doc.aspect || 1.6
      var h = 960 / aspect
      const zip = LZ.compressToBase64(stringify(doc))

      // var PRESENTA_LOCAL_PREVIEW = document.querySelector('#PRESENTA_LOCAL_PREVIEW').contentWindow
      // PRESENTA_LOCAL_PREVIEW.postMessage(zip, "*")

      // window.localStorage.setItem('PRESENTA_LOCAL_PREVIEW', zip)
      var ref = window.open(`${process.env.VUE_APP_PUBLIC_DOC_BASE_URL}/?lz=${zip}`, 'Preview', `top=0,left=0,width=960,height=${h},menubar=no,location=no,resizable=yes,scrollbars=no,status=no`)
      //var ref = window.open(`${process.env.VUE_APP_PUBLIC_DOC_BASE_URL}/`, 'Preview', `top=0,left=0,width=960,height=${h},menubar=no,location=no,resizable=yes,scrollbars=no,status=no`)
      ref.focus()
    },

    openProjectApiPanel(){
      this.setTemplateAPI(this.document)
    },

    onInitExporter(type){
      this.initExporter = type
      this.shareOpen = false
    },

    getPublicUrl(){
      return process.env.VUE_APP_PUBLIC_DOC_BASE_URL + '/?id=' + this.publicUrl
    }
  }
}
</script>



<style scoped>
.editheader {
  background-color: var(--greybarsback);
  border-bottom: 1px solid #ddd;
  z-index: 999;
  position: relative;
}

 .wrapper {
  padding: .5rem 1rem;
  padding-right: .75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}


.editablefield {
  border: 1px dashed #ccc;
  padding: 0.25rem .5rem;
  font-weight: bolder;
  display: none;
  overflow: hidden;
  white-space: nowrap;
}
.editablefield:hover {
  border: 1px dashed #ccc;
  background-color: white;
}
.editablefield:focus {
  background-color: cornsilk;
}

@media (min-width: 800px) {
  .editablefield {
    display: block;
  }
}

.btnRun {
  display: none;
}

@media (min-width: 800px) {
  .btnRun {
    display: flex;
  }
}

.savingLabel {
  font-size: 0.8rem;
  padding: 0 1rem;
  color: #75a700;
  overflow: hidden;
  white-space: nowrap;
  text-emphasis: emphasis;
  flex:1;
}
.savingLabel {
  transition: opacity 0.5s ease-in-out;
}

.savingLabel.v-enter,
.savingLabel.v-leave-to {
  opacity: 0;
}

.iconlink {
  white-space: nowrap;
  text-emphasis: emphasis;
  display: flex;
  padding: .25rem .25rem;  
  padding-left: 0;
  margin-right: .25rem;
}
.iconlink:hover{
  background-color: #fff;
}
.iconlink img{
  width: 20px;
  height: 20px;
}


.badge{
  background-color: #ccc;
  color: white;
  padding: .1rem .4rem;
  border-radius: 10px;
  font-size: .6rem;
  margin-left: .5rem;
}

.projMarkWrap{
  padding: .2rem .6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projectMarker{
    background: #ccc;
    color: #fff;
    padding: .15rem .35rem;
    border-radius: 9px;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    cursor: initial;
    cursor: pointer;
}
.apiActive .projectMarker{
    background: var(--btnGreenColor);
}
.apiChanged .projectMarker{
    background: var(--btnOrangeColor);
}
</style>