<template>
  <div class="view">
      <div class="modal">

        <div class="wrapper">
          <div class="inner">
            <h3>Log in:</h3>

            <div class="field">
              <p>Email:</p>
              <input :class="usernameClass" @blur="checkUsernameField" type="email" v-model="username" placeholder="your email" />
            </div>

            <div class="field">
              <p>Password (at least 6 chars):</p>
              <input :class="passwordClass" @blur="checkPasswordField" type="password" v-model="password" placeholder="your password" @keyup.enter="submit" />
              <p><router-link :to="{path:'forgot', query:{username: username}}">I forgot the password :(</router-link></p>
            </div>

            <div class="field" v-if="message">
              <p class="alert">{{message}}</p>
            </div>

            <div class="field">
              <button @click.prevent="submit" :disabled="noPass()">{{btnLabel}}</button>
            </div>

            <!-- <div class="field">
              <p><router-link to="/signup">I don't have an account...</router-link></p>
            </div> -->
          </div>
        </div>

      </div>
  </div>
</template>

<script>
import extract from '@/libs/extract'
import {mapActions, mapMutations} from 'vuex'

export default {

  data() {
    return {
      message: null,
      username: '',
      password: '',
      submitting: false,
      btnLabel: 'Let me in!',
      usernameClass: '',
      passwordClass: ''
    }
  },
  watch: {
    message () {
      if (this.message) {
        this.btnLabel = 'Let me in!'
        this.submitting = false
        this.password = ''
      }
    }
  },
  mounted () {
    this.username = this.$route.query.username || ''
  },
  methods: {
    ...mapMutations(['setUser']),
    ...mapActions(['login']),
    async submit() {
      if (!this.noPass()) {
        this.submitting = true
        this.btnLabel = 'Logging ...'
        try{
          let res = await this.login({ username: this.username.toLowerCase(), password: this.password })
          if(res.data && !res.data.error){
            this.setUser(res.data)
            this.$router.push('/projects')
          }else{
            this.message = res.error.message
          }
        }catch(err){
          if (err.response) {
            this.message = err.response.data.message
          } else if (err.request) {
            this.message = err.request
          } else {
            this.message = err.message
          }
        }
        
        this.submitting = false
      }
    },
    noPass() {
      var res = !((extract.email(this.username) && this.password.length > 5 && !this.submitting))
      return res
    },
    checkUsernameField () {
      this.usernameClass = (extract.email(this.username)) ? 'valid' : 'invalid'
    },
    checkPasswordField () {
      this.passwordClass = (this.password.length > 5) ? 'valid' : 'invalid'
    }
  }
}
</script>
