<template>
    <div class="assetsManager">
        

            <div class="adder">
                <label for="uploadmyfilesfromtinyeditor">
                    <p><span class="link">Upload new assets from local</span></p>
                </label>
                <input type="file" id="uploadmyfilesfromtinyeditor" 
                    :accept="accept"
                    multiple @change="requestUploadFile" />

            </div>

            <div v-if="next.show_adminonly">
                <input type="text" v-model="remoteUrl" />
                <button @click="getRemoteAndUpload">get</button>
            </div>

            <Uploader />

            <div class="wrapperGrid">
                <div class="grid" v-if="companyAssets">
                    <div v-for="item in list" :key="item.url">
                        <div @click.prevent="select(item)">
                            <img :src="item.url + '?w=100&h=100'" />
                        </div>
                    </div>
                </div>
            </div>

            <p class="moreopt" @click="limit+=20" v-if="limit<list.length">Show more assets</p>

    </div>
</template>



<script>
import uuid from '@/libs/uuid'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Uploader from './Uploader'
import axios from 'axios'

export default {
    props:{
        type:String
    },
    components:{
        Uploader
    },
    data(){
        return{
            limit: 40,
            panels:[
                {label:'Assets Manager', closable: true, key:'assets', selected: true}
            ],
            remoteUrl:''
        }
    },
    mounted(){
        if(!this.companyAssets) this.fetchAssets()
    },
    computed:{
        ...mapGetters(['companyId', 'assets', 'company']),
        companyAssets(){
            return this.assets[this.companyId]
        },
        list(){
            return this.companyAssets ? this.companyAssets.filter((d,i) => (d.type === this.type) && (i < this.limit)) : []
        },
        accept(){
            let a = 'image/*'
            if(this.type === 'video') a = 'video/*'
            if(this.type === 'svg') a = '.svg'
            if(this.type === 'font') a = '.ttf'
            return a
        },
        next(){
            return this.company && this.company.next ? this.company.next : {}
        }
    },
    methods:{
        ...mapActions(['getAssets', 'getImageSourceFromFiles']),
        ...mapMutations(['setAssets', 'addToUpload', 'startUploading']),
        select(item){
            this.$emit('selected', item)
            this.$emit('close')
        },
        async fetchAssets(){
            const res = await this.getAssets(this.companyId)
            const data = res.data.assets
            data.forEach(d => {
                d.url = `${process.env.VUE_APP_IMAGE_CDN_BASE}${process.env.VUE_APP_IMAGE_CDN}/${this.companyId}/${d.name}`
                d.ext = d.name.split('.').pop()
            })
            this.setAssets({key: this.companyId, list: data})
        },

        async requestUploadFile(){
            console.log('requestUploadFile')
            this.attemptToLoad = true
            var e = this.$el.querySelector('#uploadmyfilesfromtinyeditor')
            if(this.type !== 'image'){
                const f = e.files[0]
                this.addToUpload({file:f, curl:this.companyId, type:this.type, key: uuid.image(), onSuccess:this.fixture, onError:null})
            }else{
                let src = await this.getImageSourceFromFiles(e.files)
                src.forEach((img, index) => {
                    let key = uuid.image()
                    let f = e.files[index]
                    this.addToUpload({file:f, curl:this.companyId, key:key, onSuccess:this.fixture, onError:null})
                })
            }

            this.startUploading()
        },
        fixture(){
            this.fetchAssets()
        },

        async getRemoteAndUpload(){
            const res = await axios.get(this.remoteUrl, {
                withCredentials: false, 
                responseType: 'blob'
            })

            var blob = res.data
            blob.lastModifiedDate = new Date()
            blob.name = this.remoteUrl

            this.addToUpload({file:blob, curl:this.companyId, key: uuid.image(), onSuccess:this.fixture, onError:null})
            this.startUploading()
            this.remoteUrl = ''
        }
    }
    
    
}
</script>



<style scoped>
.assetsManager{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.wrapperGrid{
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    display: flex;
    justify-content: center;
}
.grid{
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;
     margin-top: .1rem;
   
}

.grid > div{
    width: 100px;
    padding: 2px;
}
.grid img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.adder{
    text-align: center;
    border:2px dashed #ccc;
    color:#999;
    font-size: .8rem;
    position: relative;
    margin-bottom: .2rem;
}
.link{
    text-decoration: underline;
}

.moreopt{
    font-size: .75rem;
    text-decoration: underline;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

input[type="file"]{
    display: none;
}
</style>