
export default {
  key: 'coords',
  name: 'Coordinates',
  description: 'Set the coordinates of the element container:',
  context: 'modules',
  basic:true,
  value: false,
  visible: ['block'],
  options: [
    { key: 'left', w: '50%', label: 'Left', description: '', type: 'text', value: 0 },
    { key: 'top', w: '50%', label: 'Top', description: '', type: 'text', value: 0 },
    { key: 'width', w: '50%', label: 'Width', description: '', type: 'text', value: 100 },
    { key: 'height', w: '50%', label: 'Height', description: '', type: 'text', value: 100 },
    // { key: 'right', w: '50%', label: 'Right', description: '', type: 'text', value: null },
    // { key: 'bottom', w: '50%', label: 'Botton', description: '', type: 'text', value: null },
    { key: 'angle', label: 'Rotation', description: '', type: 'text' },
    { key: 'skew', label: 'Skew', description: '', type: 'text' }
  ]
}
