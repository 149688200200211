import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'

import routes from './routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

const publicRoutes = ['login', 'reset', 'forgot', 'confirm']

router.beforeEach((to, from, next) => {
  if (to.name === 'maintenance') return next()
  if (process.env.VUE_APP_MAINTENANCE) return next('/maintenance')

  const userSession = Store.state.User.user
  console.log('userSession', userSession)
  if (userSession) {
    const cId = Store.state.User.companyId || Store.state.User.primaryCompanyId

    if (cId && to.name !== undefined && to.name.indexOf('rdr') >= 0) {
      
      const ct = userSession.ct
      if(ct !== '1') return next('/profile')
      
      next(`/projects/${cId}`)
    } else {
      next()
    }
  } else {
    if (publicRoutes.indexOf(to.name) >= 0) return next() // do not check for public routes

    // otherwise fetch
    //console.log('fetch', to.name)
    ;(async () => {
      try {
        const res = await Store.dispatch('checkLogin')

        if (res.data && !res.data.error) {
          Store.commit('setUser', res.data)

          
          

          const primaryCompany = res.data.companies.find(c => c.primary)
          const cId = primaryCompany.url
          
          //console.log(primaryCompany, cId)
          if (cId && to.name !== undefined && to.name.indexOf('rdr') >= 0) {

            const ct = res.data.ct
            if(ct !== '1') return next('/profile')
            
            next(`/projects/${cId}`)
          } else {
            next()
          }
        } else {
          next('/login')
        }
      } catch (err) {
        console.log('err', err)
        next('/login')
      }
    })()
  }
})

export default router
