<template>
  <div class="view">
      <div class="modal">
        <div class="wrapper">
          <div class="inner box" v-if="checkEmail">
            Check your email. You need to confirm it.
          </div>
          
          <div class="inner" v-if="!checkEmail">
            <h3>Get your account:</h3>

            <div class="field">
              <p>Full Name:</p>
              <input type="text" v-model="name" placeholder="Firstname Lastname" />
            </div>

            <div class="field">
              <p>Email:</p>
              <input type="email" v-model="username" placeholder="your email" />
            </div>

            <div class="field">
              <p>Password (at least 8 chars):</p>
              <input type="password" v-model="password" placeholder="your password" />
            </div>

            
            <div class="field">
              <div class="check">
                <input type="checkbox" v-model="content_newsletter" />
                <span>I'd like to receive feature related contents such as tutorials and use-cases</span>
              </div>
            </div>

            <div class="field">
              <div class="check">
                <input type="checkbox" v-model="tech_newsletter" />
                <span>I'm ok receiving only important platform updates (2/3 yearly)</span>
              </div>
            </div>


            <div class="field">
              <div class="check">
                <input type="checkbox" v-model="terms" />
                <span>I do accept the <a href="https://www.presenta.cc/legal/terms-of-service/" target="_blank">Terms of Service</a> and <br /> the <a href="https://www.presenta.cc/legal/privacy-policy/" target="_blank">Privacy Policy</a></span>
              </div>
            </div>

            <div class="field" v-if="message">
              <p class="alert">{{message}}</p>
            </div>
            
            <div class="field">
              <button @click.prevent="submit" :disabled="noPass()">{{btnLabel}}</button>
            </div>

            <div class="field nowabtec">
              <p>You'll receive a confirmation email shortly.</p>
            </div>

            <div class="field nowabtec">
              <p><router-link to="/login">I already have an account...</router-link></p>
            </div>

          </div>
        </div>
      </div>
  </div>
</template>

<script>
import extract from '@/libs/extract'
import { mapActions, mapMutations } from 'vuex'

export default {
  data: function () {
    return {
      name: '',
      username: '',
      password: '',
      tech_newsletter: false,
      content_newsletter: false,
      terms: false,
      submitting: false,
      message:null,
      checkEmail:false
    }
  },
  watch: {
    message () {
      if (this.message) {
        this.submitting = false
      }
    }
  },
  computed: {
    btnLabel () {
      var l = (this.noPass()) ? 'Check the fields' : 'Go ahead...'
      if (this.submitting) l = 'Creating ...'
      return l
    }
  },
  methods: {
    ...mapMutations(['setUser']),
    ...mapActions(['signup']),
    async submit () {
      if (!this.noPass()) {
        this.submitting = true

        try{
          var res = await this.signup({ 
            name: this.name, 
            username: this.username.toLowerCase(), 
            password: this.password, 
            tech_newsletter: this.tech_newsletter,
            content_newsletter: this.content_newsletter 
          })

          if(res.data && !res.data.error){
            //this.setUser(res.data)
            //this.$router.push('/projects')
            this.checkEmail = true
          }else{
            this.message = 'Something went wrong'
          }
        }catch(e){
          console.log('AA', e)
          this.message = e.response.data.message
        }
      }
    },
    noPass () {
      var res = false
      res = (this.terms)
      res = (res && this.name !== '')
      res = (res && extract.email(this.username))
      //res = (res && this.username === this.usernamec)
      res = (res && this.password.length > 7)
      //res = (res && this.invitation)
      res = (res && !this.submitting)
      return !res
    }
  }
}
</script>
