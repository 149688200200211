<template>

        <li v-if="!publicUrl">
            <span @click="onPublish">Share Project</span>
        </li>
        
        <li v-else-if="publicUrl" class="slot">
            <span><a :href="getPublicUrl()" target="_blank">View Shared Project</a></span>
            <span @click="onUnpublish">Revert to Private</span>
            <!-- <span @click="copyURL">Copy Template URL</span>
            <span @click="copyCode">Copy Code Snippet</span> -->
        </li>

</template>



<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import copyClipboard from '@/libs/copyClipboard'
import pluginsMap from '@/libs/def/blocks/plugins'

export default {
    props:{
        project:Object
    },
    computed:{
        ...mapGetters(['publicUrl'])
    },
    methods:{
        ...mapActions(['publishProject', 'unpublishProject']),
        ...mapMutations(['setPublicUrl', 'unsetPublicUrl', 'notifyProjectUpdate']),

        getPluginsArray(p){
            const res = []
            p.scenes.forEach(s => {
                s.blocks.forEach(b => {
                    if(pluginsMap[b.type]) res.push({url: pluginsMap[b.type]})
                })
            })
            return res
        },

        async onPublish(){
            // const plugins = this.getPluginsArray(readyToPublish)
            // readyToPublish.plugins = plugins

            const res = await this.publishProject({url:this.project.url})
            this.setPublicUrl(res.data.url)
            this.notifyProjectUpdate()
        },

        async onUnpublish(){
            const res = await this.unpublishProject({url:this.project.url})
            this.unsetPublicUrl()
        },
        
        getPublicUrl(){
            return process.env.VUE_APP_PUBLIC_DOC_BASE_URL + '/?id=' + this.publicUrl
        },

        copyURL(){
            const url = process.env.VUE_APP_IMAGE_CDN_BASE + process.env.VUE_APP_IMAGE_CDN + '/public/' + this.publicUrl + '.json?func=proxy'
            copyClipboard(url)
        },

        copyCode(){
            const code = `// Sample code
import Presenta from 'https://cdn.skypack.dev/@presenta/lib@1'
import { interpolate } from 'https://cdn.skypack.dev/@presenta/config-interpolator'

const frames = []

const config = await interpolate({frames}, '${this.publicUrl}')
await new Presenta('#app', config)     
`
            copyClipboard(code)
        }
    }
}
</script>



<style scoped>
span{
    display: block;
    padding: .2rem 0;
    text-align: center;
}
.slot{
    display: block;
    background: white;
}

.slot span:hover{
    background-color: #eee;
}
</style>