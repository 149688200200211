<template>
  <div class="subheader" @mousedown.stop>

    <div class="left zoomer">
      <div class="vcenter hideIfMobile" v-if="next.show_adminonly">
        <a class="afake" v-tooltip="'Automation'" @click.prevent="togglePlugin">
          <img src="@/assets/icons/plugins.svg" />
        </a>
      </div>
      <div v-if="slides" class="vcenter">

        <transition name="offcanvas" mode="out-in">
        
        <!-- this throw a weird error
        <div v-if="isEmptySelection">
          <span>{{slides.length}} slides</span>
        </div> -->
        <span v-if="isEmptySelection">{{slides.length}} frames</span>
        
        <div class="left" v-if="!isEmptySelection">
          <v-popover 
            trigger="manual"
            :open="menuOpen"
            :auto-hide="true">

              <button class="menu small withicon"
                @click="menuOpen=true">
                  {{getEditLabel}}
                  <img src="@/assets/icons/downdrop.svg" />
              </button>
              
              <template slot="popover">
                  <ul class="listmenu">
                    <li @click="onActionChanged('duplicate')">Duplicate</li>
                    <li :class="{disabled:isTemplate}" @click="onActionChanged('delete')">Delete</li>
                    <li class="separator"></li>
                    <li :class="{disabled:isMultiSelection}" @click="onActionChanged('copy')">Copy</li>
                    <li :class="{disabled:isMultiSelection || !internalClipboard}" @click="onActionChanged('paste')">Paste</li>
                  </ul>
              </template>
          </v-popover>
        </div>


        </transition>
      </div>
      
    </div>


    <div class="center">
      
    </div>


    <div class="right zoomer">
      <div v-if="hasSlides">
        <a class="hideIfMobile" v-tooltip="'Zoom out <span class=short>⌘-</span>'" href @click.prevent="zoomOut" :class="{disabled: prefs.zoom==6}">
          <img src="@/assets/icons/zoomout.svg" />
        </a>
        <a class="hideIfMobile" v-tooltip="'Zoom in <span class=short>⌘+</span>'" href @click.prevent="zoomIn" :class="{disabled: prefs.zoom==1}">
          <img src="@/assets/icons/zoomin.svg" />
        </a>
        <!-- <a class="hideIfMobile" v-tooltip="'Focus mode'" href @click.prevent="zoomIn">
          <img src="@/assets/icons/zoomfocus.svg" />
        </a> -->

        <a class="hideIfDesktop" href @click.prevent="toggleSidebarOpen">
          <img src="@/assets/icons/backlist.svg" />
        </a>
        
      </div>
    </div>

  </div>
</template>



<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import { cloneAsDefault } from '@/libs/defaults'

export default {
    data(){
      return{
        menuOpen:false
      }
    },
    watch:{
      currentIndexSelected(){
        this.menuOpen = false
      }
    },
    computed:{
      ...mapGetters(['slides', 'prefs', 'selection', 'project', 'company',
        'isEmptySelection', 'isMultiSelection', 'isSingleSelection',
        'internalClipboard']),
      getEditLabel(){
        let s = this.selection.length === 1 ? '' : 's'
        return `Edit frame${s}:`
      },
      hasSlides(){
        return this.slides && this.slides.length > 0;
      },
      currentSingleSelected(){
        return this.isSingleSelection ? this.selection[0] : null
      },
      currentIndexSelected(){
        return this.isSingleSelection && this.slides.length > 0 ? this.slides.indexOf(this.selection[0]) : -1
      },
      next(){
        return this.company && this.company.next ? this.company.next : {}
      },
      isTemplate(){
        let res = true
        if(this.currentSingleSelected){
          res = this.currentSingleSelected.pTemplateID
        }
        return res
      }
    },
    methods:{
      ...mapMutations(['toggleSidebarOpen', 'setZoomIn', 'setZoomOut',
          'pasteOptions', 'notifyProjectUpdate',
          'setInternalClipboard']),
      ...mapActions(['busDeleteSelectedSlides', 'busDuplicateSelectedSlides',
        'busCopySelectedSlides', 'busPasteCopiedSlides', 'busAddNewSlideFromSource']),
      zoomOut(){
        this.setZoomOut()
        this.notifyProjectUpdate()
      },
      zoomIn(){
        this.setZoomIn()
        this.notifyProjectUpdate()
      },
      onActionChanged(cmd){
        switch(cmd){
          case 'duplicate':
            this.busDuplicateSelectedSlides()
            break

          case 'delete':
            this.busDeleteSelectedSlides()
            break

          case 'copy':
            this.setInternalClipboard(cloneAsDefault(this.currentSingleSelected))
            //this.busCopySelectedSlides()
            break

          case 'paste':
            const ob = {
              slide: this.internalClipboard,
              index: this.currentIndexSelected + 1
            }
            this.busAddNewSlideFromSource(ob)
            //this.busPasteCopiedSlides()
            break


          default:
            break
        }

        this.menuOpen = false

      },

      togglePlugin(){
        this.$emit('togglePlugin')
      }
      
    }
}
</script>




<style scoped>

.subheader{
  position: sticky;
  top:0;
  background-color: var(--greybarsback);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  z-index: 9999;
  height: 34px;
  flex:0 0 auto;
  padding: 0 .5rem;
  padding-left: 1rem;
}


.left{
  display: flex;
  font-size:.75rem;
}

.center{
  flex:1;
  text-align: center;
}

.right{
  width: 160px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right > div{
  display: flex;
}

.nav{
  height: 100%;
  display: flex;
  align-items: center;
}


input{
  width: 100%;
}

.zoomer span{
  margin: 0 .25rem;
}

.zoomer a{
  color: #000;
  width: 26px;
  height: 26px;
  display: inline-block;
  text-decoration: none;
}
.zoomer a:hover{
  background-color: #fff;
}
.zoomer a.disabled{
  cursor: not-allowed;
  opacity: .25;
}

.zoomer a > img{
  width: 100%;
  height:100%;
}

.vcenter{
  display: flex;
  align-items: center;
}


.offcanvas-enter-active, .offcanvas-leave-active {
  transition: opacity .35s cubic-bezier(0.19, 1, 0.22, 1);
}
.offcanvas-enter, .offcanvas-leave-to {
  opacity: 0;
}




</style>