<template>
    <div >
        <h3>API Usage</h3>

        <p>Credits consumed: <b>{{totalGen}}</b> equals to <b>{{totalEur}}</b> - Samples generated: <b>{{totalTst}}</b> - Errors: <b>{{totalErr}}</b></p>
        <!-- <select v-if="hasData" v-model="currentMonth">
          <option v-for="month in result" :value="month" :key="month.year + month.month">{{month.year + ' ' + month.month}}</option>
        </select> -->
        <canvas ref="cnv" width="400" height="100"></canvas>
    </div>
</template>




<script>
import { mapActions } from 'vuex'
import Chart from 'chart.js/auto'

export default {
  props:{
    curl:String
  },
  data(){
    return{
      results:null,
      currentMonth:null,
      chart:null,
      totalGen:0,
      totalTst:0,
      totalErr:0,
      totalEur:''
    }
  },
  mounted(){
    this.getStats()
  },
  watch:{
    currentMonth(){
      this.chartUpdate()
    }
  },
  methods:{
    ...mapActions(['stats']),

    async getStats(){
      const res = await this.stats(this.curl)
      const { data } = res || {}

      this.results = data

      this.currentMonth = this.results.gen[0]
    },

    chartUpdate(){
      if(this.chart) this.chart.destroy()

      const ctx = this.$refs.cnv.getContext('2d')

      const days = this.currentMonth.days

      const valuesGen = this.results.gen[0].stats
      const valuesTst = this.results.tst[0].stats
      const valuesErr = this.results.err[0].stats

      this.totalGen = this.results.gen[0].total
      this.totalTst = this.results.tst[0].total
      this.totalErr = this.results.err[0].total

      this.totalEur = Math.round((this.totalGen*0.01) * 100) / 100  + '€'

      this.chart = new Chart(ctx, {
          type: 'line',
          data: {
              labels: days,
              datasets: [{
                  label: `PRO credits`,
                  data: valuesGen,
                  borderColor: '#F4A108'
              },{
                  label: `FREE samples`,
                  data: valuesTst,
                  borderColor: '#cccccc'
              },{
                  label: `Errors`,
                  data: valuesErr,
                  borderColor: '#EA0621'
              }]
          },
          options: {
              
          }
      })
    }
  }
}
</script>


<style scoped>

.row{
    padding-bottom: 1rem;
}

table{
  width: 100%;
  border: 0;
}
table{
  border-collapse: collapse;
}
td{
  padding: .2rem;
}
.header{
  font-weight: bold;
  background-color: #333;
  color: #fff;
}

</style>