<template>
    <div ref="cont" class="regionEditable">
        <div ref="area" class="area"></div>
    </div>
</template>




<script>

import { mapGetters, mapMutations } from 'vuex'

export default {
    props:{
        pscene:Object,
        index:Number
    },
    components:{
    },
    data(){
        return{
            lastSelecting:null,
            lastSelected:null,
            canvas:null,
            isShiftPressed:false,
            prevPointer:null,
            fz:0,
            areas:[]
        }
    },
    mounted(){
        const scene = this.pscene.config.scenes[this.index]

        const bb = this.$refs.cont.getBoundingClientRect()
        this.fz = +window.getComputedStyle(scene._el).getPropertyValue('--presenta-fz')

        scene.blocks.forEach((b,i) => {
            this.areas.push(this.beginEdit(b))
        })

        console.log(this.areas)

    },
    beforeDestroy(){
        
    },
    computed:{
        ...mapGetters(['selectingBlockIndex', 'selectedBlockIndex', 'selectedBlockIndexFromSidebar'])
    },
    watch:{
        selectingBlockIndex(v){
            this.handleSidebarSelectedBlock(v)
        }
    },
    methods:{
        ...mapMutations(['setSelectingBlockIndex', 'setSelectedBlockIndexFromFabric', 'setSelectedBlockIndexFromSidebar']),
        beginEdit(config){
            const scene = this.pscene.config.scenes[this.index]
            
            const bb = scene._el.getBoundingClientRect()
            const sw = bb.width
            const sh = bb.height
            
            const coords = { top: 0, left: 0, width: 100, height: 100, angle: 0 }
            const units = { top: '%', left: '%', width: '%', height: '%', angle: 'deg' }

            const cc = config.modules.coords || { top: 0, left: 0, width: 100, height: 100, angle: 0 }
            for (const k in coords) {
                if (cc[k]) {
                    const vv = typeof cc[k] === 'number' ? cc[k] + '%' : cc[k]
                    units[k] = vv.indexOf('px') > 0 ? 'px' : units[k]
                    units[k] = vv.indexOf('deg') > 0 ? 'deg' : units[k]

                    if (units[k] === '%') {
                        const dim = (k === 'left' || k === 'width') ? sw : sh
                        coords[k] = vv ? (dim * +vv.replace('%', '') / 100) : coords[k]
                    }

                    if (units[k] === 'px') {
                        coords[k] = +vv.replace('px', '') / this.fz
                    }

                    if (units[k] === 'deg') {
                        coords[k] = +vv.replace('deg', '')
                    }
                } else {
                    const dim = (k === 'left' || k === 'width') ? sw : sh
                    coords[k] = dim * +coords[k] / 100
                }

            }

            return {coords, units}
        },
        handleSidebarSelectedBlock(v){
            const area = v >= 0 ? this.areas[v] : {coords: {top:-1000, left:-1000, width:0, height:0}}
            const {coords} = area || {}
            this.$refs.area.style.top = coords.top + 'px'
            this.$refs.area.style.left = coords.left + 'px'
            this.$refs.area.style.width = coords.width + 'px'
            this.$refs.area.style.height = coords.height + 'px'
        }

    }
}
</script>



<style scoped>
.regionEditable{
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    position: absolute;
}
.area{
    width: 0;
    height: 0;
    position: absolute;
    top:-1000px;
    left:-1000px;
    border: 1px solid #24a1f9;
    background-color: #24a0f95f;
}
</style>