<template>
    <ModalContainer @close="unsetModal">
        
        <div class="wrapperInner">

            <h1>Automate this template on Social Media!</h1>

            <div v-if="!isNew">
                <p>Active:</p>
                <input @change="changeStatus" type="checkbox" v-model="isActive" />
            </div>


            <div>
                <p>Repeat every hours...:</p>
                <input type="number" min="1" max="24" v-model="everyHours" />
            </div>

            <div>
                <p>Source:</p>
                <input type="text" v-model="settings.sourceUrl" />
            </div>

            <div>
                <p>Source Format:</p>
                <input type="text" v-model="settings.sourceFormat" />
            </div>

            <div>
                <p>usePublishedFlag:</p>
                <input type="checkbox" v-model="settings.usePublishedFlag" />
            </div>

            <div>
                <button @click="save">Save</button>
            </div>
            
        </div>
    </ModalContainer>
</template>




<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ModalContainer from '@/components/ModalContainer'

export default {
    props:{
        scene:Object
    },
    data(){
        return {
            mode: 'schedule',
            curl:null,
            purl:null,
            everyHours: 1,
            isNew: true,
            isActive: false,
            settings:{
                time: '* * * * *',
                sourceUrl: '',
                sourceFormat: '',
                usePublishedFlag: false
            }
        }
    },
    components:{
        ModalContainer
    },
    async mounted(){

        this.curl = this.companyId
        this.purl = this.document.scenes[0].pTemplateID

        const r1 = await this.getSettings({curl:this.curl, purl:this.purl})
        const { settings } = r1.data
        if(settings) {
            this.isNew = false
            this.settings = settings
        }
        
        const r2 = await this.getStatus({curl:this.curl, purl:this.purl})
        const { active } = r2.data
        if(active) this.isActive = true
    },
    computed:{
        ...mapGetters(['document', 'companyId'])
    },
    methods:{
        ...mapActions(['getSettings', 'setSettings', 'getStatus', 'setStatus']),
        ...mapMutations(['setAutomateObject']),
        unsetModal(){
            this.setAutomateObject(null)
        },
        async save(){

            this.settings.time = `* */${this.everyHours} * * *`

            const r = await this.setSettings({
                curl: this.curl, 
                purl: this.purl, 
                active: this.isActive,
                settings: this.settings
            })
            
            if(r.statusText) this.isNew = false
        },
        async changeStatus(){
            const active = this.isActive || null
            const r = await this.setStatus({curl: this.curl, purl: this.purl, active})
            console.log(r)
        }
    }
}
</script>