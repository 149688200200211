import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VTooltip from 'v-tooltip'


import * as Presenta from '@presenta/lib'
import QRCodeBlock from '@presenta/block-qrcode'
import BarcodeBlock from '@presenta/block-barcode'
import EmojiBlock from '@presenta/block-emoji'
import CssModule from '@presenta/module-css'
import ScriptModule from '@presenta/module-script'
import FabricBridge from './libs/fabricbridge'
import MarkupBlock from '@presenta/block-markup'

Presenta.use(QRCodeBlock)
Presenta.use(BarcodeBlock)
Presenta.use(EmojiBlock)
Presenta.use(CssModule)
Presenta.use(ScriptModule)
Presenta.use(FabricBridge)
Presenta.use(MarkupBlock)

/*
NEW NPM
npm i ../@presenta/presenta-lib --no-save

npm i file://../@presenta/presenta-lib --no-save

*/

// make it to work for some Plugins
window.Presenta = Presenta

Vue.config.productionTip = false
Vue.use(VTooltip)
// VTooltip.VTooltip.options.defaultDelay = { show: 0, hide: 0 }

if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch)) {
  VTooltip.enabled = false
  store.commit('setTouchMode')
}

if (window.innerWidth < 768) {
  store.commit('setMobileMode')
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
