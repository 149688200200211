<template>
    <div class="guides">
        <div ref="h" class="h"></div>
        <div ref="v" class="v"></div>
    </div>
</template>



<script>
export default {
    props:{
        canvas:Object,
        fz:Number
    },
    data(){
        return{
            isCtrlPressed:false
        }
    },
    mounted(){

        const w = this.canvas.width
        const h = this.canvas.height

        const margin = 40 * 1/this.fz
        
        const xOriginalPts = [0, margin, parseInt(w / 2), w - margin, w]
        const yOriginalPts = [0, margin, parseInt(h / 2), h - margin, h]
        let xPts = []
        let yPts = []
        let mX = 0
        let mY = 0

        this.canvas.on('mouse:down', (e) => {
            this.isCtrlPressed = e.e.ctrlKey

            if (!e.target) return
            const others = this.canvas.getObjects().filter(o => (o !== e.target && !o.group))

            xPts = [...xOriginalPts]
            yPts = [...yOriginalPts]

            others.forEach(o => {
                const c = o.getCenterPoint()
                const b = o.getBoundingRect()

                xPts.push(parseInt(b.left), parseInt(c.x), parseInt(b.left + b.width))
                yPts.push(parseInt(b.top), parseInt(c.y), parseInt(b.top + b.height))
            })

            xPts = xPts.sort((a, b) => a - b)
            yPts = yPts.sort((a, b) => a - b)

            // remove duplicates
            xPts = [...new Set(xPts)]
            yPts = [...new Set(yPts)]

            mX = e.pointer.x - e.target.left
            mY = e.pointer.y - e.target.top

            this.$refs.h.style.opacity = 0
            this.$refs.v.style.opacity = 0
        })

        this.canvas.on('object:moving', (e) => {
            this.isCtrlPressed = e.e.ctrlKey
            const me = e.target

            // we disable guide snap in these conditions
            if(!this.isCtrlPressed
                || me.lockMovementY
                || me.lockMovementX) return

            const c = me.getCenterPoint()
            const b = me.getBoundingRect()

            const vLeft = e.pointer.x - mX
            const vTop = e.pointer.y - mY

            const xMe = [vLeft, c.x, vLeft + b.width]
            const yMe = [vTop, c.y, vTop + b.height]

            let cacheX = []
            xMe.forEach((lx, i) => {
                xPts.forEach(gx => {
                    let shift = gx
                    if (i === 1) shift -= b.width / 2
                    if (i === 2) shift -= b.width
                    cacheX.push({
                        diff: Math.abs(gx - lx),
                        gx,
                        shift
                    })
                })
            })
            cacheX = cacheX.sort((a, b) => a.diff - b.diff)
            const firstX = cacheX[0]

            if (firstX.diff < 10) {
                me.set('left', firstX.shift)

                this.$refs.v.style.opacity = 1
                this.$refs.v.style.left = firstX.gx + 'px'
            } else {
                this.$refs.v.style.opacity = 0
            }

            let cacheY = []
            yMe.forEach((ly, i) => {
                yPts.forEach(gy => {
                    let shift = gy
                    if (i === 1) shift -= b.height / 2
                    if (i === 2) shift -= b.height
                    cacheY.push({
                        diff: Math.abs(gy - ly),
                        gy,
                        shift
                    })
                })
            })
            cacheY = cacheY.sort((a, b) => a.diff - b.diff)
            const firstY = cacheY[0]

            if (firstY.diff < 10) {
                me.set('top', firstY.shift)
                this.$refs.h.style.opacity = 1
                this.$refs.h.style.top = firstY.gy + 'px'
            } else {
                this.$refs.h.style.opacity = 0
            }
        })
        this.canvas.on('mouse:up', (e) => {
            this.$refs.v.style.opacity = 0
            this.$refs.h.style.opacity = 0
            this.isCtrlPressed = false
        })
    }
}
</script>



<style scoped>
.guides{
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    overflow: hidden;
}

.h, .v{
    top:0;
    left:0;
    position: absolute;
    opacity: 0;
    background: var(--borderselectedcolor);
}
.h{
    width: 100%;
    height: 1px;
}
.v{
    height: 100%;
    width: 1px;
}
</style>