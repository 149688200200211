import Login from '@/views/Login.vue'
import Signup from '@/views/Signup.vue'
import Forgot from '@/views/Forgot.vue'
import Confirm from '@/views/Confirm.vue'
import Reset from '@/views/Reset.vue'
import Profile from '@/views/Profile.vue'
import Projects from '@/views/Projects.vue'
import Editor from '@/views/Editor.vue'
import NotFound from '@/views/NotFound.vue'
import Maintenance from '@/views/Maintenance.vue'
import Checking from '@/views/Checking.vue'
import Import from '@/views/Import.vue'

export default [
  // special
  { path: '/maintenance', name: 'maintenance', component: Maintenance },

  // public routes
  { path: '/login', name: 'login', component: Login },
  //{ path: '/signup', name: 'signup', component: Signup },
  { path: '/forgot', name: 'forgot', component: Forgot },
  { path: '/confirm', name: 'confirm', component: Confirm },
  { path: '/reset', name: 'reset', component: Reset },

  // private routes
  { path: '/profile', name: 'profile', component: Profile },
  { path: '/profile/:id', name: 'connect', component: Profile },
  { path: '/projects/:id', name: 'projects', component: Projects },
  { path: '/edit/:id', name: 'editor', component: Editor },
  { path: '/import/:id', name: 'import', component: Import },

  // redirects
  { path: '/edit', name: 'rdr_editor', component: Checking },
  { path: '/projects', name: 'rdr_projects', component: Checking },
  { path: '/', name: 'start', redirect: to => '/projects' },
  { path: '*', component: NotFound }
]
