
function fabricbridge (element, modConfig, config) {
  if (config.contextType === 'block') return

  const viewport = element.querySelector('.blocksContainer')

  const stl = 'width:100%;height:100%;position:absolute;border:3px dashed red;pointer-events:none;visibility:hidden;'

  const limits = document.createElement('div')
  limits.setAttribute('style', stl)
  viewport.parentNode.appendChild(limits)

  const upd = e => {
    const block = config.blocks[e.index]
    const el = block._el

    for (const k in e.coords) {
      const v = e.coords[k]
      el.style.setProperty('--' + k, v)
    }

    block.modules.coords = e.coords
  }

  const router = config._router
  router.on('live:block:update', upd)

  const begin = () => {
    viewport.style.overflow = 'inherit'
    limits.style.visibility = 'visible'
  }
  router.on('live:block:begin', begin)

  const end = () => {
    viewport.style.overflow = 'hidden'
    limits.style.visibility = 'hidden'
  }
  router.on('live:block:end', end)

  this.destroy = () => {
    router.off('live:block:update', upd)
    router.off('live:block:begin', begin)
    router.off('live:block:end', end)
  }
}

export default fabricbridge

fabricbridge.install = Presenta => {
  Presenta.addModule('fabricbridge', fabricbridge)
}
