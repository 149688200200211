<template>
    <div class="block" :class="{open:show}">

        <CtrlHeader @changed="show=!show" :label="'Colors Scheme'" />
        
        <div v-show="show" class="summary">
            
            <div class="description">Choose a pre-defined Colors Scheme that works nicely with the Rich Text element.</div>

            <div class="ctrlcontainer">
                <SelectCtrl :ctrl="kit" @change="onChangeKit" />                
            </div>

            <div class="ctrlcontainer">
                <SelectCtrl :ctrl="varSel" @change="onChangeKit" />                
            </div>

            <!-- <div class="ctrlcontainer">
                <ColorCtrl :ctrl="colorBack" @change="onChangeOpt" />                
                <ColorCtrl :ctrl="colorFore" @change="onChangeOpt" />                
            </div>

            <div class="ctrlcontainer">
                <ColorCtrl :ctrl="colorAccent" @change="onChangeOpt" />                
                <ColorCtrl :ctrl="colorAlt" @change="onChangeOpt" />                
            </div> -->


        </div>

    </div>
</template>




<script>
import { mapGetters } from 'vuex'
import CtrlHeader from './CtrlHeader.vue'
import SelectCtrl from '../ui/SelectCtrl'
import TextCtrl from '../ui/TextCtrl'
import ColorCtrl from '../ui/ColorCtrl'

export default {
    props:{
        root:Object
    },
    data(){
        return{
            show: false,

            varSel: { label: 'Variant', value: '', options:[
                { key: 'a', value: '0', label: 'A'},
                { key: 'b', value: '1', label: 'B'},
                { key: 'c', value: '2', label: 'C'}
            ]},
            
            kit: { label: 'Preset', value: '', options: [
                { key: 'bn', value: 'bn', label: 'B/N', 
                    vars:[
                        {back: '#fff',fore:'#000',accent: '#555',alt:'#ccc'},
                        {back: '#000',fore:'#fff',accent: '#ccc',alt:'#555'},
                        {back: '#ccc',fore:'#000',accent: '#fff',alt:'#555'}
                    ]
                },
                { key: 'adele', value: 'adele', label: 'Adele', 
                    vars:[
                        {back: '#000',   fore:'#fff',   accent: '#EEFF2E',alt:'#DE1318'},
                        {back: '#EEFF2E',fore:'#000',   accent: '#DE1318',alt:'#fff'},
                        {back: '#DE1318',fore:'#EEFF2E',accent: '#fff',   alt:'#000'}
                    ]
                }
            ] },

            fields: ['back', 'fore', 'accent', 'alt'],
            
            back: { label: 'Background', value: '' },
            fore: {label: 'Foreground', value: '' },
            accent: { label: 'Accent', value: '' },
            alt: { label: 'Alternate', value: '' },
            
        }
    },
    computed:{
        space(){
            return this.root.modules.colors
        }
    },
    watch:{
        space(){
            this.update()
        }
    },
    mounted(){
        this.update()
    },
    components:{
        SelectCtrl, TextCtrl, CtrlHeader, ColorCtrl
    },
    
    methods:{
        update(){
            const ft = this.space
            if(ft){
                this.kit.value = ft.kit
                this.fields.forEach(f => {
                    this[f].value = ft[f]
                })
                this.varSel.value = ft.alt
            }else{
                this.root.modules.colors = {}
            }
        },
        onChangeKit(){
            this.setFields()
        },
        onChangeOpt(){
            this.kit.value = null
            this.consolidate()
        },
        setFields(){
            const k = this.kit.value
            if(k){
                const val = this.kit.options.find(d => d.key === k)
                if(val){
                    const cidx = this.varSel.value || 0
                    const cset = val.vars[cidx]
                    this.fields.forEach(f => {
                        this[f].value = cset[f]
                    })
                }
            }else{
                this.fields.forEach(f => {
                    this[f].value = ''
                })
                this.varSel.value = ''
            }

            
            this.consolidate()
        },
        consolidate(){
            const ft = this.root.modules.colors
            ft.kit = this.kit.value
            ft.alt = this.varSel.value
            this.fields.forEach(f => {
                ft[f] = this[f].value
            })
            this.$emit('change')
        }
    }
}
</script>



<style scoped>
.block{
    width: 100%;
}
.block:hover{
    background-color: #eee;
}
.block.selecting{
    outline:1px solid #24a1f9;
}
.block.open{
    box-shadow: 0 4px 5px #ccc;
    margin-bottom: 1rem;
    background-color: #eee;
}


.inline{
    padding: 0;
}
.inline >>> p{
    margin: 0;
}
.inline >>> label{
    display: flex;
}

select.inline{
    background: none;
    border: none;
}

.description{
    font-size: .75rem;
    width: 100%;
}

.summary{
    display: flex;
    flex-wrap: wrap;
}
.ctrlcontainer{
    width: 100%;
    display: flex;
}


.summary{
    padding: .5rem;
    background-color: white;
}

</style>
