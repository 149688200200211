<template>
  <div class="view">
      <div class="modal">

        <div class="wrapper">
            <div class="inner">

            <div v-if="!status">
                <h3>Checking for confirmation...</h3>
            </div>

            <div v-if="status === 1">
                <h3>Got it!</h3>
                <p>Email confirmed!</p>
                <p>
                  <router-link to="/projects">Go to your Projects!</router-link>
                </p>
            </div>

            <div v-if="status && status !== 1">
                <h3>Something went wrong.</h3>
                <p class="warning">{{status}}</p>
                <router-link to="/forgot">Try again</router-link>
            </div>

            </div>
        </div>

        </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data(){
      return {
          status:false
      }
  },
  computed:{
      token(){
          return this.$route.query.token
      }
  },
  methods:{
    ...mapActions(['confirmEmail']),
  },
  async mounted () {
    try{
      var res = await this.confirmEmail({token: this.token})
      this.status = 1
    }catch(err){
      this.status = err.message
    }
    
  }
}
</script>

<style scoped>
.warning{
  color:red;  
}
</style>
