import uuid from '@/libs/uuid'
import commonProps from '@/libs/commonProps.js'
import { fixScene } from '@/libs/defaults'

export default {
  state: {
    project: null,
    document: null,
    pidx: 0,
    cidx: 0,
    saveStatusCode: 0
  },
  mutations: {
    setProject (state, ob) {
      commonProps(ob)
      state.project = ob
      state.document = ob.document
    },
    setSaveStatusCode (state, v) {
      state.saveStatusCode = v
    },
    notifyProjectUpdate (state, forceUpdate) {
      state.pidx = Math.random()
      const doc = state.project
      commonProps(doc, forceUpdate)
      doc.document.pProjectAPIChanged = true
      state.document = doc.document
    },
    unsetProject (state) {
      state.project = null
    },
    updateSlides (state, v) {
      state.project.document.scenes = v
    },

    setZoomIn (state) {
      const p = state.project.prefs
      if (p.zoom > 1) p.zoom--
    },
    setZoomOut (state) {
      const p = state.project.prefs
      if (p.zoom < 6) p.zoom++
    },
    setZoomMax (state) {
      const p = state.project.prefs
      p.zoom = 1
    },
    setZoomMin (state) {
      const p = state.project.prefs
      p.zoom = 6
    },
    setZoom (state, v) {
      const p = state.project.prefs
      p.zoom = v
    },

    setPublicUrl (state, url) {
      state.project.publicUrl = url
    },
    unsetPublicUrl (state) {
      state.project.publicUrl = null
    },
    setProjectName (state, v) {
      state.project.name = v
    }

  },
  getters: {
    project (state) {
      return state.project
    },
    document (state) {
      return state.document
    },
    saveStatusCode (state) {
      return state.saveStatusCode
    },
    pidx (state) {
      return state.pidx
    },
    cidx (state) {
      return state.cidx
    },
    prefs (state) {
      return state.project ? state.project.prefs : null
    },
    slides (state) {
      return state.project ? state.project.document.scenes : null
    },
    publicUrl (state) {
      return state.project ? state.project.publicUrl : null
    }
  },
  actions: {
    deleteSlide (context, s) {
      const doc = context.state.project.document.scenes
      const index = doc.indexOf(s)
      doc.splice(index, 1)
    },
    duplicateSlide (context, s) {
      const doc = context.state.project.document.scenes
      const index = doc.indexOf(s)
      const clone = JSON.parse(JSON.stringify(s))

      clone.$url = uuid.scene()
      clone.$selected = false
      clone.id = null
      clone.pTemplateID = null
      clone.pPluginID = null
      clone.pFrameID = null

      doc.splice(index + 1, 0, clone)
      fixScene(clone)
      return clone
    },
    addSlideAt (context, ob) {
      const doc = context.state.project.document
      const index = ob.index || ob.index === 0 ? ob.index : doc.scenes.length
      console.log(index, ob.slide)
      doc.scenes.splice(index, 0, ob.slide)
    },
    appendMultipleSlides (context, slides) {
      const doc = context.state.project.document.scenes
      doc.push(...slides)
    }

  }
}
