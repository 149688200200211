import stripEmoji from 'emoji-strip'

const insertBrTo = (str, tag, strip) => {
  const stripBR = strip ? '<br>' : ''
  const injectBR = strip ? '' : '<br>'
  let res = str
  const check = str.match(new RegExp(`<\s*${tag}[^>]*>(.*?)<\s*\/\s*${tag}>`, 'gim'))
  if (check) {
    check.forEach(p => {
      const newtag = p.replace(`${stripBR}</${tag}>`, `${injectBR}</${tag}>`)
      res = res.replace(p, newtag)
    })
  }
  return res
}

export default {
  youtubeId (str) {
    if (!str) return false
    // https://regex101.com/r/vJ3qD6/5
    const regExp = /^(?:(?:https?:\/\/)?(?:www\.)?)?(youtube(?:-nocookie)?\.com|youtu\.be)\/.*?(?:embed|e|v|watch\?.*?v=)?\/?([a-z0-9-_]+)/gmi
    const match = str.match(regExp)
    const parts = regExp.exec(str)
    let id = false
    if (match && parts.length >= 3 && parts[2].length === 11) {
      id = parts[2]
    }
    return id
  },
  email (str) {
    if (!str) return false
    const regExp = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/g
    const match = str.match(regExp)
    return match
  },
  url (str) {
    if (!str) return false
    const regExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/gim
    const match = str.match(regExp)
    return match && match.length > 0
  },
  countListItems (str) {
    if (!str) return 0
    const items = str.match(new RegExp('<(li)>(.*?)</(li)>', 'gim'))
    return items ? items.length : 0
  },
  countBlocks (str) {
    if (!str) return 0
    const items = str.match(new RegExp('<(h1|h2|p|blockquote|li)>(.*?)</(h1|h2|p|blockquote|li)>', 'gim'))
    return items ? items.length : 0
  },
  sanitizeEmbed (str) {
    let res = null
    const re = /<iframe [.\s\S]*<\/\s*?iframe>/gim
    const check = str.match(re)
    if (check && check.length > 0) {
      res = stripEmoji(str)
    }
    return res
  },

  prepareHTMLForEdit (str) {
    return str
    let res = str
    res = insertBrTo(res, 'p', true)
    res = insertBrTo(res, 'h1', true)
    res = insertBrTo(res, 'h2', true)
    return res
  },
  prepareHTMLForSave (str) {
    return str
    let res = str
    res = insertBrTo(res, 'p', false)
    res = insertBrTo(res, 'h1', false)
    res = insertBrTo(res, 'h2', false)

    const check = res.match(new RegExp('<(p|h1|h2)><br></(p|h1|h2)>', 'gim'))
    if (check && check.length === 1) {
      if (check[0] === res) res = null
    }
    return res
  }

}
