const effects = [
  { key: 'slideup', label: 'Slide Up (default)', value: 'exitSlideUp' },
  { key: 'fadeout', label: 'Fade Out', value: 'exitFadeOut' }
]

export default {
  key: 'reveal',
  name: 'Reveal',
  description: 'It shows an overlay with an optional message to cover the scene content. After a forward trigger the overlay will reveal the content.',
  context: 'modules',
  value: false,
  visible: ['scene'],
  options: [
    { key: 'text', label: 'Message', description: '', type: 'text', value: '' },
    { key: 'effect', label: 'Effect', description: '', type: 'select', value: '', options: effects }
  ]
}
