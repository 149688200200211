<template>
    <div></div>
</template>



<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    computed:{
        ...mapGetters(['isSingleSelection', 'slides' ,'selection'])
    },
    mounted(){
        this.clearSelection()
        document.addEventListener('keydown', this.setKeyListener)
    },
    beforeDestroy(){
        document.removeEventListener('keydown', this.setKeyListener)
    },
    methods:{
        ...mapMutations(['clearSelection', 'addToSelection',
            'setZoomIn', 'setZoomOut']),
        ...mapActions(['busDeleteSelectedSlides',
            'busSelectPrevSlide', 'busSelectNextSlide',
            'busSelectNextOrCreateNewSlide',
            'busDuplicateSelectedSlides']),
        setKeyListener(e){
            let prevent = false

            const code = e.code
            const key = e.keyCode

            // if(code === 'Backspace'){
            //     prevent = true
            //     this.busDeleteSelectedSlides()
            // }

            if(code === 'Tab'){
                prevent = true
                if(e.shiftKey){
                this.busSelectPrevSlide()
                }else{
                this.busSelectNextOrCreateNewSlide()
                }
            }
            if(code === 'ArrowLeft'){
                prevent = true
                this.busSelectPrevSlide()
            }
            if(code === 'ArrowRight'){
                prevent = true
                this.busSelectNextSlide()
            }

            if(code === 'Enter'){
                prevent = true
                if(this.isSingleSelection) this.$emit('toggleFocusMode')
            }
            if(code === 'Escape'){
                prevent = true
                this.$emit('unfocusMode')
            }


            if(e.metaKey){

                if(key === 187){
                    prevent = true
                    this.setZoomIn()
                }

                if(key === 189){
                    prevent = true
                    this.setZoomOut()
                }
                
                if(code === 'KeyA'){
                    prevent = true
                    this.clearSelection()
                    this.slides.forEach(s => {
                        this.addToSelection(s)
                    })
                }

                // duplicate the frame or the element
                // if(code === 'KeyD'){
                //     prevent = true
                //     this.busDuplicateSelectedSlides()
                // }

                // if(!this.isEmptySelection && code === 'KeyC'){
                //     prevent = true
                //     copyClipboard(stringify(this.selection))
                //     console.log('COPY SLIDES')
                // }
                // if(code === 'KeyV'){
                //     /*
                //         check clipboard
                //         - is url && is image -> upload & insert or new slide
                //         - is url && is video -> upload & insert or new slide
                //         - is iframe -> put embed
                //         - is YT url or YT code -> put yt

                //         if not, do not prevent
                //     */
                //     prevent = true
                //     const c = pasteClipboard()
                //     console.log('PASTE SOMETHING:', c)
                // }
                
            }
            
            if(prevent){
                e.preventDefault()
                e.stopImmediatePropagation()
            }
        }
    }
}
</script>
