import { Mark, Node } from 'tiptap'
import { toggleMark, updateMark, removeMark, toggleWrap, wrappingInputRule, markInputRule, markPasteRule } from 'tiptap-commands'

class TMark extends Mark {
  get name () {
    return 'mark'
  }

  get schema () {
    return {
      parseDOM: [
        {
          tag: 'mark'
        }
      ],
      toDOM: () => ['mark', 0]
    }
  }

  commands ({ type }) {
    return () => toggleMark(type)
  }

  inputRules ({ type }) {
    return [
      markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)
    ]
  }

  pasteRules ({ type }) {
    return [
      markPasteRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)/g, type)
    ]
  }
}

class THigh extends Mark {
  get name () {
    return 'high'
  }

  get schema () {
    return {
      parseDOM: [
        {
          tag: 'high'
        }
      ],
      toDOM: () => ['high', 0]
    }
  }

  commands ({ type }) {
    return () => toggleMark(type)
  }

  inputRules ({ type }) {
    return [
      markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)
    ]
  }

  pasteRules ({ type }) {
    return [
      markPasteRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)/g, type)
    ]
  }
}

class TBord extends Mark {
  get name () {
    return 'bord'
  }

  get schema () {
    return {
      parseDOM: [
        {
          tag: 'bord'
        }
      ],
      toDOM: () => ['bord', 0]
    }
  }

  commands ({ type }) {
    return () => toggleMark(type)
  }

  inputRules ({ type }) {
    return [
      markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)
    ]
  }

  pasteRules ({ type }) {
    return [
      markPasteRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)/g, type)
    ]
  }
}

class TSmall extends Mark {
  get name () {
    return 'small'
  }

  get schema () {
    return {
      parseDOM: [
        {
          tag: 'small'
        }
      ],
      toDOM: () => ['small', 0]
    }
  }

  commands ({ type }) {
    return () => toggleMark(type)
  }

  inputRules ({ type }) {
    return [
      markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)
    ]
  }

  pasteRules ({ type }) {
    return [
      markPasteRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)/g, type)
    ]
  }
}

class TBtn extends Node {
  get name () {
    return 'btn'
  }

  get schema () {
    return {
      content: 'block+',
      group: 'block',
      defining: true,
      draggable: false,
      // define how the editor will detect your node from pasted HTML
      // every blockquote tag will be converted to this blockquote node
      parseDOM: [
        { tag: 'btn' }
      ],
      // this is how this node will be rendered
      // in this case a blockquote tag with a class called `awesome-blockquote` will be rendered
      // the '0' stands for its text content inside
      toDOM: () => ['btn', { class: 'btn' }, 0]
    }
  }

  commands ({ type }) {
    return () => toggleWrap(type)
  }

  inputRules ({ type }) {
    return [
      wrappingInputRule(/^\s*>\s$/, type)
    ]
  }
}

class TCustomClass extends Mark {
  get name () {
    return 'customClass'
  }

  get defaultOptions () {
    return {
      name: null
    }
  }

  get schema () {
    return {
      attrs: {
        class: {
          default: null
        }
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'span[class]',
          getAttrs: dom => ({
            class: dom.getAttribute('class')
          })
        }
      ],
      toDOM: node => ['span', {
        ...node.attrs
      }, 0]
    }
  }

  commands ({ type }) {
    return attrs => {
      if (attrs.class) {
        return updateMark(type, attrs)
      }

      return removeMark(type)
    }
  }

  inputRules ({ type }) {
    return [
      markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)
    ]
  }

  pasteRules ({ type }) {
    return [
      markPasteRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)/g, type)
    ]
  }
}

export { TMark, THigh, TBord, TSmall, TBtn, TCustomClass }
